// @flow

import * as React from 'react';
import moment from 'moment';
import { DeleteOutlined, EditFilled, PaperClipOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Modal, Popover, Tag, Button, Popconfirm } from 'antd';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { getUser, getTeamUrl, isAdvanced} from 'Common';

import ServiceTypeIcons from '../../../../Components/ServiceTypeIcons';
import styles from './Component.css';

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

const upgradeRequired = () => {
  Modal.info({
    title: 'Upgrade Required',
    content: 'You will need to upgrade your account in order to upload & download files.'
  })
}

type Props = {
  estimate: any,
  onClickEditClientSite: Function,
  onClickEditClientContact: Function,
  onClickProject: Function,
  onDropFile: Function,
  onDeleteFile: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimate,
      onClickEditClientSite,
      onClickEditClientContact,
      onClickProject,
      onDropFile,
      onDeleteFile,
    } = this.props;

    const userIsBasic = getUser().type === 'Basic'
    //className={styles.wrapper}
    return (
      <div class="_side_bar">

        <div className={styles.innerWrapper}>

          <Section>

            {estimate.title && (
              <Outlet>
                <OutletLabel>Title</OutletLabel>
                <OutletData>
                  {estimate.title}
                </OutletData>
              </Outlet>
            )}

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${estimate.project.client_site.client.id}/view`)}>
                  {estimate.project.client_site.client.name}
                </NavLink>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red" })[estimate.project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {estimate.project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Project</OutletLabel>
              <OutletData>

                  {isAdvanced() ? (
                      <NavLink to={getTeamUrl(`projects/${estimate.project.id}/view`)}>
                          {estimate.project.number}
                          {estimate.project.title && ` » ${estimate.project.title}`}
                      </NavLink>
                  ) : (
                      <a role='presentation' onClick={() => onClickProject(estimate.project)}>
                          {estimate.project.number}
                          {estimate.project.title && ` » ${estimate.project.title}`}
                      </a>
                  )}

              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  estimate.project.client_site.name,
                  estimate.project.client_site.address_line_1,
                  estimate.project.client_site.address_line_2,
                  estimate.project.client_site.address_line_3,
                  estimate.project.client_site.town_or_city,
                  estimate.project.client_site.county,
                  estimate.project.client_site.postcode
                ].filter(line => line).join(', ')}
                {' '}
                <EditFilled onClick={onClickEditClientSite} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Requested By</OutletLabel>
              <OutletData>
                <Popover
                  trigger="hover"
                  content={(
                    <div>
                      <div style={{minWidth: 150}}>
                        <strong>Tel: </strong>
                        {estimate.client_contact.telephone ? estimate.client_contact.telephone : ''}
                      </div>
                      <div style={{minWidth: 150}}>
                        <strong>Email: </strong>
                        {estimate.client_contact.email ? estimate.client_contact.email : ''}
                      </div>
                    </div>
                  )}
                >
                  {`${estimate.client_contact.first_name || ''} ${estimate.client_contact.last_name || ''} `}
                </Popover>
                {' '}
                <EditFilled onClick={onClickEditClientContact} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Decision Maker</OutletLabel>
              <OutletData>
                {estimate.decision_maker ? (
                  <Popover
                    trigger="hover"
                    content={(
                      <div>
                        <div style={{ minWidth: 150 }}>
                          <strong>Tel: </strong>
                          {estimate.decision_maker.telephone ? estimate.decision_maker.telephone : ''}
                        </div>
                        <div style={{ minWidth: 150 }}>
                          <strong>Email: </strong>
                          {estimate.decision_maker.email ? estimate.decision_maker.email : ''}
                        </div>
                      </div>
                    )}
                  >
                    {`${estimate.decision_maker.first_name || ''} ${estimate.decision_maker.last_name || ''} `}
                  </Popover>
                ) : '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Services</OutletLabel>
              <OutletData style={{ marginLeft: -3 }}>
                <ServiceTypeIcons keys={estimate.item_type_summary} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>New / Extra</OutletLabel>
              <OutletData style={{ marginLeft: -3 }}>
                <LegacyIcon type={estimate.extra_works ? 'plus' : 'star'} style={{ color: estimate.extra_works ? 'limegreen' : '#1990FF' }} />
                {estimate.extra_works ? ' Extra Works' : ' New Works'}
              </OutletData>
            </Outlet>

          </Section>

          <Section>

            <Outlet>
              <OutletLabel>Attachments</OutletLabel>
              <OutletData>

                {estimate.media.map(file => (

                  <div style={{ marginBottom: 4, marginTop: 4, display: 'flex' }} key={file.id}>
                    <PaperClipOutlined style={{ marginTop: 2 }} />
                    <div style={{ flex: 1, marginLeft: 4, marginRight: 4 }}>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                    </div>
                      <Popconfirm onConfirm={() => onDeleteFile(file)} title="Delete attachment?" placement="topRight">
                        <DeleteOutlined style={{ marginTop: 2 }} className={styles.deleteFileButton} />
                      </Popconfirm>
                  </div>

                ))}

                {estimate.media.length === 0 && (
                  <div style={{ marginBottom: 4, marginTop: 4 }}>No files</div>
                )}

                <Dropzone
                  disablePreview
                  multiple={false}
                  onDrop={acceptedFiles => { onDropFile(acceptedFiles) }}

                  style={{ width: 'auto', height: 'auto', border: 'none' }}
                >

                  <Button
                    block
                    style={{ marginTop: 8 }}
                  >
                    Upload
                  </Button>
                </Dropzone>

              </OutletData>
            </Outlet>

          </Section>

          <Section>

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Owner</OutletLabel>
                  <OutletData>
                    {estimate.user ? `${estimate.user.first_name || ''} ${estimate.user.last_name || ''}` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Sales Contact</OutletLabel>
                  <OutletData>
                    {estimate.assigned_to_user ? `${estimate.assigned_to_user.first_name || ''} ${estimate.assigned_to_user.last_name || ''}` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

            <div style={{ height: 15 }} />

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Date</OutletLabel>
                  <OutletData>
                    {estimate.date ? moment(estimate.date).format('DD MMM YYYY') : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Due</OutletLabel>
                  <OutletData>
                    {estimate.due_date ? moment(estimate.due_date).format('DD MMM YYYY') : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

            <div style={{ height: 15 }} />

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Follow Up</OutletLabel>
                  <OutletData>
                    {estimate.follow_up_date ? moment(estimate.follow_up_date).format('DD MMM YYYY') : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

          <Section>

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Discount</OutletLabel>
                  <OutletData>
                    {estimate.discount_percentage > 0 ? `${estimate.discount_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Uplift</OutletLabel>
                  <OutletData style={estimate.uplift_percentage > 0 ? { color: 'red' } : {}}>
                    {estimate.uplift_percentage > 0 ? `${estimate.uplift_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

        </div>

      </div>
    );
  }
}

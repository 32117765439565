// @flow
import { getTeamUrl } from 'Common';

export default () => async (dispatch: Function, getState: Function) => {

  const { project, selectedOrderIds } = getState().screens.ProjectView;

  let url = getTeamUrl(`projects/${project.id}/job_sheet_pdf`, 'exports')

  if (selectedOrderIds.length) {
    url = `${url}?order_ids=${selectedOrderIds.join(',')}`
  }

  window.open(url);

}

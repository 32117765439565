import { createAction, handleActions } from 'redux-actions'

const setSettings = createAction('SCREENS/SETTINGS/SET_SETTINGS')

const defaultState = []

const reducer = handleActions(
  {
    [setSettings]: (state, action) => action.payload,
  },
  defaultState,
)

export { setSettings, reducer }

// @flow
import * as React from 'react';
import moment from 'moment';
import { Table, Row, Col, Button, Input} from 'antd';
import numeral from 'numeral';
import {NavLink} from "react-router-dom";
import { getTeamUrl, isAdvanced } from 'Common';


type Props = {
  client: Object,
  searchTerm: string,

  onChangeSearch: Function,
  onClickNewProject: Function,
  onClickProjectRow: Function,

}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      client,
      searchTerm,
      onChangeSearch,
      onClickNewProject,
      onClickProjectRow,
    } = this.props;

     const columns = [
      {
        title: 'Project',
        dataIndex: '',
        render: (text, record) => isAdvanced() ? (
              <NavLink to={getTeamUrl(`projects/${record.id}/view`)}>
                  {`Project ${record.number}`}
              </NavLink>
          ) : (
              <a role='presentation' onClick={() => onClickProjectRow(record)}>{`Project ${record.number}`}</a>
          )
      },
      {
        title: 'Site',
        dataIndex: 'client_site_name'
      },
      {
        title: 'Start',
        dataIndex: 'start_date',
        render: (text, record) => (
          <span>
            {record.start_date ? moment(record.start_date).format('DD MMM YYYY') : '--'}
          </span>
        )
      },
      {
        title: 'Handover',
        dataIndex: 'handover_date',
        render: (text, record) => (
          <span>
            {record.handover_date ? moment(record.handover_date).format('DD MMM YYYY') : '--'}
          </span>
        )
      },
      {
        title: 'Value',
        key: 'value_total',
        width: 180,
        onHeaderCell: () => ({
          style: { textAlign: 'right' },
        }),
        onCell: () => ({
          style: { textAlign: 'right' },
        }),
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_total).format('0,0.00')}`}
          </div>
        )
      },
      {
        title: 'Remaining',
        dataIndex: '',
        key: 'value_remaining',
        width: 180,
        onHeaderCell: () => ({
          style: { textAlign: 'right' },
        }),
        onCell: () => ({
          style: { textAlign: 'right' },
        }),
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_remaining	).format('0,0.00')}`}
          </div>
        )
      },
      {
        title: 'Status',
        key: 'status_key',
        width: 180,
        render: (text, record) => (
          <span>
            { record.status.title }
          </span>
        )
      },
    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) }

    return (
      <React.Fragment>

        <Row>

          <Col span={12}>

            <Input.Search
              style={{ width: 250 }}
              placeholder="Search"
              onChange={onChangeSearch}
              value={searchTerm}
            />

          </Col>

          <Col span={12} className="text-right">

            <Button type="primary" onClick={onClickNewProject}>
              New Project
            </Button>

          </Col>

        </Row>

        <Row>

          <Col span={24} style={{ marginTop: 15 }}>

            <Table
                dataSource={client.projects}
                scroll={scroll}
                columns={columns}
                rowKey={record => record.id} />

          </Col>

        </Row>

      </React.Fragment>



    )
  };
}

import fetchSettings from '../Services/fetchSettings'
import { setPagination } from '../State/pagination'

export default () => (dispatch) => {

  dispatch(setPagination({ current: 1 }))

  dispatch(fetchSettings())
  
}

// @flow

import * as React from 'react';
import { ArrowRightOutlined, CaretDownOutlined, DeleteOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import NotesPopover from './Components/NotesPopover';
import styles from './Component.css';

type Props = {
  order: any,
  onClickActionMenuItem: (key: string) => void,
  onChangeNotes: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      order,
      onClickActionMenuItem,
      onChangeNotes
    } = this.props;

    const menu = (

      <Menu onClick={({ key }) => onClickActionMenuItem(key)}>

        <Menu.Item key="SEND">
          <MailOutlined />
          {' Send Acknowledgement'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="EDIT">
          <EditOutlined />
          {' Edit Details'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="DELETE_FILE" disabled={!order.order_file_storage_path}>
          <DeleteOutlined />
          {' Delete PO File'}
        </Menu.Item>

        <Menu.Item key="DELETE" disabled={order.action_statuses.delete !== true}>
          <DeleteOutlined />
          {' Delete Order'}
        </Menu.Item>

      </Menu>

    );
    //      <div className={styles.stack}>
    return (
      <div className='_header_bar'>

        <h1 className={styles.heading}>
          {order.reference}
        </h1>

        <NotesPopover
          notes={order.notes}
          onChangeNotes={onChangeNotes}
        />

        <div style={{ width: 16 }} />

        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="primary">
            {'Actions '}
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>
    );
  }
}

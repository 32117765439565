import moment from 'moment';
import { omit } from 'lodash';
import { getEstimates, getEstimateTotals } from '../../../HttpRequests/estimates';
import { loadEstimates } from '../State/estimates';
import { loadEstimateTotals } from '../State/estimateTotals';
import { setPagination } from '../State/pagination';
import { setFetching } from '../State/fetching';
import { setFetchingTotals } from '../State/fetchingTotals';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.Estimates;
  
  try {

    const params = {};
    
    params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'decisionMaker',
      'latestActionByUser',
    ].join();

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }
    
    if (searchTerm) {
      params.search = searchTerm;
    }
    
    if (filters.smart_status !== 'all') {
      params['filter[smart_status]'] = filters.smart_status;
    }
    
    if (filters.extra_works_status === 'new') {
      params['filter[extra_works]'] = 0;
    } else if (filters.extra_works_status === 'extra') {
      params['filter[extra_works]'] = 1;
    }
    
    if (filters.priority) {
      params['filter[priority]'] = filters.priority;
    }

    if (filters.assigned === 'assigned') {
      params['filter[assigned]'] = true;
    } else if (filters.assigned === 'unassigned') {
      params['filter[unassigned]'] = true;
    } else {
      params['filter[assigned_to_user_id]'] = filters.assigned;
    }

    if (filters.user_id === 'assigned') {
      params['filter[has_owner]'] = true;
    } else if (filters.user_id === 'unassigned') {
      params['filter[doesnt_have_owner]'] = true;
    } else {
      params['filter[user_id]'] = filters.user_id;
    }
    
    if (filters.client_id) {
      params['filter[client_id]'] = filters.client_id;
    }
    
    if (filters.status_key.length > 0) {
      params['filter[status_key]'] = filters.status_key.join(',');
    }
    
    if (filters.latest_action_key.length > 0) {
      params['filter[latest_action_key]'] = filters.latest_action_key.join(',');
    }

    if (filters.decision_maker_id) {
      params['filter[decision_maker_id]'] = filters.decision_maker_id;
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (filters.latest_only) {
      params['filter[latest_only]'] = true;
    }

    dispatch(setFetching(true));

    //dispatch(setFetchingTotals(true));

    const response = await getEstimates({ params });

    dispatch(loadEstimates(response.data.data));

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }))

    dispatch(setFetching(false));
    
    //const response2 = await getEstimateTotals({ params: omit(params, [ 'page[size]', 'page[number]', 'csort' ]) });
    
    //dispatch(loadEstimateTotals(response2.data.data));

    //dispatch(setFetchingTotals(false));

  } catch (error) {

    handleException(error);

  }
}
// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { Dropdown } from 'antd';
import { withConfig } from '../Contexts/ConfigContext';

type Props = {
    title: ?string,
    icon?: React.Node,
    target?: ?string,
    hreffull?: ?string,
    key?: ?string,
    iconSize?: number,
    href: ?string,
    selected: ?boolean,
    config: any,
    dropdown?: React.Node
};

type State = {
  hovered: boolean,
  active: boolean
};

class HeaderButton extends React.Component<Props, State> {

  static defaultProps = {
    icon: null,
    iconSize: 15,
    dropdown: null
  }

  state = {
    hovered: false,
    active: false
  }

  render () {

    const { title, icon, iconSize, target, href, hreffull, selected, config, dropdown, key } = this.props;

    const { hovered, active } = this.state;

    const theme = config.theme.headerButtons;

    const buttonColor =
      (selected && theme.buttonColorSelected) ||
      (active && theme.buttonColorActive) ||
      (hovered && theme.buttonColorHovered) ||
      theme.buttonColor;

    const textColor =
      (selected && theme.textColorSelected) ||
      (active && theme.textColorActive) ||
      (hovered && theme.textColorHovered) ||
      theme.textColor;

    const iconColor =
      (selected && theme.iconColorSelected) ||
      (active && theme.iconColorActive) ||
      (hovered && theme.iconColorHovered) ||
      theme.iconColor;

    const styles = {

      button: {
        backgroundColor: buttonColor,
      },

      text: {
        color: textColor,
      },

      icon: {
        color: iconColor,
      }
    }

    const content = (
      <React.Fragment>
        {(!!icon) && (
          <Icon
            icon={icon}
            size={iconSize}
            style={styles.icon}
          />
        )}

        {(!icon && title) && (
          <div
            className="_Portal_HeaderButton_text"
            style={styles.text}
          >
            {title}
          </div>
        )}
      </React.Fragment>
    )

    if (dropdown) return (
      <Dropdown trigger={['click']} overlay={dropdown}>
        <div
          role='presentation'
          id={title}
          className={"_Portal_HeaderButton_button " + title}
          style={styles.button}
          onMouseEnter={() => { this.setState({ hovered: true, active: false }) }}
          onMouseLeave={() => { this.setState({ hovered: false, active: false }) }}
          onMouseDown={() => { this.setState({ active: true }) }}
          onMouseUp={() => { this.setState({ active: false }) }}
        >
          {content}
        </div>
      </Dropdown>
    )

      if (hreffull) return (
          <React.Fragment>
            <a href={hreffull} target={target} className="_Portal_HeaderButton_button" style={styles.button}
               onMouseEnter={() => {this.setState({ hovered: true, active: false })}}
               onMouseLeave={() => {this.setState({ hovered: false, active: false })}}
               onMouseDown={() => {this.setState({ active: true })}}
               onMouseUp={() => {this.setState({ active: false })}}

            >{content}</a>
          </React.Fragment>
      )

      return (

      <Link
        to={href}
        target={target}
        className="_Portal_HeaderButton_button"
        style={styles.button}
        onMouseEnter={() => {this.setState({ hovered: true, active: false })}}
        onMouseLeave={() => {this.setState({ hovered: false, active: false })}}
        onMouseDown={() => {this.setState({ active: true })}}
        onMouseUp={() => {this.setState({ active: false })}}
      >
        {content}
      </Link>
    )
  }
}

export default withConfig(HeaderButton);

// @flow

import * as React from 'react';
import { Breadcrumb, Button, Divider } from 'antd';
import { isAdmin } from 'Common'

export default ({ onClickCaveats }) => (

  <React.Fragment>

    <div style={{ display: 'flex' }}>

      <div style={{ flex: 1 }}>

        <Breadcrumb separator="»">

          <Breadcrumb.Item>
            Home
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            Estimates
          </Breadcrumb.Item>

        </Breadcrumb>

      </div>

      {isAdmin() && (
        <div>
          <Button type='link' onClick={onClickCaveats}>Caveats</Button>
        </div>
      )}

    </div>

    <Divider />

  </React.Fragment>

)
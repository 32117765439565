import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSettingModal = createAction('SCREENS/SETTINGS/OPEN_SETTING_MODAL')
const closeSettingModal = createAction('SCREENS/SETTINGS/CLOSE_SETTING_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openSettingModal]: (state, action) => ({
      ...state,
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeSettingModal]: () => defaultState,
  },
  defaultState,
)

export { openSettingModal, closeSettingModal, reducer }

import React, { useRef, useLayoutEffect, useState } from "react";
import { Modal, Dropdown, Menu, Button } from 'antd'
import { DeleteOutlined, FrownOutlined, SafetyOutlined, SmileOutlined } from '@ant-design/icons'
import Form from './Form'
import styles from './styles.css'
import {Field} from "redux-form";

type Props = {
    id: ?any,
    rams_html: string,
    project: any,
    submitting: boolean,
    logo: string,
    resetPasswordModal: {},
    componentWillMount: (props: any) => void,
    onClickSubmit: () => void,
    onClickCancel: () => void,
    onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {
    componentDidMount() {
        let wh = window.innerHeight;

        wh = wh - 230;
        document.getElementById('rams-signing').style.maxHeight = wh + "px";
        if (document.getElementById('sign-button')) {
            document.getElementById('sign-button').setAttribute("disabled", 1);
        }
    }

  componentWillMount = () => this.props.componentWillMount(this.props)

  getOptionsMenu = () => {
    const {
      onClickDelete,
    } = this.props
    return (
      <Menu
        onClick={({ key }) => {
          if (key === 'delete') {
            onClickDelete()
          }
        }}
      >
        <Menu.Item key='delete'><DeleteOutlined />{` Delete`}</Menu.Item>
      </Menu>
    )
  }


  render() {

    const {
        id,
        rams_record,
        rams_html,
        project,
        signed,
        submitting,
        logo,
        onUpload,
        onClickSubmit,
        onClickCancel,
    } = this.props

    const footer = [];

    // cant use state within class component
    //const [isSignDisabled, setSignDisabled] = useState(false);

    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      //setBottom(bottom)
      if (bottom)
      {
          if (document.getElementById('sign-button')) {
            document.getElementById('sign-button').setAttribute("disabled", 0);
            document.getElementById('sign-button').removeAttribute("disabled");
          }
      }
    };

    /*if (id) {
        footer.push([
          <Dropdown key='options' overlay={this.getOptionsMenu()}>
            <Button style={{ float: 'left' }} loading={submitting}>
              Options
            </Button>
          </Dropdown>,
        ])
    }

    disabled={1}
    */

    if (signed == 0)
    {
        footer.push([
            <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
                Close
            </Button>,
            <Button id='sign-button' key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
                Sign
            </Button>,
        ])
    }else{
        footer.push([
            <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
                Close
            </Button>,
            <Button id='sign-button' key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
                Sign
            </Button>,
        ])
    }

    let title = '';
    if (typeof project === 'object' && !Array.isArray(project) && project !== null)
        title = project.client_site.client.name + " / " + project.client_site.name + " / " + project.number;

    return (

      <Modal
        title={'RAMS Signing' + title + ' - Scroll to the bottom of the document to sign.'}
        footer={footer}
        onCancel={onClickCancel}
        visible
        width="100%"
        wrapClassName="rams-modal-wrap"
      >
        <div id="rams-signing" onScroll={handleScroll}>
            <Form
              id={id}
              rams_record={rams_record}
              rams_html={rams_html}
              submitting={submitting}
              logo={logo}
            />

        </div>

      </Modal>

    )

  }

}

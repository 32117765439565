import * as React from "react";
import PropTypes from "prop-types";
import Screen from '../../Components/Screen'

// import SettingsMenu from '../../Components/SettingsMenu';
import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import CaveatModal from "../../Modals/Caveat";

export default class extends React.PureComponent {
  
  static propTypes = {
    selectedCaveatId: PropTypes.number,
    showCaveatModal: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseCaveatModal: PropTypes.func.isRequired,
    didCreateCaveat: PropTypes.func.isRequired,
    didUpdateCaveat: PropTypes.func.isRequired,
    didDeleteCaveat: PropTypes.func.isRequired
  };

  static defaultProps = { selectedCaveatId: null };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showCaveatModal,
      selectedCaveatId,
      onCloseCaveatModal,
      didCreateCaveat,
      didUpdateCaveat,
      didDeleteCaveat
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header />

        <Table />

        {showCaveatModal && (
          <CaveatModal
            caveatId={selectedCaveatId}
            onClose={onCloseCaveatModal}
            didCreate={didCreateCaveat}
            didUpdate={didUpdateCaveat}
            didDelete={didDeleteCaveat}
          />
        )}

        {/* <SettingsMenu /> */}

      </Screen>

    );
  }
}

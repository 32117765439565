import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'

import Initializing from './Components/Initializing'
import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Tabs from './Components/Tabs'
import Items from './Components/Items'
import Footer from './Components/Footer'
import Sidebar from './Components/Sidebar'
import SubValuationModal from '../../Modals/SubValuation'

const SubValuation = props => {

  const {
    subValuation,
    activeTab,
    subValuationModal,
    componentDidMount,
    componentWillUnmount,
    onCloseSubValuationModal,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  if (!subValuation) return <Initializing />

  return (

    <Screen style={{ marginRight: 280, paddingRight: 30 }}>

      <Breadcrumbs subValuation={subValuation} />

      <Divider />

      <div style={{ fontSize: 16, color: '#999', marginBottom: 8 }}>Sub Valuation</div>

      <Header />

      <Tabs />

      {activeTab === 'ITEMS' && <Items />}

      <Footer />

      <Sidebar />

      {subValuationModal.show && (
        <SubValuationModal
          {...subValuationModal}
          subValuation={subValuation}
          onClose={onCloseSubValuationModal}
        />
      )}
      
    </Screen>
    
  )

}

SubValuation.propTypes = {
  subValuation: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  subValuationModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseSubValuationModal: PropTypes.func.isRequired,
}

SubValuation.defaultProps = {
  subValuation: null,
}

export default SubValuation

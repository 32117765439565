// @flow
import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Table, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import { getTeamUrl } from 'Common';

type Props = {
  clients: [],
  pagination: {},
  fetching: boolean,

  onChangeTable: () => void
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      clients,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const columns = [

      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (text, record) => (
          <NavLink to={getTeamUrl(`clients/${record.id}/view`)}>
            { record.name }
          </NavLink>
        )
      },

      {
        title: 'Telephone',
        dataIndex: 'telephone',
      },

      {
        title: 'Risk Level',
        dataIndex: 'client_risk_level_key',
        key: 'client_risk_level_key',
        width: 150,
        sorter: true,
        render: (text, record) => record.client_risk_level && (
          <Tag
            color={({ "1": "green", "2": "orange", "3": "red" })[record.client_risk_level_key]}
            style={{ cursor: 'default' }}
          >
            {record.client_risk_level.title}
          </Tag>
        )
      },

      {
        title: 'Credit Limit',
        dataIndex: 'credit_limit',
        key: 'credit_limit',
        width: 150,
        sorter: true,
        render: (text, record) => record.credit_limit !== null && `£${numeral(record.credit_limit).format('0,0.00')}`
      },

      {
        title: 'Experian Limit',
        dataIndex: 'aldermore_limit',
        key: 'aldermore_limit',
        width: 150,
        sorter: true,
        render: (text, record) => record.aldermore_limit !== null && `£${numeral(record.aldermore_limit).format('0,0.00')}`
      },

      {
        title: 'Outstanding',
        dataIndex: 'value_outstanding',
        key: 'value_outstanding',
        width: 150,
        sorter: true,
        render: (text, record) => record.value_outstanding !== null && `£${numeral(record.value_outstanding).format('0,0.00')}`
      },

      {
        title: 'Review Date',
        dataIndex: 'value_outstanding_review_date',
        key: 'value_outstanding_review_date',
        width: 150,
        sorter: true,
        render: (text, record) => record.value_outstanding_review_date !== null && moment(record.value_outstanding_review_date).format('DD/MM/YYYY')
      },

      {
        title: 'Project Remaining',
        dataIndex: 'projects_value_remaining',
        key: 'projects_value_remaining',
        width: 200,
        sorter: true,
        render: (text, record) => record.projects_value_remaining !== null && `£${numeral(record.projects_value_remaining).format('0,0.00')}`
      },

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) }

    return (
      <Table
        dataSource={clients}
        columns={columns}
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        scroll={scroll}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />
    )

  };

}

          
import React from 'react'
import Screen from '../../Components/Screen'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Table from './Components/Table'

import Filters from './Components/Filters'
import Setting from '../../Modals/Setting'
import User from "../../Modals/User";

type Props = {
  settings: [],
  fetching: boolean,
  searchTerm: string,
  filters: {},
  pagination: {},
  settingModal: boolean,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickExport: () => void,
  onClickNewSetting: () => void,
  onCloseSettingModal: () => void,
  didCreateSetting: () => void,
  didUpdateSetting: () => void,
  didDeleteSetting: () => void,
  didDeactivateSetting: () => void,
  didReactivateSetting: () => void,
}

export default class extends React.PureComponent<Props> {


  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      settings,
      fetching,
      searchTerm,
      filters,
      pagination,
      settingModal,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onClickRow,
      onClickExport,
      onClickNewSetting,
      onCloseSettingModal,
      didCreateSetting,
      didUpdateSetting,
      didDeleteSetting,
      didDeactivateSetting,
      didReactivateSetting,
    } = this.props
     
    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
          onClickNewSetting={onClickNewSetting}
        />

        <Filters
             filters={filters}
             onChangeFilter={onChangeFilter}
        />
        
        <Table
          settings={settings}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
          onClickRow={onClickRow}
        />

	      {settingModal.show && (
        	<Setting
	          id={settingModal.id}
	          onClose={onCloseSettingModal}
	          didCreate={didCreateSetting}
	          didUpdate={didUpdateSetting}
	          didDelete={didDeleteSetting}
	          didDeactivate={didDeactivateSetting}
	          didReactivate={didReactivateSetting}
	        />
        )}

      </Screen> 

    )

  }

}

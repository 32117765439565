export default (state) => {

  const { search, filters, sorting } = state.screens.Subbies.SubOrders

  const params = {
    search,
    sort: sorting.order ? `${sorting.order === 'descend' ? '-' : ''}${sorting.columnKey}` : '-date',
  }

  if (filters.subcontractor_id.length) params['filter[subcontractor_id]'] = filters.subcontractor_id.join(',')
  if (filters.project_id.length) params['filter[project_id]'] = filters.project_id.join(',')
  if (filters.date_range.length) params['filter[date_range]'] = filters.date_range.map(date => date.format('YYYY-MM-DD')).join(',')
  if (filters.user_id.length) params['filter[user_id]'] = filters.user_id.join(',')

  return params

}

// @flow
import * as React from 'react';
import { Radio, Select, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const defaultSelectProps = {
  style: { width: 230 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: true,
  optionFilterProp: 'children',
};

type Props = {
  filters: any,
  valuationStatuses: [],
  clients: [],
  users: [],

  onChangeFilter: Function
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      filters,
      valuationStatuses,
      clients,
      users,
      onChangeFilter
    } = this.props;

    const valuationOptions = [
      { title: 'All', key: 'all'},
      ...valuationStatuses
    ]

    return (

      <div>

        <div class="_filter_bar" >

          <Radio.Group
            value={filters.status_key}
            onChange={(event) => onChangeFilter('status_key', event.target.value)}
          >

            {valuationOptions.map(option => (
              <Radio.Button
                style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
                value={option.key}
                key={option.key}
              >
                {option.title}
              </Radio.Button>
            ))}

          </Radio.Group>

        </div>

        <div className="_filter_bar">

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Billing Type"
            value={filters.billing_type}
            onChange={(value) => onChangeFilter('billing_type', value)}
          >

            {['Application', 'Client Valuation', 'Invoice', 'Unknown'].map(billingType => (
              <Select.Option key={billingType}>
                {`${billingType}`}
              </Select.Option>
            ))}

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Client"
            value={filters.client_id}
            onChange={(value) => onChangeFilter('client_id', value)}
          >

            {clients.map(client => (
              <Select.Option key={client.id}>
                {`${client.name}`}
              </Select.Option>
            ))}

          </Select>

          <div style={{ width: 10 }} />

          <RangePicker
            value={filters.date_between}
            onChange={(value) => onChangeFilter('date_between', value)}
            format="DD/MM/YYYY"
          />

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Contracts Manager"
            value={filters.contracts_manager}
            onChange={(value) => onChangeFilter('contracts_manager', value)}
          >

            {users.map(user => (
              <Select.Option key={user.id}>
                {`${user.first_name} ${user.last_name}`}
              </Select.Option>
            ))}

          </Select>

        </div>

      </div>

    )
  };
}

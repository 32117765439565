import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import { orderBy } from 'lodash'
import moment from 'moment'
import styles from './TimesheetAdjustmentsPopover.css'

const TimesheetAdjustmentsPopover = props => (
  <Popover
    title='Adjustments'
    content={(
      <table className={styles.table}>
        <thead>
          <tr>
            <th>By</th>
            <th className={styles.num}>Amount</th>
            <th>Reason</th>
            <th>When</th>
          </tr>
        </thead>
        <tbody>
          {orderBy(props.timesheetAdjustments, 'updated_at', 'desc').map(timesheetAdjustment => (
            <tr
              key={timesheetAdjustment.id}
              className={styles.row}
              onClick={e => {
                e.stopPropagation()
                props.onClickRow(timesheetAdjustment, props.timesheet)
              }}
            >
              <td>{timesheetAdjustment.user.full_name}</td>
              <td className={styles.num}>{timesheetAdjustment.hours_formatted}</td>
              <td>{timesheetAdjustment.reason}</td>
              <td>{moment(timesheetAdjustment.updated_at).fromNow()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  >
    {props.children}
  </Popover>
)

TimesheetAdjustmentsPopover.propTypes = {
  timesheetAdjustments: PropTypes.array.isRequired,
  timesheet: PropTypes.object.isRequired,
  onClickRow: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default TimesheetAdjustmentsPopover

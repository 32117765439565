import loadingIndicator from '../../resources/images/loading.gif';
import leadPmLogo from '../../resources/images/lead-pm-logo-white.png';

const darkgrey = '#005BED';
const blue = '#43B7FF';

export default {

  header: {
    barColor: darkgrey,
    logo: leadPmLogo
  },

  headerButtons: {
    textColorSelected: blue,
    buttonColorSelected: darkgrey
  },

  startup: {
    loadingIndicator
  },

  login: {
    brandingPanelColor: darkgrey,
    brandingPanelLogo: leadPmLogo,
    inputBorderColorFocused: blue,
    submitButtonColor: blue
  }

}
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n._3o68a7ribEu33yS5kDRYGQ {\n    width: 32px;\n    height: 32px;\n    line-height: 26px;\n    border-radius: 16px;\n    border: 3px solid white;\n    margin-right: -8px;\n    text-align: center;\n    font-size: 10px;\n    font-weight: 500;\n    display: inline-block;\n    background-color: rgb(229, 239, 251);\n    color: rgb(20, 83, 141);\n    cursor: help;\n}\n", "", {"version":3,"sources":["/home/forge/qa.leadpm.io/frontend/src/Screens/ProjectView/Components/RamsRecords/Component.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,oBAAoB;IACpB,wBAAwB;IACxB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,qCAAqC;IACrC,wBAAwB;IACxB,aAAa;CAChB","file":"Component.css","sourcesContent":["\n.icon {\n    width: 32px;\n    height: 32px;\n    line-height: 26px;\n    border-radius: 16px;\n    border: 3px solid white;\n    margin-right: -8px;\n    text-align: center;\n    font-size: 10px;\n    font-weight: 500;\n    display: inline-block;\n    background-color: rgb(229, 239, 251);\n    color: rgb(20, 83, 141);\n    cursor: help;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"icon": "_3o68a7ribEu33yS5kDRYGQ"
};
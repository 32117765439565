import { message } from 'antd'
import modalProps from '../modalProps'
import { createValuationDate, updateValuationDate } from '../../../HttpRequests/valuationDates'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {

  const { valuationDate, project } = modalProps()

  const data = {
    date: values.date,
    to_date: values.to_date,
  }

  try {

    if (valuationDate) {

      await updateValuationDate({ id: valuationDate.id, data })

      message.success('Schedule updated')

    } else {

      data.project_id = project.id

      await createValuationDate({ data })

      message.success('Schedule updated')

    }

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}

import moment from 'moment';
import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const type = options.key;

  const { searchTerm, filters, sorting } = getState().screens.Valuations;

  const params = {};

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  }
  
  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.status_key !== 'all') {
    params['filter[status_key]'] = filters.status_key;
  }
  
  if (filters.billing_type) {
    params['filter[billing_type]'] = filters.billing_type;
  }
  
  if (filters.client_id) {
    params['filter[client_id]'] = filters.client_id;
  }
  
  if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
    params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
  }

  if (filters.contracts_manager) {
    params['filter[contracts_manager]'] = filters.contracts_manager;
  }

  let url = `valuations/export?type=${type}`;

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports');

}
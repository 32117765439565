import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Select, Input, Button } from 'antd';
import MetaTags from 'react-meta-tags'
import moment from 'moment'
import Screen from '../../Components/Screen'
import styles from './styles.css'

export default class Timesheet extends React.PureComponent {

  static propTypes = {
    projects: PropTypes.array.isRequired,
    data: PropTypes.object,
    form: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onBlurNotes: PropTypes.func.isRequired,
    onClockIn: PropTypes.func.isRequired,
    onClockOut: PropTypes.func.isRequired,
    onSwitchProject: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: null,
  }

  componentDidMount = () => this.props.componentDidMount()

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      projects,
      data,
      form,
      onChangeField,
      onBlurNotes,
      onClockIn,
      onClockOut,
      onSwitchProject,
    } = this.props

    if (!data) return false

    const {
      timesheet_posting: timesheetPosting,
      recent_timesheet_postings: recentTimesheetPostings,
      week_stats: weekStats,
      month_stats: monthStats,
    } = data

    const getStatus = () => {
      if (timesheetPosting) {
        return (
          <div className={styles.clockedIn}>
            <CheckCircleOutlined className={styles.clockedInIcon} />
            {`Clocked In`}
          </div>
        );
      }
      return (
        <div className={styles.clockedOut}>
          <MinusCircleOutlined className={styles.clockedOutIcon} />
          {'Clocked Out'}
        </div>
      );
    }

    const getProject = (project) => `[${project.number}] ${project.client_site.client.name} / ${project.client_site.name}`

    return (

      <Screen>

        <MetaTags>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        </MetaTags>

        <div className={styles.wrapper}>

          <h2>{data.subcontractor.name}</h2>

          <div className={styles.info}>
            <div className={styles.label}>Status</div>
            <div className={styles.value}>{getStatus()}</div>
          </div>

          {timesheetPosting && (

            <React.Fragment>

              <div className={styles.info}>
                <div className={styles.label}>Project</div>
                <div className={styles.value}>{getProject(timesheetPosting.project)}</div>
              </div>

              <div className={styles.info}>
                <div className={styles.label}>Started</div>
                <div className={styles.value}>{moment(timesheetPosting.start_date_time).fromNow()}</div>
              </div>

            </React.Fragment>

          )}

          <br />

          {!!timesheetPosting && (

            <React.Fragment>

              <Input.TextArea
                value={form.notes}
                onChange={event => onChangeField('notes', event.target.value)}
                onBlur={onBlurNotes}
                label='Notes'
                rows={3}
              />

              <br />

            </React.Fragment>

          )}

          <Select
            value={form.projectId}
            onChange={value => onChangeField('projectId', value)}
            size='large'
            showSearch
            allowClear
            placeholder={timesheetPosting ? 'Switch Project' : 'Select Project'}
            optionFilterProp='children'
            style={{ width: '100%' }}
          >
            {projects.filter(project => project.status_key === 2).map(project => (
              <Select.Option key={project.id}>
                {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
              </Select.Option>
            ))}
          </Select>

          <br />

          {!timesheetPosting && (

            <Button
              type='primary'
              size='large'
              onClick={onClockIn}
              disabled={!form.projectId}
              style={{ width: '100%' }}
            >
              Clock In
            </Button>

          )}

          {!!timesheetPosting && (

            <React.Fragment>

              {!!form.projectId && (

                <Button
                  type='primary'
                  size='large'
                  onClick={onSwitchProject}
                  style={{ width: '100%' }}
                >
                  Switch Project
                </Button>

              )}

              {!form.projectId && (

                <Button
                  type='danger'
                  size='large'
                  onClick={onClockOut}
                  style={{ width: '100%' }}
                >
                  Clock Out
                </Button>

              )}

            </React.Fragment>

          )}

        </div>

        {!!recentTimesheetPostings.length && (

          <div>

            <br />
            <br />

            <h3 style={{ marginBottom: 20 }}>Last 10 Postings</h3>

            {recentTimesheetPostings.map((recentPosting, index) => (

              <div key={recentPosting.id}>

                <h4>{moment(recentPosting.start_date_time).fromNow()}</h4>

                <div className={styles.info}>
                  <div className={styles.label}>Project</div>
                  <div className={styles.value}>{getProject(recentPosting.project)}</div>
                </div>

                <div className={styles.info}>
                  <div className={styles.label}>Hours</div>
                  <div className={styles.value}>{recentPosting.hours_formatted}</div>
                </div>

                {(index + 1) < recentTimesheetPostings.length && <br />}

              </div>

            ))}

          </div>

        )}

        <br />
        <br />

        <div>

          <h3 style={{ marginBottom: 20 }}>This Week</h3>

          <div className={styles.info}>
            <div className={styles.label}>Postings</div>
            <div className={styles.value}>{weekStats.timesheet_postings.length}</div>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Total Hours</div>
            <div className={styles.value}>{weekStats.total_time}</div>
          </div>

        </div>

        <br />
        <br />

        <div>

          <h3 style={{ marginBottom: 20 }}>{monthStats.month}</h3>

          <div className={styles.info}>
            <div className={styles.label}>Postings</div>
            <div className={styles.value}>{monthStats.timesheet_postings.length}</div>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Total Hours</div>
            <div className={styles.value}>{monthStats.total_time}</div>
          </div>

        </div>

      </Screen>

    )

  }

}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Input, Select, InputNumber, DatePicker, Switch, Button, Divider, Tooltip } from 'antd'
import { CheckOutlined, DeleteOutlined, PoundOutlined } from '@ant-design/icons'
import moment from 'moment'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 10 }} />

export default class SubcontractorCellModal extends React.PureComponent {

  static propTypes = {
    period: PropTypes.object.isRequired,
    subcontractor: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    jobs: PropTypes.array.isRequired,
    plannerCell: PropTypes.object.isRequired,
    duplicate: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onAddJob: PropTypes.func.isRequired,
    onUpdateJob: PropTypes.func.isRequired,
    onRemoveJob: PropTypes.func.isRequired,
    onUpdatePlannerCell: PropTypes.func.isRequired,
    onChangeDuplicateKey: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      period,
      subcontractor,
      projects,
      activeTab,
      jobs,
      plannerCell,
      duplicate,
      onChangeActiveTab,
      onAddJob,
      onUpdateJob,
      onRemoveJob,
      onUpdatePlannerCell,
      onChangeDuplicateKey,
      onSave,
      onClose,
    } = this.props

    const footer = [
      <Button
        key='cancel'
        onClick={() => onClose()}
      >
        Cancel
      </Button>,
      <Button
        key='save'
        type='primary'
        onClick={onSave}
      >
        Save
      </Button>,
    ]

    const jobsInSlot = jobs.filter(job => job.slot === activeTab)

    return (

      <Modal
        title={`${period.day_of_week} ${period.date_formatted}`}
        visible
        width={600}
        footer={footer}
        onCancel={() => onClose()}
        bodyStyle={{ padding: '24px 24px 13px 24px' }}
      >
        
        <div className={styles.wrapper}>

          <div style={{ fontSize: 15, fontWeight: 600 }}>{subcontractor.name}</div>

          <br />

          <Tabs activeKey={activeTab} onChange={onChangeActiveTab} size='small' style={{ marginBottom: 8 }}>
            <Tabs.TabPane key='Day' tab='Day' />
            <Tabs.TabPane key='AM' tab='AM' />
            <Tabs.TabPane key='PM' tab='PM' />
            <Tabs.TabPane key='Night' tab='Night' />
            <Tabs.TabPane key='Absence' tab='Absence' />
            <Tabs.TabPane key='Alert' tab='Alert' />
          </Tabs>

          {activeTab !== 'Absence' && activeTab !== 'Alert' && (

            <React.Fragment>

              {jobsInSlot.map((job, index) => (

                <div key={job.key} className={styles.project}>

                  <div style={{ width: 16 }}>{index + 1}</div>

                  <Spacer />

                  <Select
                    value={job.project_id}
                    onChange={value => onUpdateJob(job.key, 'project_id', value)}
                    showSearch
                    optionFilterProp='children'
                    style={{ flex: 1 }}
                  >
                    {projects.map(project => (
                      <Select.Option key={project.id} value={project.id}>
                        {`[${project.number}] ${project.client_site.client.name} - ${project.client_site.name}`}
                      </Select.Option>
                    ))}
                  </Select>

                  {!!subcontractor.is_multiple && (

                    <React.Fragment>

                      <Spacer />

                      <InputNumber
                        value={job.quantity}
                        onChange={value => onUpdateJob(job.key, 'quantity', value)}
                        style={{ width: 60 }}
                      />

                    </React.Fragment>

                  )}

                  <Spacer />

                  <Tooltip title={job.price_work ? 'Unmark as Price Work' : 'Mark as Price Work'}>
                    <Button
                      type={job.price_work ? 'primary' : 'default'}
                      icon={<PoundOutlined />}
                      onClick={() => onUpdateJob(job.key, 'price_work', !job.price_work)}
                    />
                  </Tooltip>

                  <Spacer />

                  <Tooltip title={job.confirmed ? 'Unconfirm' : 'Confirm'}>
                    <Button
                      type={job.confirmed ? 'primary' : 'default'}
                      icon={<CheckOutlined />}
                      onClick={() => onUpdateJob(job.key, 'confirmed', !job.confirmed)}
                    />
                  </Tooltip>

                  <Spacer />

                  <Tooltip title='Remove'>
                    <Button type='danger' icon={<DeleteOutlined />} onClick={() => onRemoveJob(job.key)} />
                  </Tooltip>

                </div>

              ))}

              {!!jobsInSlot.length && (

                <div className={styles.addProject}>

                  <div style={{ width: 26 }} />

                  <a
                    role='link'
                    tabIndex={0}
                    onClick={onAddJob}
                    style={{ fontSize: 13, fontStyle: 'italic' }}
                  >
                    + Add project
                  </a>

                </div>

              )}

              {!jobsInSlot.length && (

                <div>No projects scheduled for this slot. Click <a role='link' tabIndex={0} onClick={onAddJob}>here</a> to add.</div>

              )}

            </React.Fragment>
            
          )}

          {activeTab === 'Absence' && (

            <Input.TextArea
              value={plannerCell.absence}
              onChange={e => onUpdatePlannerCell('absence', e.target.value)}
              rows={4}
              placeholder='Insert absence here'
            />

          )}

          {activeTab === 'Alert' && (

            <Input.TextArea
              value={plannerCell.alert}
              onChange={e => onUpdatePlannerCell('alert', e.target.value)}
              rows={4}
              placeholder='Insert alert here'
            />

          )}

          <Divider style={{ marginTop: activeTab !== 'Absence' && activeTab !== 'Alert' && !!jobsInSlot.length && 16 }} />

          <div className={styles.inputRow}>

            <div>Duplicate cell up until:</div>

            <DatePicker
              value={duplicate.upToDate}
              onChange={value => onChangeDuplicateKey('upToDate', value)}
              disabledDate={date => date < moment(period.date)}
              style={{ width: 135 }}
            />

          </div>

          <div className={styles.inputRow}>

            <div>Include weekends:</div>

            <Switch
              checked={duplicate.incWeekends}
              onChange={value => onChangeDuplicateKey('incWeekends', value)}
            />

          </div>

        </div>

      </Modal>

    )

  }

}

import { flatten } from 'lodash'
import screenProps from '../screenProps'
import { findSubInvoice } from '../../../HttpRequests/subInvoices'
import { getSubValuations } from '../../../HttpRequests/subValuations'
import { setOriginalItems } from '../State/originalItems'
import { getSubOrders } from '../../../HttpRequests/subOrders'
import { setSubInvoice } from '../State/subInvoice'
import { setSubValuations } from '../State/subValuations'
import { setSubOrders } from '../State/subOrders'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'subcontractor',
      'user',
      'subValuations',
    ].join(','),
  }

  try {

    const findSubInvoiceResponse = await findSubInvoice({ id, params })

    const subInvoice = findSubInvoiceResponse.data

    const subValuationIds = subInvoice.sub_valuations.map(row => row.id)

    const getSubValuationsResponse = await getSubValuations({
      params: {
        'filter[id]': subValuationIds.length ? subValuationIds.join(',') : 0,
        sort: 'date',
        include: 'subValuationItems.subOrderItem.subOrderGroup',
      },
    })

    const subValuations = getSubValuationsResponse.data

    const subOrderIds = flatten(subValuations.map(row => row.sub_valuation_items)).map(row => row.sub_order_item.sub_order_group.sub_order_id)

    const getSubOrdersResponse = await getSubOrders({
      params: {
        'filter[id]': subOrderIds.length ? subOrderIds.join(',') : 0,
        sort: 'date',
        include: 'subOrderGroups.subOrderItems',
      },
    })

    dispatch(setSubValuations(subValuations))

    dispatch(setSubOrders(getSubOrdersResponse.data))

    dispatch(setSubInvoice(subInvoice)) // set last

    dispatch(setOriginalItems(subValuations))

  } catch (error) {

    handleException(error)

  }

}

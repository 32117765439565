import { connect } from 'react-redux'
import { formValueSelector, isSubmitting } from 'redux-form'
import User from './User'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onClickReset from './Handlers/onClickReset'
import onClickRequireReset from './Handlers/onClickRequireReset'
import onClickDeactivate from './Handlers/onClickDeactivate'
import onClickReactivate from './Handlers/onClickReactivate'
import onCloseResetPasswordModal from './Handlers/onCloseResetPasswordModal'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  type: formValueSelector(FORM_NAME)(state, 'type'),
  deactivated: !!formValueSelector(FORM_NAME)(state, 'deactivated_at'),
  submitting: isSubmitting(FORM_NAME)(state),
  resetPasswordModal: state.modals.User.resetPasswordModal,
})

const actionCreators = {
  componentWillMount,
  onClickSubmit,
  onClickCancel,
  onClickDelete,
  onClickReset,
  onClickRequireReset,
  onClickDeactivate,
  onClickReactivate,
  onCloseResetPasswordModal,
}

export default connect(mapStateToProps, actionCreators)(User)

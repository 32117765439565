import { openTimesheetAdjustmentModal } from '../State/timesheetAdjustmentModal'

export default (timesheetAmendment, timesheet) => (dispatch) => {

  const { hours_formatted: time } = timesheetAmendment

  const initialValues = {
    ...timesheetAmendment,
    type: timesheetAmendment.hours < 0 ? 'reduction' : 'addition',
    time: `${timesheetAmendment.hours < 0 ? time.substring(1) : time}:00`,
  }

  dispatch(openTimesheetAdjustmentModal({ id: timesheetAmendment.id, initialValues, timesheet }))
  
}

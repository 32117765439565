import { getUsers } from '../../../HttpRequests/users'
import { setUsers } from '../State/users'
import { setFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { searchTerm, filters, sorting, pagination } = getState().screens.Users

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (filters.type !== 'All') {
    params['filter[type]'] = filters.type
  }else{
      params['filter[type]'] = ['Admin','Premium','Advanced','Basic','Basic+'];
  }

  params['filter[status]'] = filters.status

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'full_name'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(setFetching(true))

  try {

    const response = await getUsers({ params })

    dispatch(setUsers(response.data.rows))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

  }

}

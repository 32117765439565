import { message } from 'antd'
import { clockTimesheetSubcontractorOut } from '../../../HttpRequests/timesheets'
import fetch from './fetch'
import { resetForm } from '../State/form'
import fetchProjects from '../../../Core/Services/fetchProjects'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { data } = getState().screens.TimesheetPosting

  try {
    
    await clockTimesheetSubcontractorOut({
      data: {
        timesheet_posting_id: data.timesheet_posting.id,
      },
    })
    
    dispatch(fetch())

    dispatch(resetForm())

    dispatch(fetchProjects())

    message.success('Clocked Out')

  } catch (error) {

    handleException(error)

  }

}

import {message} from 'antd'
import {updateSetting} from '../../../HttpRequests/settings'

export default (values) => async (dispatch, getState) => {


    if (values.processor == "FileUpload")
    {
        values.value = getState().modals.Setting.logo;
        values.value = values.value.replace("/settings/files/", "");
    }

    const {id} = values

    const data = {
        value: values.value,
        processor: values.processor,
        setting_value_id: values.setting_value_id,
    }

    const response = await updateSetting({id, data})

    const setting = response.data

    message.success('Setting updated')

    return setting

}

import { sortBy } from 'lodash'
import { getOrders } from '../../../../../HttpRequests/orders';
import { loadOrders } from '../State/orders';
import { setFetching } from '../State/fetching';
import { getProps } from '../../../props';
import handleException from '../../../../../Core/Helpers/handleException';

export default () => async (dispatch) => {
  
  try {
    
    const { id: projectId } = getProps().match.params;

    const params = {
      'filter[project_id]': projectId,
    }
    
    params.include = [
      'orderGroups.orderItems',
    ].join()

    dispatch(setFetching(true));

    const response = await getOrders({ params });

    let orders = response.data.data;

    orders = orders.map(order => Object.assign({}, order, {
      order_groups: sortBy(order.order_groups.map(orderGroup => Object.assign({}, orderGroup, {
        order_items: sortBy(orderGroup.order_items, 'order_number')
      })), 'order_number')
    }))

    dispatch(loadOrders(orders));

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}
// @flow

import * as React from 'react';
import styles from './index.css';
import Toolbar from './Components/Toolbar';
import Menu from './Components/Menu';
import List from './Components/List';

type Props = {
  rootDirectories: [],
  currentDirectoryPath: null|string,
  selectedItemPath: null|string,
  checkedItemPaths: [],
  filemode: 'list'|'grid',
  selectedItemType: null|string,
  listItems: [],
  onSelectRootDirectory: Function,
  onSelectItem: Function,
  onDoubleClickItem: Function,
  onToggleItemCheckbox: Function,
  onClickGrid: Function,
  onClickList: Function,
  onClickDownload: Function,
  onClickUpload: Function,
  onClickNewFolder: Function,
  onClickDelete: Function,
  onClickEdit: Function,
  onClickFileContextMenuItem: Function,
  onClickPathBreadcrumb: Function,
  onDragEnter: Function,
  onClickMove: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      rootDirectories,
      currentDirectoryPath,
      selectedItemPath,
      checkedItemPaths,
      filemode,
      selectedItemType,
      listItems,
      onSelectRootDirectory,
      onSelectItem,
      onDoubleClickItem,
      onToggleItemCheckbox,
      onClickGrid,
      onClickList,
      onClickDownload,
      onClickUpload,
      onClickNewFolder,
      onClickDelete,
      onClickEdit,
      onClickFileContextMenuItem,
      onClickPathBreadcrumb,
      onDragEnter,
      onClickMove

    } = this.props;

    return (

      <div
        className={styles.wrapper}
        onDragEnter={onDragEnter}
        onDrop={(event) => event.preventDefault()}
        onDragOver={(event) => event.preventDefault()}
      >

        <Toolbar
          currentDirectoryPath={currentDirectoryPath}
          selectedItemPath={selectedItemPath}
          onClickGrid={onClickGrid}
          onClickList={onClickList}
          onClickDownload={onClickDownload}
          onClickUpload={onClickUpload}
          onClickNewFolder={onClickNewFolder}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          filemode={filemode}
          selectedItemType={selectedItemType}
          onClickPathBreadcrumb={onClickPathBreadcrumb}
          onClickMove={onClickMove}
        />

        <div className={styles.body}>

          <Menu
            rootDirectories={rootDirectories}
            currentDirectoryPath={currentDirectoryPath}
            onSelectRootDirectory={onSelectRootDirectory}
          />

          <List
            mode={filemode}
            listItems={listItems}
            selectedItemPath={selectedItemPath}
            checkedItemPaths={checkedItemPaths}
            onSelectItem={onSelectItem}
            onDoubleClickItem={onDoubleClickItem}
            onToggleItemCheckbox={onToggleItemCheckbox}
            onClickFileContextMenuItem={onClickFileContextMenuItem}
          />

        </div>

      </div>

    )
  }
}
import { getClients } from '../../../HttpRequests/clients';
import { setFetching } from '../State/fetching';
import { loadClients } from '../State/clients'; 
import { setPagination } from '../State/pagination';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.Clients;

  try {

    const params = {};

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (sorting.columnKey) {
      params.sort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[smart_status]'] = filters.status_key;
    }

    dispatch(setFetching(true));

    const response = await getClients({ params });

    dispatch(loadClients(response.data.data));
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);
  }
}
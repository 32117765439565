import { getTeamUrl } from 'Common'
import getParams from '../../../Utils/getParams'

export default () => (dispatch, getState) => {

  const state = getState()

  const { controls } = state.screens.Planner

  const params = getParams(controls)

  let url = `planner/export?`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}

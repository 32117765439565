// @flow
import React from 'react';
import { withConfig } from '../Contexts/ConfigContext';
import HeaderButton from './HeaderButton';
import { getUser } from 'Common';

type Props = {
  headerButtons: {
    left: [],
    right: []
  },
  config: any
};

const Header = ({ headerButtons, config }: Props) => {

  const theme = config.theme.header;
  const user = getUser();
  let usertype = "";
  if (user !== undefined)
      usertype = user.type;

  const styles = {

    bar: {
      backgroundColor: theme.barColor
    },

    logoContainer: {
      width: theme.logoContainerWidth,
      backgroundColor: theme.logoContainerColor
    },

    logo: {
      width: theme.logoWidth,
      height: theme.logoHeight,
    }
  }

  return (

    <div className="_Portal_Header_bar" style={styles.bar}>

      {(!!theme.logo) && (
        <div className="_Portal_Header_logoContainer" style={styles.logoContainer}>
          <img src={theme.logo} style={styles.logo} />
        </div>
      )}

      <div className={"_Portal_Header_leftMenuItems " + usertype}>

        {headerButtons.left.map((menuItem) => (

          <HeaderButton {...menuItem} />

        ))}

      </div>

      <div className={"_Portal_Header_rightMenuItems " + usertype}>

        {headerButtons.right.map((menuItem) => (

          <HeaderButton {...menuItem} />

        ))}

      </div>

    </div>
  )
}

export default withConfig(Header);


import { createSelector } from 'reselect'

const selectFilters = state => state.screens.SubValuations.filters

export default createSelector(
  selectFilters,
  (filters) => {

    if (filters.subcontractor_id.length) return true
    if (filters.project_id.length) return true
    if (filters.date_range.length) return true
    if (filters.to_date_range.length) return true
    if (filters.user_id.length) return true

    return false

  },
)

// @flow
import * as React from 'react';
import classnames from 'classnames';
import { find, isEqual } from 'lodash';
import { AlignLeftOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { isAdmin } from 'Common';
import styles from './GroupItem.css';

import type {
  OrderItem,
  ValuationItem,
  UpdateValuationItemPercentageParams
} from '../../../types';

type Props = {
  editable: boolean,
  activeItemInputKeys: any,
  orderIndex: number,
  orderGroupIndex: number,
  orderItemIndex: number,
  orderItem: OrderItem,
  valuationItem: ValuationItem,
  serviceTypes: [],
  onClickValuationItemPercentageOutlet: () => void,
  onClickValuationItemAmountOutlet: () => void,
  onChangeValuationItemPercentage: (params: UpdateValuationItemPercentageParams) => void,
  onChangeValuationItemAmount: () => void,
  onBlurValuationItemPercentage: (params: any) => void,
  onBlurValuationItemAmount: () => void,
  onTabValuationItem: () => void,
  onClickDescription: () => void
}

export default class extends React.PureComponent<Props> {

  componentDidUpdate (prevProps) {

    const {
      activeItemInputKeys: prevActiveItemInputKeys
    } = prevProps;

    const {
      activeItemInputKeys,
      valuationItem
    } = this.props;
    
    if (!activeItemInputKeys) return;
    if (isEqual(prevActiveItemInputKeys, activeItemInputKeys)) return;
    if (activeItemInputKeys.valuationItemId !== valuationItem.id) return;

    setTimeout(() => {

      if (activeItemInputKeys.input === 'amount') {
        if (this.AmountInput) {
          this.AmountInput.focus()
          this.AmountInput.select()
        }
      }

      if (activeItemInputKeys.input === 'percentage') {
        if (this.PercentageInput) {
          this.PercentageInput.focus()
          this.PercentageInput.select()
        }
      }

    },100);

  }

  onClickDescription = () => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      orderItem,
      onClickDescription
    } = this.props;
    
    onClickDescription({
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      orderItemId: orderItem.id
    })

  }

  onClickValuationItemPercentageOutlet = async () => {

    const {
      editable,
      valuationItem,
      onClickValuationItemPercentageOutlet,
    } = this.props;

    if (!editable) return;

    await onClickValuationItemPercentageOutlet({
      valuationItemId: valuationItem.id
    })

  }

  onClickValuationItemAmountOutlet = async () => {

    const {
      editable,
      valuationItem,
      onClickValuationItemAmountOutlet,
    } = this.props;

    if (!editable) return;

    await onClickValuationItemAmountOutlet({
      valuationItemId: valuationItem.id
    })

  }

  onChangeValuationItemPercentage = async (value: string) => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onChangeValuationItemPercentage
    } = this.props;
    
    await onChangeValuationItemPercentage({
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      value
    })
  }

  onChangeValuationItemAmount = async (value: string) => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onChangeValuationItemAmount
    } = this.props;
    
    await onChangeValuationItemAmount({
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      value
    })
  }

  onBlurValuationItemPercentage = async () => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onBlurValuationItemPercentage
    } = this.props;
    
    await onBlurValuationItemPercentage({
      orderIndex,
      orderGroupIndex,
      orderItemIndex
    })
  }

  onBlurValuationItemAmount = async () => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onBlurValuationItemAmount
    } = this.props;
    
    await onBlurValuationItemAmount({
      orderIndex,
      orderGroupIndex,
      orderItemIndex
    })
  }

  render () {

    const {
      editable,
      activeItemInputKeys,
      serviceTypes,
      orderItem,
      valuationItem,
      onTabValuationItem
    } = this.props;

    const serviceType = find(serviceTypes, type => type.key === orderItem.type);

    const textOnly = !orderItem.type
    const excluded = orderItem.flag === 'Omit' || orderItem.flag === 'By Others';

    const showLineNumber = !textOnly;
    const showInputs = !textOnly && !excluded;

    let percentageInputActive = false;
    let amountInputActive = false;

    if (activeItemInputKeys && activeItemInputKeys.valuationItemId === valuationItem.id) {
      if (activeItemInputKeys.input === 'percentage') percentageInputActive = true;
      if (activeItemInputKeys.input === 'amount') amountInputActive = true;
    }

    return (
      <div className={styles.wrapper}>
        
        <div className={styles.lineNumberArea}>

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {valuationItem.line_number}
            </div>
          )}

        </div>

        <div className={classnames(styles.table, styles.flexTable)}>

          <div className={styles.cell} style={{ width: 45 }}>
              
            <Tooltip title={serviceType && serviceType.title} placement="right">
            
              <div>

                {serviceType && (
                  <div
                    className={styles.serviceTypeIcon}
                    style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                  >
                    {serviceType.key}
                  </div>
                )}

                {textOnly && (
                  <AlignLeftOutlined className={styles.textOnlyIcon} />
                )}

              </div>

            </Tooltip>

          </div>

          <div className={classnames(styles.cell, styles.flexCell)}>
            <div
              className={classnames(styles.outlet, styles.description, { [styles.editable]: editable && isAdmin() })}
              onClick={() => editable && isAdmin() ? this.onClickDescription() : null}
              role="presentation"
            >
              {orderItem.description}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.quantity : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.unit : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.discounted_rate : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.total : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.flag : '-'}
            </div>
          </div>

        </div>

        <div style={{ width: 16 }} />

        <div className={styles.table}>

          <div className={classnames(styles.cell, styles.splitCell)} style={{ width: 100 }}>

            {showInputs && (

              <React.Fragment>

                <div className={styles.splitCellUpper}>
                    
                  <div className={classnames(styles.outlet)}>
                    {`${Number(Number(valuationItem.previous_aggregate_percentage).toFixed(2))}%`}
                  </div>

                </div>

                <div className={styles.splitCellLower}>
                  
                  <div className={classnames(styles.outlet)}>
                    {`£${valuationItem.previous_aggregate_total.toFixed(2)}`}
                  </div>

                </div>
              
              </React.Fragment>

            )}

          </div>

          <div className={classnames(styles.cell, styles.splitCell)} style={{ width: 100 }}>

            {showInputs && (

              <React.Fragment>

                <div className={styles.splitCellUpper}>
                  
                  {!percentageInputActive && (
                    <div
                      className={classnames(styles.outlet, { [styles.outletClickable]: editable })}
                      onClick={() => this.onClickValuationItemPercentageOutlet()}
                      role="presentation"
                    >
                      {`${Number(Number(valuationItem.aggregate_percentage).toFixed(2))}%`}
                    </div>
                  )}

                  {percentageInputActive && (
                    <input
                      value={valuationItem.aggregate_percentage}
                      onChange={(event) => this.onChangeValuationItemPercentage(event.target.value)}
                      onBlur={(event) => this.onBlurValuationItemPercentage(event)}
                      onKeyDown={event => {
                        if (event.which === 9) onTabValuationItem()
                      }}
                      className={styles.numberInput}
                      disabled={!editable}
                      ref={ref => { this.PercentageInput = ref}}
                    />
                  )}

                </div>

                <div className={styles.splitCellLower}>
                  
                  {!amountInputActive && (
                    <div
                      className={classnames(styles.outlet, { [styles.outletClickable]: editable })}
                      onClick={() => this.onClickValuationItemAmountOutlet()}
                      role="presentation"
                    >
                      {`£${valuationItem.aggregate_total}`}
                    </div>
                  )}

                  {amountInputActive && (
                    <input
                      value={valuationItem.aggregate_total}
                      onChange={event => this.onChangeValuationItemAmount(event.target.value)}
                      onBlur={() => this.onBlurValuationItemAmount()}
                      onKeyDown={event => {
                        if (event.which === 9) onTabValuationItem()
                      }}
                      className={styles.numberInput}
                      disabled={!editable}
                      ref={ref => { this.AmountInput = ref}}
                    />
                  )}

                </div>
              
              </React.Fragment>

            )}

          </div>

          <div className={classnames(styles.cell, styles.splitCell)} style={{ width: 100 }}>

            {showInputs && (

              <React.Fragment>

                <div className={styles.splitCellUpper}>
                  
                  <div className={classnames(styles.outlet)}>
                    {(Number(valuationItem.complete_percentage) !== 0) && `${Number(Number(valuationItem.complete_percentage).toFixed(2))}%`}
                  </div>

                </div>

                <div className={styles.splitCellLower}>
                  
                  <div
                    className={classnames(styles.outlet, {
                      [styles.outletGreen]: (Number(valuationItem.complete_total) > 0),
                      [styles.outletRed]: (Number(valuationItem.complete_total) < 0)
                    })}
                  >
                    {(Number(valuationItem.complete_total) !== 0) && `${valuationItem.complete_total < 0 ? '-' : ''}£${Math.abs(valuationItem.complete_total).toFixed(2)}`}
                  </div>

                </div>
              
              </React.Fragment>

            )}

          </div>

        </div>

      </div>
    );
  }
}
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, Divider } from 'antd';
import { getTeamUrl } from 'Common';

const { Item } = Breadcrumb;

export default () => (

  <React.Fragment>

    <Breadcrumb separator="»">

      <Item>
        Home
      </Item>

      <Item>
        <NavLink to={getTeamUrl('estimates')}>
          Estimates
        </NavLink>
      </Item>

      <Item>
        Caveats
      </Item>

    </Breadcrumb>
      
    <Divider />

  </React.Fragment>

)
import { connect } from 'react-redux'
import Sidebar from './Sidebar'

const mapStateToProps = (state) => ({
  subOrder: state.screens.SubOrder.subOrder,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Sidebar)

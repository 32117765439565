import { getProps } from '../props'
import { setFetching } from '../State/fetching'
import { getInvoice } from '../../../HttpRequests/invoices'
import { loadInvoice } from '../State/invoice'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {
  
  try {
    
    const { id } = getProps().match.params

    const params = {}
    
    params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'clientContact',
      'user',
      'order',
      'valuation.activeInvoices.user',
      'valuation.activeInvoices.order',
      'valuation.activeInvoices.project.clientSite.client',
      'retention_payment.activeInvoices.user',
      'retention_payment.activeInvoices.order',
      'retention_payment.activeInvoices.project.clientSite.client',
      'invoiceItems',
      'batch.user',
      'batch.order',
      'batch.project.clientSite.client'
    ].join()

    dispatch(setFetching(true))

    const response = await getInvoice({ id, params })

    const invoice = response.data.data

    dispatch(loadInvoice(invoice))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}

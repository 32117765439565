import { Modal } from 'antd'
import deleteSetting from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: `Are you sure to delete this setting?`,
    content: 'This action cannot be undone',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteSetting()),
  })

}

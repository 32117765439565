import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { sortBy, reverse, take } from 'lodash'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover, Button, Tooltip } from 'antd';
import ServiceTypeIcons from '../../../../Components/ServiceTypeIcons';
import styles from "./Component.css";

export default class _RamsRecords_ extends React.PureComponent {

  static propTypes = {
    project: PropTypes.object.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
  }

  render () {

    const {
      project,
      onClickRecord,
      onClickNew,
    } = this.props

    const columns = [

      {
        title: 'No.',
        key: 'id',
        sorter: false,
        width: 100,
        render: (text, record) => (
          <a onClick={() => onClickRecord(record.id)} role="presentation">
            {`RAMS ${record.id}`}
          </a>
        ),
      },

      {
        title: ' ',
        key: 'notes',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },
      {
            title: 'Signed',
            key: 'signed',
            width: 100,
            sorter: false,
            render: (text, record) => (
                  <div className={styles.icon}>
                      <Popover content={record.signed.signedPeople} title="Signed">
                          {record.signed.signed}
                      </Popover>
                  </div>
            ),
      },
      {
            title: 'Awaiting signed',
            key: 'awaiting_signed',
            width: 100,
            sorter: false,
            render: (text, record) => (
              <div className={styles.icon}>
                  <Popover content={record.signed.notSignedPeople} title="Not signed">
                      {record.signed.notSigned}
                  </Popover>
              </div>
            ),
      },
      {
        title: 'Valid From',
        key: 'valid_from',
        width: 150,
        sorter: false,
        render: (text, record) => record.valid_from ? moment(record.valid_from).format('DD MMM YYYY') : '',
      },
      {
        title: 'Valid To',
        key: 'valid_to',
        width: 150,
        sorter: false,
        render: (text, record) => record.valid_to ? moment(record.valid_to).format('DD MMM YYYY') : '',
      },
      {
        title: 'Services',
        key: 'services',
        width: 150,
        sorter: false,
        render: (text, record) => (
          <ServiceTypeIcons keys={record.services} />
        ),
      },

      {
        title: 'Access Equipment',
        key: 'access_equipment',
        sorter: false,
        render: (text, record) => (
          <Tooltip title={record.access_equipment.join(', ')}>
            {`${take(record.access_equipment, 2).join(', ')} ${record.access_equipment.length > 2 ? '...' : ''}`}
          </Tooltip>
        ),
      },

      {
        title: 'Paints',
        key: 'paints',
        sorter: false,
        render: (text, record) => (
          <Tooltip title={record.paints.join(', ')}>
            {`${take(record.paints, 2).join(', ')} ${record.paints.length > 2 ? '...' : ''}`}
          </Tooltip>
        ),
      }

    ]

    const ramsRecords = reverse(sortBy(project.rams_records, ['id']))

    return (

      <div>

        <div style={{ marginBottom: 15 }}>

          <Button type="primary" onClick={onClickNew}>
            New RAMS
          </Button>

        </div>

        <Table
          dataSource={ramsRecords}
          rowKey={record => record.id}
          columns={columns}
        />

      </div>

    )
  }
}

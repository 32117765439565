import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { InputField, SelectField, DatePickerField } from 'FormFields'

const labelColSpan = 6
const wrapperColSpan = 16

export default class extends React.PureComponent {

  static propTypes = {
    subcontractors: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  render() {

    const {
      subcontractors,
      projects,
      users,
      submitting,
    } = this.props

    return (

      <React.Fragment>

        <Field
          name='reference'
          label='Reference'
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name='subcontractor_id'
          label='Subcontractor'
          component={SelectField}
          options={subcontractors.map(subcontractor => ({
            label: subcontractor.name,
            value: subcontractor.id,
          }))}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name='project_id'
          label='Project'
          component={SelectField}
          options={projects.map(project => ({
            label: `[${project.number}] ${project.client_site.client.name} - ${project.client_site.name}`,
            value: project.id,
          }))}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name='user_id'
          label='Owner'
          component={SelectField}
          options={users.map(user => ({
            label: user.full_name,
            value: user.id,
          }))}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name='date'
          label='Date'
          component={DatePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name='vat_perc'
          label='Vat %'
          component={SelectField}
          options={[
            { label: 0, value: 0 },
            { label: 20, value: 20 },
          ]}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </React.Fragment>

    )

  }

}

import { connect } from 'react-redux'
import SubValuation from './SubValuation'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSubValuationModal from './Handlers/onCloseSubValuationModal'

const mapStateToProps = (state) => ({
  subValuation: state.screens.SubValuation.subValuation,
  activeTab: state.screens.SubValuation.activeTab,
  subValuationModal: state.screens.SubValuation.subValuationModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSubValuationModal,
}

export default connect(mapStateToProps, actionCreators)(SubValuation)

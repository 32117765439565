import { connect } from 'react-redux'
import SubOrder from './SubOrder'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSubOrderModal from './Handlers/onCloseSubOrderModal'

const mapStateToProps = (state) => ({
  subOrder: state.screens.SubOrder.subOrder,
  activeTab: state.screens.SubOrder.activeTab,
  subOrderModal: state.screens.SubOrder.subOrderModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSubOrderModal,
}

export default connect(mapStateToProps, actionCreators)(SubOrder)

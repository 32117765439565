// @flow
import * as React from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Input, Menu, Button, Dropdown } from 'antd';
import { isAdmin } from 'Common';

const styles = {

  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25
  },

  heading: {
    flex: 1,
    margin: 0
  }
}

type Props = {
  searchTerm: string,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onClickNewProject: () => void,
  onClickExport: () => void
}

export default class extends React.PureComponent<Props> {

  render () {
    const {
      searchTerm,
      onClickNewProject,
      onSubmitSearch,
      onChangeSearch,
      onClickExport
    } = this.props;

    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key="excel">Export to Excel</Menu.Item>
        <Menu.Item key="csv">Export to CSV</Menu.Item>
      </Menu>
    );

    return (
      <div style={styles.stack}>

        <h1 style={styles.heading}>
          Projects
        </h1>

        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSubmitSearch}
          placeholder="Search"
          enterButton
          style={{ width: 250 }}
        />
        
        {isAdmin() && (

          <React.Fragment>
          
            <div style={{ width: 10 }} />

            <Dropdown overlay={exportMenu}>
              <Button>
                <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
              </Button>
            </Dropdown>
          
          </React.Fragment>

        )}

        <div style={{ width: 10 }} />

        <Button type="primary" onClick={onClickNewProject}>
          New Project
        </Button>

      </div>
    );
  }
}
// @flow
import * as React from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Dropdown, Menu, Button, Tag } from 'antd';
import { expiries } from '../../../../../Config/expiries';

const Spacer = () => <div style={{ width: 12 }} />

const styles = {

  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25
  },

  left: {
    flex: 1,
    display: 'flex',
  },

  right: {
    display: 'flex',
  },
}

type Props = {
  searchTerm: string,
  filters: any,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: Function,
  onClickExport: () => void,
  onClickNewSubcontractor: () => void,
  
}

export default class extends React.PureComponent<Props> {

  render () {
    const {
      searchTerm,
      filters,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onClickExport,
      onClickNewSubcontractor,
    } = this.props;

    const statusOptions = [
      { title: 'All', key: 'All' },
      { title: 'Active', key: 'Active' },
      { title: 'Deactivated', key: 'Deactivated' },
    ]

    const expiryOptions = [
      { title: 'In date', key: 'Valid' },
      { title: 'Missing', key: 'Missing', style: { color: 'white', background: '#ccc', borderColor: '#ccc' } },
      { title: 'Expiring soon', key: 'Expiring', style: { color: 'white', background: 'orange', borderColor: 'orange' } },
      { title: 'Expired', key: 'Expired', style: { color: 'white', background: 'red', borderColor: 'red' } },
    ]

    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key='xlsx'>Export to Excel</Menu.Item>
        <Menu.Item key='csv'>Export to CSV</Menu.Item>
      </Menu>
    );

    return (
      <div style={styles.stack}>

        <div style={styles.left}>

          <Input.Search
            value={searchTerm}
            onChange={onChangeSearch}
            onSearch={onSubmitSearch}
            placeholder="Search"
            enterButton
            style={{ width: 250 }}
          />

          <Spacer />

          <Radio.Group
            value={filters.status}
            onChange={(event) => onChangeFilter('status', event.target.value)}
          >

            {statusOptions.map(option => (
              <Radio.Button
                style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
                value={option.key}
                key={option.key}
              >
                {option.title}
              </Radio.Button>
            ))}

          </Radio.Group>

          <Spacer />

          <Select
            value={filters.expiry}
            onChange={value => onChangeFilter('expiry', value)}
            allowClear
            placeholder='Select expiry'
            style={{ width: 200 }}
          >

            {expiryOptions.map(option => (
              <Select.Option key={option.key} value={option.key}>
                <Tag style={option.style}>{option.title}</Tag>
              </Select.Option>
            ))}

          </Select>

          <Spacer />

          <Select
            value={filters.document}
            onChange={value => onChangeFilter('document', value)}
            allowClear
            placeholder='Select document'
            style={{ width: 200 }}
          >

            {Object.keys(expiries).map(key => (
              <Select.Option key={key} value={key}>
                {expiries[key].title}
              </Select.Option>
            ))}

          </Select>

        </div>

        <div style={styles.right}>

          <Dropdown overlay={exportMenu}>
            <Button>
              <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
            </Button>
          </Dropdown>

          <Spacer />

          <Button type="primary" onClick={onClickNewSubcontractor}>
            New Subcontractor
          </Button>

        </div>

      </div>
    );
  }
}
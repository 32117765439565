import screenProps from '../screenProps'
import { getSubcontractor } from '../../../HttpRequests/subcontractors'
import { setSubcontractor } from '../State/subcontractor'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'subOrders.project',
      'subValuations',
    ].join(','),
  }

  try {

    const response = await getSubcontractor({ id, params })

    dispatch(setSubcontractor(response.data.data))

  } catch (error) {

    handleException(error)

  }

}

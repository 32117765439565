// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Filters from './Components/Filters';
import Table from './Components/Table';
import Footer from './Components/Footer';
import EstimateModal from '../../Modals/Estimate'

type Props = {
  estimates: [],
  estimateTotals: {},
  clientContactsWithEstimates: {},
  estimateStatuses:[],
  estimateLatestActions:[],
  estimateTenderStatuses: [],
  siteVisitStatuses: [],
  users: [],
  clients: [],
  clientContacts: [],
  fetching: boolean,
  fetchingTotals: Boolean,
  searchTerm: string,
  filters: any,
  pagination: any,
  showEstimateModal: any,
  componentDidMount: () => void,
  didCreateEstimate: () => void,
  onClickNewEstimate: () => void,
  onClickExport: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onChangeEstimateField: () => void,
  onChangeSiteVisit: () => void,
  onCloseEstimateModal: () => void,
  onClickCaveats: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      estimates,
      estimateTotals,
      clientContactsWithEstimates,
      estimateStatuses,
      estimateLatestActions,
      estimateTenderStatuses,
      siteVisitStatuses,
      users,
      clients,
      clientContacts,
      fetching,
      fetchingTotals,
      searchTerm,
      filters,
      pagination,
      showEstimateModal,
      didCreateEstimate,
      onClickNewEstimate,
      onClickExport,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onChangeEstimateField,
      onChangeSiteVisit,
      onCloseEstimateModal,
      onClickCaveats,
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs onClickCaveats={onClickCaveats} />

        <Header
          searchTerm={searchTerm}
          onClickNewEstimate={onClickNewEstimate}
          onClickExport={onClickExport}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
        />

        <Filters
          filters={filters}
          users={users}
          clients={clients}
          clientContacts={clientContactsWithEstimates}
          estimateStatuses={estimateStatuses}
          estimateLatestActions={estimateLatestActions}
          onChangeFilter={onChangeFilter}
        />

        <Table
          estimates={estimates}
          estimateStatuses={estimateStatuses}
          estimateTenderStatuses={estimateTenderStatuses}
          estimateLatestActions={estimateLatestActions}
          siteVisitStatuses={siteVisitStatuses}
          clientContacts={clientContacts}
          users={users}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
          onChangeEstimateField={onChangeEstimateField}
          onChangeSiteVisit={onChangeSiteVisit}
        />

        <Footer
          estimateTotals={estimateTotals}
          fetchingTotals={fetchingTotals}
        />

        {showEstimateModal && (
          <EstimateModal
            onClose={onCloseEstimateModal}
            didCreate={didCreateEstimate}
          />
        )}

      </Screen>
    )
  }
}

import { combineReducers } from 'redux';

import { reducer as project } from './State/project';
import { reducer as fetching } from './State/fetching';
import { reducer as rootDirectories } from './State/rootDirectories';
import { reducer as currentDirectoryPath } from './State/currentDirectoryPath';
import { reducer as currentDirectoryContents } from './State/currentDirectoryContents';
import { reducer as selectedItemPath } from './State/selectedItemPath';
import { reducer as showProjectModal } from './State/showProjectModal';
import { reducer as showEstimateModal } from './State/showEstimateModal';
import { reducer as showOrderModal } from './State/showOrderModal';
import { reducer as showValuationModal } from './State/showValuationModal';
import { reducer as searchTerm } from './State/searchTerm';
import { reducer as filemode } from './State/filemode';
import { reducer as showUploadModal } from './State/showUploadModal';
import { reducer as showDirectoryModal } from './State/showDirectoryModal';
import { reducer as selectedItemType } from './State/selectedItemType';
import { reducer as directoryActionType } from './State/directoryActionType';
import { reducer as showJobSheetModal } from './State/showJobSheetModal';
import { reducer as showProjectCompleteModal } from './State/showProjectCompleteModal';
import { reducer as showMoveFilesModal } from './State/showMoveFilesModal';
import { reducer as fetchingDirectory } from './State/fetchingDirectory';
import { reducer as fetchingRootDirectories } from './State/fetchingRootDirectories';
import { reducer as checkedItemPaths } from './State/checkedItemPaths';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as showViewEmailModal } from './State/showViewEmailModal';
import { reducer as selectedEmailId } from './State/selectedEmailId';
import { reducer as selectedRetentionPaymentId } from './State/selectedRetentionPaymentId';
import { reducer as showRetentionPaymentModal } from './State/showRetentionPaymentModal';
import { reducer as ramsRecordModal } from './State/ramsRecordModal';
import { reducer as showEmailModal } from './State/showEmailModal';
import { reducer as selectedOrderIds } from './State/selectedOrderIds';
import { reducer as valuationDateModal } from './State/valuationDateModal';

import Valuations from './Components/Valuations/reducer';
import TargetCosts from './Components/TargetCosts/reducer';

export default combineReducers({
  project,
  fetching,
  rootDirectories,
  currentDirectoryPath,
  currentDirectoryContents,
  selectedItemPath,
  showProjectModal,
  showEstimateModal,
  showOrderModal,
  showValuationModal,
  searchTerm,
  filemode,
  showUploadModal,
  showDirectoryModal,
  selectedItemType,
  directoryActionType,
  showJobSheetModal,
  showProjectCompleteModal,
  showMoveFilesModal,
  fetchingDirectory,
  fetchingRootDirectories,
  checkedItemPaths,
  showClientContactModal,
  showClientSiteModal,
  showViewEmailModal,
  selectedEmailId,
  selectedRetentionPaymentId,
  showRetentionPaymentModal,
  ramsRecordModal,
  showEmailModal,
  selectedOrderIds,
  valuationDateModal,

  Valuations,
  TargetCosts,
})

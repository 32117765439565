import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction("SCREENS/RETENTION_PAYMENTS/SET_FILTERS")

const defaultState = {
  status: 'ALL',
  client_id: undefined,
}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload),
  },
  defaultState
)

export { setFilters, reducer }
// @flow

import * as React from 'react';
import { Tabs } from 'antd';
import Screen from '../../Components/Screen';
import Initializing from './Components/Initializing';
import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Items from './Components/Items';
import Caveats from './Components/Caveats';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar';
import OrderModal from '../../Modals/Order'
import EmailOrderAcknowledgement from '../../Modals/EmailOrderAcknowledgement';
import ClientSiteModal from '../../Modals/ClientSite';
import ClientContactModal from '../../Modals/ClientContact';
import ProjectEditorModal from '../../Modals/Project';
import SubOrderModal from '../../Modals/SubOrder';

type Props = {
  order: any,
  fetching: boolean,
  showOrderModal: boolean,
  showEmailModal: boolean,
  showClientSiteModal: boolean,
  showClientContactModal: boolean,
  projectEditorModal: Object,
  showEmailModal: boolean,
  subOrderModal: Object,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  componentDidUpdate: (props: any) => void,
  
  onChangeTab: Function,
  didUpdateOrder: Function,
  didSendEmail: Function,
  didUpdateClientSite: Function,
  didUpdateClientContact: Function,
  onCloseOrderModal: Function,
  onCloseEmailModal: Function,
  onCloseClientContactModal: Function,
  onCloseClientSiteModal: Function,
  onCloseProjectEditorModal: Function,
  onCloseSubOrderModal: Function,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentDidUpdate = () => this.props.componentDidUpdate(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      order,
      fetching,
      showOrderModal,
      showEmailModal,
      showClientSiteModal,
      showClientContactModal,
      projectEditorModal,
      subOrderModal,
      onChangeTab,
      didUpdateOrder,
      didSendEmail,
      didUpdateClientSite,
      didUpdateClientContact,
      onCloseOrderModal,
      onCloseEmailModal,
      onCloseClientContactModal,
      onCloseClientSiteModal,
      onCloseProjectEditorModal,
      onCloseSubOrderModal,
    } = this.props;

    if (!order) return <Initializing />

    return (

      <Screen style={{ marginRight: 280, paddingRight: 30 }}>
          
        <Breadcrumbs />

        <Header />

        <Tabs
          defaultActiveKey="ITEMS"
          animated={false}
          onChange={onChangeTab}
        >

          <Tabs.TabPane tab="ITEMS" key="ITEMS">
            <Items />
          </Tabs.TabPane>

          <Tabs.TabPane tab="CAVEATS" key="CAVEATS">
            <Caveats />
          </Tabs.TabPane>
        
        </Tabs>

        <Footer />

        <Sidebar />

        {showOrderModal && (
          <OrderModal
            order={order}
            onClose={onCloseOrderModal}
            didUpdate={didUpdateOrder}
          />
        )}

        {showEmailModal && (
          <EmailOrderAcknowledgement
            order={order}
            onClose={onCloseEmailModal}
            didSend={didSendEmail}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didUpdate={didUpdateClientSite}
            clientSite={order.project.client_site}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didUpdate={didUpdateClientContact}
            clientContact={order.client_contact}
          />
        )}

        {projectEditorModal.show && (
          <ProjectEditorModal
            {...projectEditorModal}
            onClose={onCloseProjectEditorModal}
          />
        )}

        {subOrderModal.show && (
          <SubOrderModal
            {...subOrderModal}
            onClose={onCloseSubOrderModal}
          />
        )}

      </Screen>

    )
  }
}
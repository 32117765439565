import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table as TableProvider } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import { getTeamUrl } from 'Common'

const Table = props => {

  const {
    subOrders,
    fetching,
    onChangeTable,
  } = props

  return (
    
    <TableProvider
      dataSource={subOrders}
      rowKey='id'
      loading={fetching}
      onChange={onChangeTable}
    >

      <TableProvider.Column
        title='Reference'
        key='reference'
        sorter
        render={(text, record) => (
          <NavLink to={getTeamUrl(`sub_orders/${record.id}`)}>
            <div style={{ fontSize: 15 }}>{record.reference}</div>
          </NavLink>
        )}
      />

      <TableProvider.Column
        title='Project'
        key='project_id'
        sorter
        render={(text, record) => (
          <React.Fragment>
            <div>
              <span style={{ fontSize: 15, fontWeight: 600 }}>{record.project.number}</span>
              <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${record.project.client_site.client.name}`}</span>
            </div>
            <div style={{ color: '#999999' }}>{record.project.client_site.name}</div>
          </React.Fragment>
        )}
      />

      <TableProvider.Column
        title='Date'
        key='date'
        sorter
        render={(text, record) => moment(record.date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='Owner'
        key='user.name'
        sorter
        render={(text, record) => record.user.full_name}
      />

      <TableProvider.Column
        title='Total'
        key='total'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => `${record.total < 0 ? '-' : ''}£${numeral(Math.abs(record.total)).format('0,0.00')}`}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  subOrders: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
}

export default Table

import { message } from 'antd'
import { requireUserPasswordReset } from '../../../HttpRequests/users'
import { getProps } from '../props'

export default () => async () => {

  const { id } = getProps()

  const response = await requireUserPasswordReset({ id })

  const user = response.data

  message.success('Reset required')

  return user

}

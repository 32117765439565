import { message } from 'antd'
import { updateUser } from '../../../HttpRequests/users'
import { getProps } from '../props'

export default (values) => async () => {

  const { id } = getProps()

  const data = {
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    telephone: values.telephone,
    type: values.type,
    subcontractor_id: values.type === 'Subcontractor' ? values.subcontractor_id : null,
  }

  const response = await updateUser({ id, data })

  const user = response.data

  message.success('User updated')

  return user

}

import { initialize } from 'redux-form'
import moment from 'moment'
import { findTimesheet } from '../../../HttpRequests/timesheets'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

const formatManualDeductionValue = (value) => {
  if (value === null) {
    return 'auto'
  }
  if (value === 0) {
    return 'none'
  }
  if (value === 0.5) {
    return '30m'
  }
  if (value === 1) {
    return '1h'
  }
  return null
}

export default () => async (dispatch) => {

  const { id } = getProps()

  const params = {
    include: 'timesheetPostings.project.clientSite.client',
  }

  if (id) {

    const response = await findTimesheet({ id, params })

    const timesheet = response.data

    dispatch(initialize(FORM_NAME, {
      ...timesheet,
      manual_deduction: formatManualDeductionValue(timesheet.manual_deduction),
      day_rate: !timesheet.hourly_rate,
    }))

  } else {

    dispatch(initialize(FORM_NAME, {
      date: moment().format('YYYY-MM-DD'),
    }))

  }

}

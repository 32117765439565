export default (values) => {

  const errors = {};

  if (!values.client_id) errors.client_id = true;
  if (!values.client_site_id) errors.client_site_id = true;

  // Validate fields in update context

  return errors;
  
}
import { combineReducers } from 'redux'

import { reducer as settings } from './State/settings'
import { reducer as fetching } from './State/fetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as settingModal } from './State/settingModal'

export default combineReducers({
  settings,
  fetching,
  searchTerm,
  filters,
  sorting,
  pagination,
  settingModal,
})

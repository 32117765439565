import React from 'react'
import PropTypes from 'prop-types'
import { Select, Radio, DatePicker } from 'antd'
import styles from './Component.css'

const { RangePicker } = DatePicker;

export default class _Filters_ extends React.PureComponent {

  static propType = {
    filters: PropTypes.object.isRequired,
    clients: PropTypes.array.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render () {

    const {
      filters,
      clients,
      onChangeFilter,
    } = this.props

    const defaultSelectProps = {
      style: { width: 230 },
      showArrow: true,
      showSearch: true,
      allowClear: true,
      notFoundContent: null,
      defaultActiveFirstOption: true,
      optionFilterProp: 'children',
    }

    const typeOptions = [
      { value: 'ALL', label: 'All' },
      { value: 'INVOICES', label: 'Invoices' },
      { value: 'APPLICATIONS', label: 'Applications' },
    ]

    return (

      <div class="_filter_bar">

        <Radio.Group
          value={filters.type}
          onChange={(event) => onChangeFilter('type', event.target.value)}
        >
          {typeOptions.map(option => (
            <Radio.Button
              className={styles.radio}
              value={option.value}
              key={option.value}
            >
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        <div style={{ width: 10 }} />

        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Client"
          value={filters.client_id}
          onChange={(value) => onChangeFilter('client_id', value)}
        >

          {clients.map(client => (
            <Select.Option key={client.id}>
              {`${client.name}`}
            </Select.Option>
          ))}

        </Select>

        <div style={{ width: 10 }} />

        <RangePicker
          value={filters.date_between}
          onChange={(value) => onChangeFilter('date_between', value)}
          format="DD/MM/YYYY"
        />

      </div>

    )
  }
}

// @flow

import * as React from 'react';

import {
  AppstoreOutlined,
  BarsOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DragOutlined,
  EditOutlined,
  FolderAddOutlined,
  FolderFilled,
  UploadOutlined,
} from '@ant-design/icons';

import { Row, Col, Breadcrumb, Tooltip } from 'antd';
import { capitalize, drop } from 'lodash';
import styles from './Toolbar.css';

type Props = {
  currentDirectoryPath: null|string,
  selectedItemPath: null|string,
  filemode: string,
  selectedItemType: null|string,
  onClickPathBreadcrumb: (key: number) => void,
  onClickGrid: Function,
  onClickList: Function,
  onClickDelete: Function,
  onClickUpload: Function,
  onClickDownload: Function,
  onClickNewFolder: Function,
  onClickEdit: Function,
  onClickMove: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      currentDirectoryPath,
      selectedItemPath,
      filemode,
      selectedItemType,
      onClickPathBreadcrumb,
      onClickGrid,
      onClickList,
      onClickDelete,
      onClickUpload,
      onClickDownload,
      onClickNewFolder,
      onClickEdit,
      onClickMove

    } = this.props;

    const hasSelectedDirectoryPath = !currentDirectoryPath ? { color: '#dddddd', pointerEvents: 'none' } : [];
    const hasSelectedItemPath = !selectedItemPath ? { color: '#dddddd', pointerEvents: 'none' } : [];

    const structuredDirectoryPath = currentDirectoryPath ? drop(currentDirectoryPath.split('/'), 2) : [];
    
    return (
      <Row className={styles.wrapper}>

        <Col span={16}>

          <div className={styles.path}>

            <FolderFilled className={styles.pathIcon} />

            <Breadcrumb className={styles.breadcrumbs} separator="/">

              {structuredDirectoryPath.map((element, index) => (

                <Breadcrumb.Item key={element}>

                  {structuredDirectoryPath.length > (index + 1) ? (

                    <span className={styles.link} key={element} onClick={() => onClickPathBreadcrumb(index + 2)} role="presentation">

                      {element}

                    </span>

                  ) : element}

                </Breadcrumb.Item>

              ))}

            </Breadcrumb>

          </div>

        </Col>

        <Col span={8} style={{ textAlign: 'right' }}>

          <Tooltip placement="top" title="Move selected item">
            <DragOutlined
              className={styles.actionIcon}
              style={{ display: 'none', /* hasSelectedItemPath */ }}
              onClick={onClickMove} />
          </Tooltip>

          <div style={{ width: 10, display: 'inline-block' }} />

          {filemode === 'list' ? (
            <Tooltip placement="top" title="Grid View">

              <AppstoreOutlined className={styles.actionIcon} onClick={onClickGrid} />

            </Tooltip>
          ) : (
            <Tooltip placement="top" title="List View">

              <BarsOutlined className={styles.actionIcon} onClick={onClickList} />

            </Tooltip>
          )}
          <div style={{ width: 10, display: 'inline-block' }} />

          <Tooltip placement="top" title={`Delete ${capitalize(selectedItemType)}`}>
            <DeleteOutlined
              className={styles.actionIcon}
              style={hasSelectedItemPath}
              onClick={onClickDelete} />
          </Tooltip>

          <div style={{ width: 10, display: 'inline-block' }} />

          <Tooltip placement="top" title={`Rename ${capitalize(selectedItemType)}`}>
            <EditOutlined
              className={styles.actionIcon}
              style={hasSelectedItemPath}
              onClick={onClickEdit} />
          </Tooltip>

          <div style={{ width: 10, display: 'inline-block' }} />

          <Tooltip placement="top" title="Download Files">
            <DownloadOutlined
              className={styles.actionIcon}
              style={hasSelectedItemPath}
              onClick={onClickDownload} />
          </Tooltip>

          <div style={{ width: 10, display: 'inline-block' }} />

          <Tooltip placement="top" title="Upload Files">
            <UploadOutlined
              className={styles.actionIcon}
              style={hasSelectedDirectoryPath}
              onClick={onClickUpload} />
          </Tooltip>

          <div style={{ width: 10, display: 'inline-block' }} />

          <Tooltip placement="top" title="New Directory">
            <FolderAddOutlined
              className={styles.actionIcon}
              style={hasSelectedDirectoryPath}
              onClick={onClickNewFolder} />
          </Tooltip>

        </Col>

      </Row>
    );
  }
}
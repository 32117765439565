import {message} from 'antd'
import {createSetting} from '../../../HttpRequests/settings'

export default (values) => async (dispatch, getState) => {


    const {id} = values

    if (values.processor == "FileUpload")
    {
        values.value = getState().modals.Setting.logo;
        values.value = values.value.replace("/settings/files/", "");
    }

    const data = {
        value: values.value,
        setting_id: values.id,
    }

    const response = await createSetting({data})

    const setting = response.data

    message.success('Setting created')

    return setting

}

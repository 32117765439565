/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import moment from 'moment'
import { FORM_NAME } from '../Form';
import { getProps } from '../props';


export default () => async (dispatch) => {

  const { order } = getProps();

  const { project, user } = order;
  const contact = order.client_contact;

  // TODO - cc should have a setting cc
  const values = {
    to: [],
    cc: [],
    bcc: [],
    subject: `Order Acknowledgement - ${ project.client_site.name } project`,
    body: '',
    id: order.id,
  };

  if (contact.email) values.to.push(contact.email);

  values.body += `Dear ${contact.first_name}\r\n\r\n`
  values.body += `We write in connection with your order ref ${order.reference ? `[${order.reference}]` : `[Unknown]`} dated ${moment(order.date).format('DD-MM-YYYY')} which we would hereby wish to acknowledge with thanks.\r\n\r\n`
  values.body += `Please be advised that we shall now proceed with your order in accordance with our discussions to date and our estimate and enclosures therewith.\r\n\r\n`
  values.body += `Thank you for the documents we have already received, please can you forward us the latest revisions of the following documents relevant to our works:\r\n\r\n`
  values.body += `- Programme\r\n`;
  values.body += `- Finishes Schedule\r\n`;
  values.body += `- Drawings\r\n\r\n`;

  // TODO - this should be set inthe db
  //values.body += `May we take this opportunity to thank you again for your order and please do not hesitate to contact us at projects@vividfinishes.co.uk should you require any additional information.\r\n\r\n`;
  values.body += `Kind Regards\r\n\r\n`;
  values.body += `${user.first_name } ${user.last_name}`;


  dispatch(initialize(FORM_NAME, values));

}

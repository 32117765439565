import { connect } from 'react-redux'
import Header from './Header'

import onClickEdit from './Handlers/onClickEdit'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  subOrder: state.screens.SubOrder.subOrder,
})

const actionCreators = {
  onClickEdit,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(Header)

// @flow
import { getTeamUrl } from 'Common';

export default (type) => async (dispatch: Function, getState: Function) => {

  const { project, selectedOrderIds } = getState().screens.ProjectView;

  let url = getTeamUrl(`projects/${project.id}/order_summary_${type}`, 'exports')

  if (selectedOrderIds.length) {
    url = `${url}?order_ids=${selectedOrderIds.join(',')}`
  }

  window.open(url);

}

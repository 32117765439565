// @flow
import * as React from 'react';
import { Modal, Button } from 'antd';
import Form from './Form';

type Props = {
  submitting: boolean,
  clientRiskLevels: [],
  client: {},
  componentDidMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount(this.props);

  render () {

    const {
      submitting,
      clientRiskLevels,
      client,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
    } = this.props;

    const footer = []

    if (client) footer.push(
      <Button key='delete' type='danger' onClick={onClickDelete} style={{ float: 'left' }}>
        Delete
      </Button>,
    )

    footer.push(
      <Button key='cancel' onClick={() => onClickCancel()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        {client ? 'Update' : 'Create'}
      </Button>,
    )

    return (
        
      <Modal
        title={`${client ? 'Update Client': 'New Client'}`}
        visible
        footer={footer}
        onCancel={onClickCancel}
        width={900}
      >
        
        <Form
          clientRiskLevels={clientRiskLevels}
        />

      </Modal>
    )
  }
}
import screenProps from '../../../screenProps'
import { getSubOrders } from '../../../../../HttpRequests/subOrders'
import { setSubOrders } from '../State/subOrders'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const state = getState()

  const { filters } = state.screens.SubProject

  const { search, sorting } = state.screens.SubProject.SubOrders

  const params = {
    search,
    'filter[subcontractor_id]': id,
    'filter[project_status_key]': filters.projectStatusKey || undefined,
    'filter[project_id]': filters.projectId || undefined,
    sort: sorting.order ? `${sorting.order === 'descend' ? '-' : ''}${sorting.columnKey}` : '-date',
    include: [
      'project.clientSite.client',
      'user',
    ].join(','),
  }

  dispatch(startFetching())

  try {

    const response = await getSubOrders({ params })

    dispatch(setSubOrders(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}

import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Section = props => (
  <div className={styles.section}>
    {props.children}
  </div>
)

const Outlet = props => (
  <div className={styles.outlet}>
    {props.children}
  </div>
)

const Label = props => (
  <div className={styles.label}>
    {props.children}
  </div>
)

const Value = props => (
  <div className={styles.value}>
    {props.children}
  </div>
)

const Sidebar = props => {

  const {
    subOrder,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <Section>

        <Outlet>
          <Label>Subcontractor</Label>
          <Value>
            <NavLink to={getTeamUrl(`subcontractors/${subOrder.subcontractor.id}/view`)}>
              {subOrder.subcontractor.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Client</Label>
          <Value>
            <NavLink to={getTeamUrl(`clients/${subOrder.project.client_site.client.id}/view`)}>
              {subOrder.project.client_site.client.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Project</Label>
          <Value>
            <NavLink to={getTeamUrl(`projects/${subOrder.project.id}/view`)}>
              {subOrder.project.number}
              {subOrder.project.title && ` » ${subOrder.project.title}`}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Site</Label>
          <Value>
            {[
              subOrder.project.client_site.name,
              subOrder.project.client_site.address_line_1,
              subOrder.project.client_site.address_line_2,
              subOrder.project.client_site.address_line_3,
              subOrder.project.client_site.town_or_city,
              subOrder.project.client_site.county,
              subOrder.project.client_site.postcode,
            ].filter(line => line).join(', ')}
          </Value>
        </Outlet>

        <Outlet>
          <Label>Owner</Label>
          <Value>{subOrder.user.full_name}</Value>
        </Outlet>

        <Outlet>
          <Label>Date</Label>
          <Value>{moment(subOrder.date).format('DD MMM YYYY')}</Value>
        </Outlet>

      </Section>

    </div>
    
  )

}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Outlet.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  subOrder: PropTypes.object.isRequired,
}

export default Sidebar

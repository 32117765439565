import fetchValuations from '../Services/fetchValuations';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchClients from '../../../Core/Services/fetchClients';
import fetchValuationStatuses from '../../../Core/Services/fetchValuationStatuses';

export default () => async (dispatch) => {

  dispatch(fetchValuations());
  dispatch(fetchUsers());
  dispatch(fetchProjects());
  dispatch(fetchClients());
  dispatch(fetchValuationStatuses());
  
}
export default (values) => {

  const errors = {}

  if (!values.first_name) errors.first_name = 'First name is required'

  if (!values.last_name) errors.last_name = 'Last name is required'

  if (!values.type) errors.type = 'Type is required'

  if (values.type === 'Subcontractor') {

    if (!values.subcontractor_id) errors.subcontractor_id = 'Subcontractor is required'

  }

  if (!values.email) errors.email = 'Email is required'

  if (!values.id) {

    if (!values.password) errors.password = 'Password is required'

    if (!values.password_confirm) errors.password_confirm = 'Password confirmation is required'

    if (values.password_confirm !== values.password) errors.password_confirm = 'Password does not match'

  }

  return errors
  
}

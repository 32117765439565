import { message } from 'antd'
import { clockTimesheetSubcontractorIn } from '../../../HttpRequests/timesheets'
import fetch from './fetch'
import { resetForm } from '../State/form'
import fetchProjects from '../../../Core/Services/fetchProjects'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { form } = getState().screens.TimesheetPosting

  const data = {
    project_id: form.projectId,
  }

  try {
    
    await clockTimesheetSubcontractorIn({ data })
    
    dispatch(fetch())

    dispatch(resetForm())

    dispatch(fetchProjects())

    message.success('Clocked In')

  } catch (error) {

    handleException(error)

  }

}

import React from 'react'
import { Select, Radio } from 'antd'

import ChartPanel from './ChartPanel'
import Chart from './Chart'

type Props = {
  config: Object,
  type: String,
  dateRange: String,
  onChangeFilter: Function,
}

export default class extends React.PureComponent<Props> {

  renderTools = () => (

    <div class="chart-tools " style={{ display: 'flex' }}>

      <Select
        value={this.props.type}
        onChange={value => this.props.onChangeFilter('type', value)}
        style={{ width: 150 }}
      >
        <Select.OptGroup label='View By'>
          <Select.Option key='quantity'>Quantity</Select.Option>
          <Select.Option key='subtotal'>Subtotal</Select.Option>
        </Select.OptGroup>
      </Select>


      <div style={{ width: 10 }} />

      <Select
        value={this.props.dateRange}
        onChange={value => this.props.onChangeFilter('dateRange', value)}
        style={{ width: 150 }}
      >
        <Select.OptGroup label='Date Range'>
          <Select.Option key='last_three_months'>Last 3 Months</Select.Option>
          <Select.Option key='year_to_date'>Year to Date</Select.Option>
        </Select.OptGroup>
      </Select>

    </div>

  )

  renderChart = () => [
    <Chart key={0} config={this.props.config[0]} />,
    <Chart key={1} config={this.props.config[1]} />,
  ]

  render = () => (

    <ChartPanel
      title='Estimates By Client'
      renderTools={() => this.renderTools()}
      renderChart={() => this.renderChart()}
    />

  )

}

import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onClickDelete from './Handlers/onClickDelete';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  clientRiskLevels: state.core.clientRiskLevels,
});

const actionCreators = {
  componentDidMount,
  onClickCancel,
  onClickSubmit,
  onClickDelete,
};

export default connect(mapStateToProps, actionCreators)(Component);
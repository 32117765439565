import { createAction, handleActions } from 'redux-actions';

const loadEstimateTotals = createAction("SCREENS/ESTIMATES/LOAD_ESTIMATE_TOTALS");

const defaultState = {
  total: 0,
  services: [],
  statuses: [],
}

const reducer = handleActions(
  {
    [loadEstimateTotals]: (state, action) => action.payload
  },
  defaultState
);

export { loadEstimateTotals, reducer };
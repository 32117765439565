
import { connect } from 'react-redux'
import { formValueSelector, isSubmitting } from 'redux-form'
import Setting from './Setting'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onUpload from './Handlers/onUpload'

const mapStateToProps = (state) => ({

    type: formValueSelector(FORM_NAME)(state, 'type'),
    value: formValueSelector(FORM_NAME)(state, 'value'),
    processor: formValueSelector(FORM_NAME)(state, 'processor'),
    processor_json: formValueSelector(FORM_NAME)(state, 'processor_json'),
    title: formValueSelector(FORM_NAME)(state, 'title'),
    validation_json: formValueSelector(FORM_NAME)(state, 'validation_json'),
    submitting: isSubmitting(FORM_NAME)(state),
    logo: state.modals.Setting.logo,

})

const actionCreators = {
    componentWillMount,
    onClickSubmit,
    onClickCancel,
    onClickDelete,
    onUpload,
}


export default connect(mapStateToProps, actionCreators)(Setting)

import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const type = options.key

  const { searchTerm, sorting, filters } = getState().screens.RamsRecords

  const params = {}

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey
  }
  
  if (searchTerm) {
    params.search = searchTerm
  }
    
  if (filters.status !== 'ALL') {
    params['filter[status]'] = filters.status
  }
  
  if (filters.client_id) {
    params['filter[client_id]'] = filters.client_id
  }

  let url = `rams_records/export?type=${type}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}
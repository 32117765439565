// @flow

import { sortBy, assign } from 'lodash';
import { getOrder } from '../../../HttpRequests/orders';
import { loadOrder } from '../State/order';
import { setFetching } from '../State/fetching';
import { getProps } from '../props';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'orderGroups',
      'orderGroups.orderItems',
      'orderCaveats',
      'clientContact',
      'user',
      'estimate',
    ].join();

    params.append = [
      'action_statuses',
      'updatable_fields'
    ].join();

    dispatch(setFetching(true));

    const response = await getOrder({ id, params });

    const order = response.data.data;

    order.order_groups = sortBy(
      order.order_groups.map(orderGroup => assign(
        {},
        orderGroup,
        { order_items: sortBy(orderGroup.order_items, 'order_number') }
      )
    ), 'order_number');

    order.order_optionals = sortBy(order.order_optionals, 'order_number');

    order.order_caveats = sortBy(order.order_caveats, 'order_number');

    dispatch(loadOrder(order));

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}
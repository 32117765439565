import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openRamsRecordModal = createAction('SCREENS/RAMS_RECORDS/OPEN_RAMS_RECORD_MODAL')
const closeRamsRecordModal = createAction('SCREENS/RAMS_RECORDS/CLOSE_RAMS_RECORD_MODAL')

const defaultState = {
  show: false,
  ramsRecordId: null,
}

const reducer = handleActions(
  {
    [openRamsRecordModal]: (state, action) => Object.assign({}, state, {
      show: true,
      ramsRecordId: get(action, 'payload.ramsRecordId', null),
    }),
    [closeRamsRecordModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openRamsRecordModal, closeRamsRecordModal, reducer }
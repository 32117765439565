import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

export default class extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired
  }

  render () {

    const {
      filters,
      onChangeFilter
    } = this.props;

    const statusOptions = [
      { title: 'All', key: 'all'},
      { title: 'Live Projects', key: 'live-projects'},
      { title: 'Live Estimates', key: 'live-estimates'},
      { title: 'Inactive', key: 'inactive'}
    ]

    return (

      <div class="_filter_bar" >

        <Radio.Group
          value={filters.status_key}
          onChange={(event) => onChangeFilter('status_key', event.target.value)}
        >

          {statusOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

        <div style={{ width: 10 }} />

      </div>

    )
  }
}

// @flow

import React, { useState } from 'react'
import { getUser } from 'Common'
import ResetPasswordModal from 'Modals/ResetPassword'
import {Button, Modal} from "antd";
import Form from "../../Modals/ResetPassword/Form";
//import parse from 'html-react-parser';

type Props = {
  children: React.Node,
  style: any,
  innerStyle: any,
  maxWidth: any
}

const Screen = ({ children, style, innerStyle, maxWidth }: Props) => {

    const [resetPassword, setResetPassword] = useState(!!getUser().reset_password)
    const [setPayment] = useState(!!getUser().force_payment)
    const setPaymentHTML = window.state.user.force_payment_html
    const setPaymentMsg  = window.state.user.force_payment_msg

  return (


    <div className="portal-screen" style={style}>

      <div className="portal-screen-inner" style={Object.assign({} , maxWidth && { maxWidth }, innerStyle)}>

        {children}

      </div>

      {resetPassword && (
        <ResetPasswordModal
          onClose={() => setResetPassword(false)}
        />
      )}

      {setPayment && (
          <Modal
              title='Add payment details'
	      footer={null}
              visible
          >
	  	<p dangerouslySetInnerHTML={{ __html: setPaymentMsg }} />
	  	<div dangerouslySetInnerHTML={{ __html: setPaymentHTML }} />
	  </Modal>
      )}


    </div>

  )

}



export default Screen;

import { omit } from 'lodash'
import moment from 'moment'
import handleException from '../../../Core/Helpers/handleException'
import { getInvoices, getInvoiceTotals } from '../../../HttpRequests/invoices'
import { setFetching } from '../State/fetching'
import { loadInvoices } from '../State/invoices'
import { loadTotals } from '../State/totals'
import { setPagination } from '../State/pagination'

export default () => async (dispatch, getState) => {

  try {

    const { pagination, sorting, filters, searchTerm } = getState().screens.Invoices

    const params = {}

    params.include = [
      'client',
      'project',
      'project.clientSite',
      'valuation',
      'retentionPayment',
    ].join()
    
    params['page[size]'] = pagination.pageSize
    params['page[number]'] = pagination.current

    if (searchTerm) {
      params.search = searchTerm
    }

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey
    }
    
    if (filters.type === 'INVOICES') {
      params['filter[type]'] = 'INVOICE_SINGLE,INVOICE_SPLIT'
    } else if (filters.type === 'APPLICATIONS') {
      params['filter[type]'] = 'APPLICATION'
    }
    
    if (filters.client_id) {
      params['filter[client_id]'] = filters.client_id
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join()
    }

    dispatch(setFetching(true))

    const response = await getInvoices({ params })

    dispatch(loadInvoices(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page,
    }))

    dispatch(setFetching(false))

    const response2 = await getInvoiceTotals({ params: omit(params, ['include', 'page[size]', 'page[number]', 'csort']) })

    dispatch(loadTotals(response2.data.data))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}
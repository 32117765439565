import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table as TableProvider, Progress } from 'antd'
import { CheckCircleFilled, MinusCircleOutlined } from '@ant-design/icons'
import numeral from 'numeral'
import moment from 'moment'
import { getTeamUrl } from 'Common'

const Table = props => {

  const {
    subValuations,
    fetching,
    pagination,
    onChangeTable,
  } = props

  return (
    
    <TableProvider
      dataSource={subValuations}
      rowKey='id'
      loading={fetching}
      onChange={onChangeTable}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: pagination.pageNumber,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
    >

      <TableProvider.Column
        title='Reference'
        key='project_id'
        sorter
        render={(text, record) => (
          <NavLink to={getTeamUrl(`sub_valuations/${record.id}`)}>
            <div style={{ fontSize: 15 }}>{record.reference}</div>
          </NavLink>
        )}
      />

      <TableProvider.Column
        title='Subcontractor'
        key='subcontractor.name'
        sorter
        render={(text, record) => record.subcontractor.name}
      />

      <TableProvider.Column
        title='Project'
        key='project_id'
        sorter
        render={(text, record) => (
          <React.Fragment>
            <div>
              <span style={{ fontSize: 15, fontWeight: 600 }}>{record.project.number}</span>
              <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${record.project.client_site.client.name}`}</span>
            </div>
            <div style={{ color: '#999999' }}>{record.project.client_site.name}</div>
          </React.Fragment>
        )}
      />

      <TableProvider.Column
        title='Date'
        key='date'
        sorter
        render={(text, record) => moment(record.date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='To Date'
        key='to_date'
        sorter
        render={(text, record) => moment(record.to_date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='Owner'
        key='user.name'
        sorter
        render={(text, record) => record.user.full_name}
      />

      <TableProvider.Column
        title='Status'
        key='status'
        render={(text, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {record.status === 'Pending' && <MinusCircleOutlined style={{ fontSize: 15, color: '#999999' }} />}
            {record.status === 'Approved' && <CheckCircleFilled style={{ fontSize: 15, color: 'orange' }} />}
            {record.status === 'Paid' && <CheckCircleFilled style={{ fontSize: 15, color: 'limegreen' }} />}
            <span style={{ marginLeft: 5 }}>{record.status}</span>
          </div>
        )}
      />

      <TableProvider.Column
        title='Complete'
        key='complete_total'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => (
          <React.Fragment>
            <div style={{ fontSize: 15, fontWeight: 500 }}>
              {`${record.complete_total < 0 ? '-' : ''}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
            </div>
            <Progress percent={numeral(record.aggregate_perc).format('0,0')} />
          </React.Fragment>
        )}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  subValuations: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onChangeTable: PropTypes.func.isRequired,
}

export default Table

// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Tooltip } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { getTeamUrl } from 'Common';
import TableCellSelect from '../../../Components/TableCellSelect';
import ServiceTypeIcons from '../../../Components/ServiceTypeIcons';

import NotesPopover from './NotesPopover'
import ActivityPopover from './ActivityPopover'
import SiteVisitPopover from './SiteVisitPopover'
import FollowUpPopover from './FollowUpPopover'

type Props = {
  estimates: [],
  estimateStatuses:[],
  estimateTenderStatuses: [],
  estimateLatestActions: [],
  siteVisitStatuses: [],
  clientContacts: [],
  users: [],
  fetching: boolean,
  pagination: any,
  onChangeTable: () => void,
  onChangeEstimateField: (index: any, field: any, value: any) => void,
  onChangeSiteVisit: () => void,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimates,
      estimateStatuses,
      estimateTenderStatuses,
      estimateLatestActions,
      siteVisitStatuses,
      clientContacts,
      users,
      fetching,
      pagination,
      onChangeTable,
      onChangeEstimateField,
      onChangeSiteVisit,
    } = this.props;

    const columns = [

      { 
        title: 'No', 
        key: 'reference',
        width: 202,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <NavLink key={record.id} to={getTeamUrl(`estimates/${record.id}/view`)}>
              {record.full_reference}
            </NavLink>
            <Tooltip title={record.title} placement="bottomLeft">
              <div className="ellipsis" style={{ width: 170, color: '#999' }}>
                {record.title}
              </div>
            </Tooltip>
          </div>
        ),
      },
      
      {
        title: 'Client / Site / Project',
        key: 'client.name',
        width: 252,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.project.client_site.client.name}
              </strong>
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.project.client_site.name}
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.project.number}
              </strong>
            </div>
          </div>
        ),
      },

      { 
        title: ' ', 
        key: 'notes',
        width: 25,
        sorter: false,
        fixed: 'left',
        render: (text, record) => (
          <NotesPopover
            notes={record.notes} 
            onChange={(value) => onChangeEstimateField(record.id, 'notes', value)}
          />
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },

      { 
        title: ' ', 
        key: 'activity',
        width: 25,
        sorter: false,
        fixed: 'left',
        render: (text, record) => (
          <ActivityPopover estimate={record} />
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },
      
      {
        title: 'Date',
        key: 'date',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'Due Date',
        key: 'due_date',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.due_date ? moment(record.due_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'Total',
        key: 'total',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.total).format('0,0.00')}`}
          </div>
        ),
      },
      
      {
        title: 'Project Value',
        key: 'project_value',
        width: 120,
        sorter: false,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.project.value_total).format('0,0.00')}`}
          </div>
        ),
      },
      
      {
        title: 'Status',
        key: 'status_key',
        width: 160,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: null, label: 'No Status' },
            ...estimateStatuses.map(item => ({ value: item.key, label: item.title }))
          ];

          const current = find(estimateStatuses, item => String(item.key) === String(record.status_key));

          const color = current && current.color;
          
          return (
            <TableCellSelect
              type="outlined"
              color={color}
              options={options}
              value={record.status_key}
              onChange={(value) => onChangeEstimateField(record.id, 'status_key', value)}
              disabled={!record.updatable_fields.includes('status_key')}
            />
          )
        }
      },
      
      {
        title: 'Tender',
        key: 'tender_status_key',
        width: 200,
        sorter: true,
        render: (text, record) => {

          const options = [
            ...estimateTenderStatuses.map(item => ({ value: item.key, label: item.title }))
          ];
          
          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.tender_status_key}
              onChange={(value) => onChangeEstimateField(record.id, 'tender_status_key', value)}
              disabled={!record.updatable_fields.includes('tender_status_key')}
            />
          )
        }
      },
      
      {
        title: 'Sales Contact',
        key: 'assigned_to_user_id',
        width: 180,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: null, label: 'Unassigned' },
            ...users.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];
          
          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.assigned_to_user_id}
              onChange={(value) => onChangeEstimateField(record.id, 'assigned_to_user_id', value)}
              disabled={!record.updatable_fields.includes('assigned_to_user_id')}
            />
          )
        }
      },
      
      { 
        title: 'Site Visit', 
        key: 'site_visit',
        width: 220,
        render: (text, record) => (
          <SiteVisitPopover
            estimate={record}
            siteVisitStatuses={siteVisitStatuses}
            users={users}
            onChangeSiteVisit={onChangeSiteVisit}
          />
        )
      },

      /*
      
      {
        title: 'Planswifts Sent',
        key: 'planswifts_sent',
        width: 160,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
          ]
          
          return (
            <TableCellSelect
              type="outlined"
              color={record.planswifts_sent ? 'green' : 'red'}
              options={options}
              value={record.planswifts_sent}
              onChange={(value) => onChangeEstimateField(record.id, 'planswifts_sent', value)}
              disabled={!record.updatable_fields.includes('planswifts_sent')}
            />
          )
        }
      },
      
      {
        title: 'Full Measure',
        key: 'full_measure',
        width: 160,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' },
          ]
          
          return (
            <TableCellSelect
              type="outlined"
              color={record.full_measure ? 'green' : 'red'}
              options={options}
              value={record.full_measure}
              onChange={(value) => onChangeEstimateField(record.id, 'full_measure', value)}
              disabled={!record.updatable_fields.includes('full_measure')}
            />
          )
        }
      },

      */

      {
        title: 'Priority',
        key: 'priority',
        width: 160,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: 'High', label: 'High', color: 'red' },
            { value: 'Medium', label: 'Medium', color: 'orange' },
            { value: 'Low', label: 'Low', color: 'green' },
            { value: null, label: 'N/A', color: 'grey' },
          ]

          const current = find(options, item => String(item.value) === String(record.priority));

          const color = current && current.color;

          return (
            <TableCellSelect
              type="outlined"
              color={color}
              options={options}
              value={record.priority}
              onChange={(value) => onChangeEstimateField(record.id, 'priority', value)}
              disabled={!record.updatable_fields.includes('priority')}
            />
          )
        }
      },

      {
        title: 'New / Extra',
        key: 'extra_works',
        width: 140,
        render: (text, record) => (
          <div>
            <LegacyIcon type={record.extra_works ? 'plus' : 'star'} style={{ color: record.extra_works ? 'limegreen' : '#1990FF' }} />
            {record.extra_works ? ' Extra Works' : ' New Works'}
          </div>
        ),
      },
      
      {
        title: 'Latest Action',
        key: 'latest_action_key',
        width: 220,
        sorter: true,
        render: (text, record) => {

          const options = [
            ...estimateLatestActions.map(item => ({ value: item.key, label: item.title }))
          ];
          
          return (
            <React.Fragment>
              <TableCellSelect
                type="default"
                options={options}
                value={record.latest_action_key}
                onChange={(value) => onChangeEstimateField(record.id, 'latest_action_key', value)}
                disabled={!record.updatable_fields.includes('latest_action_key')}
              />
              {record.latest_action_by_user && (
                <div style={{ fontSize: 12, color: '#999' }}>
                  {`[${record.latest_action_by_user.first_name.substr(0,1)}${record.latest_action_by_user.last_name.substr(0,1)}] ${moment(record.latest_action_at).format('DD/MM/YYYY [at] HH:mm')}`}
                </div>
              )}
            </React.Fragment>
          )
        }
      },

      {
        title: 'Follow Up',
        key: 'follow_up_date',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <FollowUpPopover
            estimate={record}
            onChangeEstimateField={onChangeEstimateField}
          />
        ),
      },

      {
        title: 'Decision Maker',
        key: 'decision_maker',
        width: 220,
        render: (text, record) => {

          const options = [
            { value: null, label: '--' },
            ...clientContacts.filter(item => item.client_id === record.project.client_site.client_id).map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];

          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.decision_maker_id}
              onChange={(value) => onChangeEstimateField(record.id, 'decision_maker_id', value)}
            />
          )
        }
      },
      
      {
        title: 'Services',
        key: 'item_type_summary',
        width: 0,
        sorter: false,
        render: (text, record) => (
          <ServiceTypeIcons keys={record.item_type_summary} />
        ),
      }
    
    ];
    
    const scroll = { x: columns.reduce((total, column) => total + column.width, 150) };
    
    return (
      <Table
        dataSource={estimates}
        columns={columns} 
        rowKey={record => record.id}
        onRow={(record) => {
          
          const current = find(estimateStatuses, item => String(item.key) === String(record.status_key));

          const background = current && current.background;

          return {
            className: `disable-hover`,
            style: {
              backgroundColor: background
            }
          }
        }}
        scroll={scroll}
        onChange={onChangeTable}
        loading={fetching}
        pagination={{
          showSizeChanger: true,
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  }
}
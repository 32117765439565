import { connect } from 'react-redux';
import Component from './Component';


import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onClickExport from './Handlers/onClickExport';
import onChangeFilter from './Handlers/onChangeFilter';

import onClickNewClient from './Handlers/onClickNewClient';
import onCloseClientModal from './Handlers/onCloseClientModal';
import didCreateClient from './Handlers/didCreateClient';


const mapStateToProps = (state) => ({
  clients: state.screens.Clients.clients,
  searchTerm: state.screens.Clients.searchTerm,
  filters: state.screens.Clients.filters,
  pagination: state.screens.Clients.pagination,
  fetching: state.screens.Clients.fetching,
  showClientModal: state.screens.Clients.showClientModal
});

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onClickNewClient,
  onCloseClientModal,
  didCreateClient,

  onChangeSearch,
  onSubmitSearch,
  onClickExport,
  onChangeFilter
};

export default connect(mapStateToProps, actionCreators)(Component);
import fetchOrders from '../Services/fetchOrders';
import { setFilters } from '../State/filters';
import { setPagination } from '../State/pagination';

export default (filter, value) => async (dispatch) => {
  
  dispatch(setFilters({ [filter]: value }));

  dispatch(setPagination({ current: 1 }));
  
  dispatch(fetchOrders());
  
}
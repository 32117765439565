// @flow

import { getProject } from '../../../HttpRequests/projects';
import { loadProject } from '../State/project';
import { setFetching } from '../State/fetching';
import { getProps } from '../props';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'clientSite',
      'clientSite.client',
      'clientContact',
      'contractsManager',
      'otherContractsManager',
      'supervisor',
      'estimates.project',
      'orders.project',
      'valuationDates',
      'valuations.project',
      'valuations.activeInvoices',
      'invoices.project',
      'invoices.valuation',
      'invoices.retentionPayment',
      'retentionPayments',
      'retentionPayments.activeInvoices',
      'ramsRecords',
    ].join();
    
    params.append = [
      'audit_trail',
      'status',
      'phase',
      'action_statuses',
      'updatable_fields'
    ].join();

    dispatch(setFetching(true));

    const response = await getProject({ id, params });

    const project = response.data.data;

    dispatch(loadProject(project));

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}
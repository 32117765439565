import { getTimesheets } from '../../../HttpRequests/timesheets'
import { setTimesheets } from '../State/timesheets'
import { setFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { searchTerm, filters, sorting, pagination } = getState().screens.Timesheets

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (Array.isArray(filters.date_range) && filters.date_range.length === 2) {
    params['filter[date_range]'] = filters.date_range.map(date => date.format('YYYY-MM-DD')).join()
  }

  if (filters.subcontractor_id) params['filter[subcontractor_id]'] = filters.subcontractor_id

  if (filters.project_id) params['filter[project_id]'] = filters.project_id

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'date'

  params.include = [
    'subcontractor',
    'timesheetPostings.project.clientSite.client',
    'timesheetAdjustments.user',
  ].join(',')

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(setFetching(true))

  try {

    const response = await getTimesheets({ params })

    dispatch(setTimesheets(response.data.rows))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

  }

}

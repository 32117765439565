import fetchOrders from '../Services/fetchOrders';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchClients from '../../../Core/Services/fetchClients';


export default () => async (dispatch) => {

  dispatch(fetchOrders());
  dispatch(fetchUsers());
  dispatch(fetchProjects());
  dispatch(fetchClients());
  
}
import { get } from 'lodash'

const isSuper = () => {
  
  const user = get(window, 'state.user')
  
  return !!user.is_super
  
}

export default isSuper

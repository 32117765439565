import axios from './axios'

export const createTimesheet = ({ data }) => axios.post('timesheets', data)
export const deleteTimesheet = ({ id }) => axios.delete(`timesheets/${id}`)
export const findTimesheet = ({ params, id } = {}) => axios.get(`timesheets/${id}`, { params })
export const getTimesheets = ({ params } = {}) => axios.get('timesheets', { params })
export const updateTimesheet = ({ id, data }) => axios.put(`timesheets/${id}`, data)

export const getTimesheetSubcontractorToday = () => axios.get('timesheets/subcontractor/today')
export const clockTimesheetSubcontractorIn = ({ data }) => axios.put('timesheets/subcontractor/clock_in', data)
export const clockTimesheetSubcontractorOut = ({ data }) => axios.put('timesheets/subcontractor/clock_out', data)
export const switchTimesheetSubcontractorProject = ({ data }) => axios.put('timesheets/subcontractor/switch_project', data)

import React from 'react';
import { Select, Radio, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const defaultSelectProps = {
  style: { width: 230 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: true,
  optionFilterProp: 'children',
};

type Props = {
  filters: any,
  clients: [],
  onChangeFilter: (key: any, value: any) => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      filters,
      clients,
      onChangeFilter
    } = this.props;

    return (

      <div class="_filter_bar" >

        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Client"
          value={filters.client_id}
          onChange={(value) => onChangeFilter('client_id', value)}
        >

          {clients.map(client => (
            <Select.Option key={client.id}>
              {`${client.name}`}
            </Select.Option>
          ))}

        </Select>

        <div style={{ width: 10 }} />

        <Radio.Group
          value={filters.extra_works}
          onChange={(event) => onChangeFilter('extra_works', event.target.value)}
        >

          <Radio.Button
            value='all'
            style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
          >
            All
          </Radio.Button>

          <Radio.Button
            value={0}
            style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
          >
            New
          </Radio.Button>

          <Radio.Button
            value={1}
            style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
          >
            Extra
          </Radio.Button>

        </Radio.Group>

        <div style={{ width: 10 }} />

        <RangePicker
          value={filters.date_between}
          onChange={(value) => onChangeFilter('date_between', value)}
          format="DD/MM/YYYY"
        />

      </div>

    )
  }
}

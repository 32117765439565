import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/VALUATIONS/SET_FILTERS");

const defaultState = {
  status_key: 'all',
  billing_type: undefined,
  client_id: undefined,
  date_betweem: undefined,
  contracts_manager: undefined,
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setFilters, reducer };
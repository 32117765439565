import { createSelector } from 'reselect'

const selectFilters = state => state.screens.Subbies.SubProjects.filters

export default createSelector(
  selectFilters,
  (filters) => {

    if (filters.subcontractorIds.length) return true
    if (filters.projectIds.length) return true
    if (filters.completionStatuses.length) return true

    return false

  },
)

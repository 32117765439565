import { message } from 'antd'
import { switchTimesheetSubcontractorProject } from '../../../HttpRequests/timesheets'
import fetch from './fetch'
import { resetForm } from '../State/form'
import fetchProjects from '../../../Core/Services/fetchProjects'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { data, form } = getState().screens.TimesheetPosting

  try {
    
    await switchTimesheetSubcontractorProject({
      data: {
        timesheet_posting_id: data.timesheet_posting.id,
        project_id: form.projectId,
      },
    })
    
    dispatch(fetch())
    
    dispatch(resetForm())

    dispatch(fetchProjects())

    message.success('Switched Project')

  } catch (error) {

    handleException(error)

  }

}

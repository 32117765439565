import { get } from 'lodash'

const isPremium = () => {

    const type = get(window, 'state.user.type')

    return type === 'Premium'

}

export default isPremium

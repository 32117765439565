import { connect } from 'react-redux'
import Timesheets from './Timesheets'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickExport from './Handlers/onClickExport'
import onClickNewTimesheet from './Handlers/onClickNewTimesheet'
import onCloseTimesheetModal from './Handlers/onCloseTimesheetModal'
import didCreateTimesheet from './Handlers/didCreateTimesheet'
import didUpdateTimesheet from './Handlers/didUpdateTimesheet'
import didDeleteTimesheet from './Handlers/didDeleteTimesheet'
import onClickStartDateTime from './Handlers/onClickStartDateTime'
import onClickEndDateTime from './Handlers/onClickEndDateTime'
import onClickCreateTimesheetAdjustment from './Handlers/onClickCreateTimesheetAdjustment'
import onClickTimesheetAmendment from './Handlers/onClickTimesheetAmendment'
import onCloseTimesheetAdjustmentModal from './Handlers/onCloseTimesheetAdjustmentModal'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  timesheets: state.screens.Timesheets.timesheets,
  fetching: state.screens.Timesheets.fetching,
  searchTerm: state.screens.Timesheets.searchTerm,
  filters: state.screens.Timesheets.filters,
  pagination: state.screens.Timesheets.pagination,
  timesheetModal: state.screens.Timesheets.timesheetModal,
  timesheetAdjustmentModal: state.screens.Timesheets.timesheetAdjustmentModal,
})

const actionCreators = {
  componentDidMount,
  onChangeSearch,
  onSubmitSearch,
  onChangeFilter,
  onChangeTable,
  onClickRow,
  onClickExport,
  onClickNewTimesheet,
  onCloseTimesheetModal,
  didCreateTimesheet,
  didUpdateTimesheet,
  didDeleteTimesheet,
  onClickStartDateTime,
  onClickEndDateTime,
  onClickCreateTimesheetAdjustment,
  onClickTimesheetAmendment,
  onCloseTimesheetAdjustmentModal,
}

export default connect(mapStateToProps, actionCreators)(Timesheets)

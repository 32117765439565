import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { InfoCircleFilled } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import { getUser, getTeamUrl, isAdvanced } from 'Common';
import styles from './Component.css'

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

export default class _Sidebar_ extends React.PureComponent {

  static propTypes = {
    invoice: PropTypes.object.isRequired,
    onClickProject: PropTypes.func.isRequired,
  }

  render () {

    const {
      invoice,
      onClickProject,
    } = this.props
    //      <div className={styles.wrapper}>
    return (
      <div className="_side_bar">

          <div className={styles.innerWrapper}>

          <Section>

            {invoice.title && (
              <Outlet>
                <OutletLabel>Title</OutletLabel>
                <OutletData>
                  {invoice.title}
                </OutletData>
              </Outlet>
            )}

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${invoice.project.client_site.client.id}/view`)}>
                  {invoice.project.client_site.client.name}
                </NavLink>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red" })[invoice.project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {invoice.project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Project</OutletLabel>
              <OutletData>
                  {isAdvanced() ? (
                      <NavLink to={getTeamUrl(`projects/${invoice.project.id}/view`)}>
                          {invoice.project.number}
                          {invoice.project.title && ` » ${invoice.project.title}`}
                      </NavLink>
                  ) : (
                      <a role='presentation' onClick={() => onClickProject(invoice.project)}>
                          {invoice.project.number}
                          {invoice.project.title && ` » ${invoice.project.title}`}
                      </a>
                  )}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  invoice.project.client_site.name,
                  invoice.project.client_site.address_line_1,
                  invoice.project.client_site.address_line_2,
                  invoice.project.client_site.address_line_3,
                  invoice.project.client_site.town_or_city,
                  invoice.project.client_site.county,
                  invoice.project.client_site.postcode
                ].filter(line => line).join(', ')}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Contact</OutletLabel>
              <OutletData>
                {`${invoice.client_contact.first_name || ''} ${invoice.client_contact.last_name || ''} `}
                <Popover
                  trigger="click"
                  content={(
                    <div>
                      <div style={{minWidth: 150}}>
                        <strong>Tel: </strong>
                        {invoice.client_contact.telephone ? invoice.client_contact.telephone : ''}
                      </div>
                      <div style={{minWidth: 150}}>
                        <strong>Email: </strong>
                        {invoice.client_contact.email ? invoice.client_contact.email : ''}
                      </div>
                    </div>
                  )}
                >
                  <InfoCircleFilled style={{ color: "#1990FF", cursor: 'pointer' }} />
                </Popover>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Owner</OutletLabel>
              <OutletData>
                {`${invoice.user.first_name || ''} ${invoice.user.last_name || ''}`}
              </OutletData>
            </Outlet>

          </Section>

        </div>

      </div>
    );
  }
}

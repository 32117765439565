import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import didCreateEstimate from './Handlers/didCreateEstimate';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onChangeFilter from './Handlers/onChangeFilter';
import onChangeTable from './Handlers/onChangeTable';
import onClickNewEstimate from './Handlers/onClickNewEstimate';
import onClickExport from './Handlers/onClickExport';
import onChangeEstimateField from './Handlers/onChangeEstimateField';
import onChangeSiteVisit from './Handlers/onChangeSiteVisit';
import onCloseEstimateModal from './Handlers/onCloseEstimateModal';
import onClickCaveats from './Handlers/onClickCaveats';

const mapStateToProps = (state) => ({
  estimateStatuses: state.core.estimateStatuses,
  estimateLatestActions: state.core.estimateLatestActions,
  estimateTenderStatuses: state.core.estimateTenderStatuses,
  siteVisitStatuses: state.core.siteVisitStatuses,
  clientContacts: state.core.clientContacts,
  users: state.core.users,
  clients: state.core.clients,
  estimates: state.screens.Estimates.estimates,
  estimateTotals: state.screens.Estimates.estimateTotals,
  clientContactsWithEstimates: state.screens.Estimates.clientContacts,
  fetching: state.screens.Estimates.fetching,
  fetchingTotals: state.screens.Estimates.fetchingTotals,
  searchTerm: state.screens.Estimates.searchTerm,
  filters: state.screens.Estimates.filters,
  pagination: state.screens.Estimates.pagination,
  showEstimateModal: state.screens.Estimates.showEstimateModal,
});

const actionCreators = {
  componentDidMount,
  didCreateEstimate,
  onChangeSearch,
  onSubmitSearch,
  onChangeFilter,
  onChangeTable,
  onClickNewEstimate,
  onClickExport,
  onChangeEstimateField,
  onChangeSiteVisit,
  onCloseEstimateModal,
  onClickCaveats,
};

export default connect(mapStateToProps, actionCreators)(Component);
import { combineReducers } from 'redux'

import { reducer as subOrder } from './State/subOrder'
import { reducer as originalItems } from './State/originalItems'
import { reducer as activeTab } from './State/activeTab'
import { reducer as editing } from './State/editing'
import { reducer as selectedItems } from './State/selectedItems'
import { reducer as saving } from './State/saving'
import { reducer as subOrderModal } from './State/subOrderModal'

export default combineReducers({
  subOrder,
  originalItems,
  activeTab,
  editing,
  selectedItems,
  saving,
  subOrderModal,
})

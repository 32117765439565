import { createAction, handleActions } from 'redux-actions'

const setControls = createAction('SCREENS/PLANNER/SET_CONTROLS')
const setPeriodIndex = createAction('SCREENS/PLANNER/SET_PERIOD_INDEX')
const incPeriodIndex = createAction('SCREENS/PLANNER/INC_PERIOD_INDEX')
const decPeriodIndex = createAction('SCREENS/PLANNER/DEC_PERIOD_INDEX')
const resetPeriodIndex = createAction('SCREENS/PLANNER/RESET_PERIOD_INDEX')
const setDayOfWeek = createAction('SCREENS/PLANNER/SET_DAY_OF_WEEK')
const setFilter = createAction('SCREENS/PLANNER/SET_FILTER')
const setProjectSortBy = createAction('SCREENS/PLANNER/SET_PROJECT_SORT_BY')
const resetControls = createAction('SCREENS/PLANNER/RESET_CONTROLS')

const defaultState = {
  periodIndex: 0,
  dayOfWeek: 'Mon',
  yAxis: 'projects',
  hideEmpty: false,
  shrink: false,
  unconfirmedOrRequired: false,
  unattended: false,
  unavailableOrAbsent: false,
  duplicated: false,
  unexpected: false,
  attended: false,
  alerts: false,
  switched: false,
  priceWork: false,

  // Project
  projectSearch: null,
  contractsManagerId: [],
  statusKey: [2],
  phaseKey: [],
  startDateBetween: [],
  omitted: false,
  inductionRequired: false,
  weeklyReturns: false,
  termsAndConditions: false,
  noValuationThisMonth: false,
  missingRams: false,
  expiringRams: false,
  expiredRams: false,
  summary: false,
  projectSortBy: undefined,

  // Subcontractor
  subcontractorSearch: undefined,
  cardType: [],
}

const reducer = handleActions(
  {
    [setControls]: (state, { payload }) => payload,
    [setPeriodIndex]: (state, { payload }) => ({ ...state, periodIndex: payload }),
    [incPeriodIndex]: state => ({ ...state, periodIndex: state.periodIndex + 1 }),
    [decPeriodIndex]: state => ({ ...state, periodIndex: state.periodIndex - 1 }),
    [resetPeriodIndex]: state => ({ ...state, periodIndex: 0 }),
    [setDayOfWeek]: (state, { payload }) => ({ ...state, dayOfWeek: payload }),
    [setFilter]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [setProjectSortBy]: (state, { payload }) => ({ ...state, projectSortBy: payload }),
    [resetControls]: () => defaultState,
  },
  defaultState,
)

export {
  setControls,
  setPeriodIndex,
  incPeriodIndex,
  decPeriodIndex,
  resetPeriodIndex,
  setDayOfWeek,
  setFilter,
  setProjectSortBy,
  resetControls,
  reducer,
}

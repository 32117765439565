import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/SUBBIES/SUBCONTRACTORS/SET_FILTERS");

const defaultState = {
  status: 'All',
  expiry: null,
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setFilters, reducer };
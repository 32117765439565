import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import { Table, Popover, Dropdown, Menu } from 'antd'
import { DownOutlined, EyeOutlined, InfoCircleOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Common'
import styles from './Component.css'

export default class _Table_ extends React.PureComponent {

  static propTypes = {
    retentionPayments: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    onClickActionMenuItem: PropTypes.func.isRequired,
  }

  render () {

    const {
      retentionPayments,
      pagination,
      fetching,
      onChangeTable,
      onClickRecord,
      onClickActionMenuItem,
    } = this.props

    const actionMenu = (retentionPayment) => (

      <Menu onClick={({ key }) => onClickActionMenuItem(key, retentionPayment)}>

        <Menu.Item key="CREATE_INVOICE" disabled={retentionPayment.active_invoices.length > 0}>
          <PlusOutlined />
          {' Create Invoice'}
        </Menu.Item>

        <Menu.Item key="VIEW_INVOICE" disabled={retentionPayment.active_invoices.length === 0}>
          <EyeOutlined />
          {' View Invoice'}
        </Menu.Item>

        <Menu.Item key="UNDO_INVOICE" disabled={retentionPayment.active_invoices.length === 0}>
          <UndoOutlined />
          {' Undo Invoice'}
        </Menu.Item>

      </Menu>
      
    )
    
    const columns = [

      { 
        title: 'Number', 
        key: 'number',
        fixed: 'left',
        sorter: true,
        width: 155,
        render: (text, record) => (
          <a onClick={() => onClickRecord(record.id)} role="presentation">
            {`Retention ${record.id}`}
          </a>
        ),
      },
      
      {
        title: 'Client', 
        key: 'client.name',
        fixed: 'left',
        sorter: true,
        width: 252,
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.project.client_site.client.name}
              </strong>
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.project.client_site.name}
            </div>
          </div>
        ),
      },

      { 
        title: ' ', 
        key: 'notes',
        fixed: 'left',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },
      
      {
        title: 'Project',
        key: 'project_id',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`projects/${record.project_id}/view`)}>
            {`Project ${record.project.number}`}
          </NavLink>
        ),
      },
      
      {
        title: 'Total',
        key: 'total',
        width: 130,
        sorter: true,
        render: (text, record) => record.total ? `£${numeral(record.total).format('0,0.00')}` : '',
      },
      
      {
        title: 'Split',
        key: 'percentage',
        width: 170,
        sorter: false,
        render: (text, record) => `${record.percentage}% [${record.sequence.split(',')[0]} of ${record.sequence.split(',')[1]}]`,
      },
      
      {
        title: 'Due Date',
        key: 'due_date',
        width: 150,
        sorter: true,
        render: (text, record) => record.due_date ? moment(record.due_date).format('DD MMM YYYY') : '',
      },
      
      {
        title: 'Status',
        key: 'status',
        width: 200,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>
            
            {record.active_invoices.length > 0 && `Invoiced ${moment(record.active_invoices[0].date).fromNow()}`}

            {(record.active_invoices.length === 0 && record.due_date < moment().format('YYYY-MM-DD')) && (
              <span className={styles.overdue}>
                {`Due ${moment(record.due_date).fromNow()}`}
              </span>
            )}

            {(record.active_invoices.length === 0 && record.due_date >= moment().format('YYYY-MM-DD')) && (
              <span>
                {`Due ${moment(record.due_date).fromNow()}`}
              </span>
            )}

          </React.Fragment>
        ),
      },

      {
        title: 'Invoice', 
        key: 'invoice',
        width: 150,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>

            {record.active_invoices.length > 0 && (
              <NavLink key={record.active_invoices[0].id} to={getTeamUrl(`invoices/${record.active_invoices[0].id}/view`)}>
                {`Invoice ${record.active_invoices[0].number}`}
              </NavLink>
            )}

            {!record.active_invoices.length === 0 && "--"}

          </React.Fragment>
        ),
      },

      {
        title: ' ', 
        key: 'actions',
        width: 100,
        sorter: false,
        render: (record) => (
          <Dropdown overlay={actionMenu(record)} trigger={['click']} placement="bottomRight">
            <a>
              {'Actions '}
              <DownOutlined />
            </a>
          </Dropdown>
        ),
      },

      {
        title: ' ', 
        key: 'spacer',
        width: 0,
        sorter: false,
        render: () => ''
      },

    ]
    
    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) }
    
    return (

      <Table
        dataSource={retentionPayments}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        scroll={scroll}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination,
          style: { float: 'none', textAlign: 'center' },
        }}
      />

    )
  }
}
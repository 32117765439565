export default (jobs, job) => jobs.filter(row => {
  if (row.id === job.id) return false
  switch (row.slot) {
    case 'Day':
      return job.slot !== 'Night'
    case 'AM':
    case 'PM':
      return (job.slot === 'Day' || job.slot === row.slot)
    case 'Night':
      return job.slot === 'Night'
    default:
      return true
  }
})

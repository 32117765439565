import moment from 'moment';
import { omit } from 'lodash';
import { getValuations, getValuationTotals } from '../../../HttpRequests/valuations';
import { loadValuations } from '../State/valuations';
import { loadValuationTotals } from '../State/valuationTotals';
import { setFetching } from '../State/fetching';
import { setPagination } from '../State/pagination';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.Valuations;

  try {

    const params = {};

    params.include = [
      'project',
      'project.clientSite',
      'project.clientSite.client',
      'project.contractsManager',
      'project.otherContractsManager',
      'project.orders',
    ].join();

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }
    
    if (filters.billing_type) {
      params['filter[billing_type]'] = filters.billing_type;
    }
    
    if (filters.client_id) {
      params['filter[client_id]'] = filters.client_id;
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (filters.contracts_manager) {
      params['filter[contracts_manager]'] = filters.contracts_manager;
    }

    dispatch(setFetching(true));

    const response = await getValuations({ params });

    dispatch(loadValuations(response.data.data));

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

    const response2 = await getValuationTotals({ params: omit(params, ['page[size]', 'page[number]', 'csort']) });

    dispatch(loadValuationTotals(response2.data.data));

  } catch (error) {

    handleException(error);
    
  }
}
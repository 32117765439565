// @flow

import * as React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import {
  CheckCircleFilled,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EditFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { getUser, getTeamUrl, isAdvanced } from 'Common';
import styles from './Component.css';

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

type Props = {
  valuation: any,
  editable: boolean,
  onClickDownloadFile: Function,
  onDropOrderFile: Function,
  onClickEditClientSite: Function,
  onClickEditClientContact: Function,
  onClickProject: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      valuation,
      editable,
      onClickDownloadFile,
      onDropOrderFile,
      onClickEditClientSite,
      onClickEditClientContact,
      onClickProject,
    } = this.props;

    const userIsBasic = getUser().type === 'Basic'
//      <div className={styles.wrapper}>
    return (
      <div className="_side_bar">

        <div className={styles.innerWrapper}>

          <Section>

            <Outlet>
              <OutletLabel>Reference</OutletLabel>
              <OutletData>
                {valuation.full_reference}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${valuation.project.client_site.client.id}/view`)}>
                  {valuation.project.client_site.client.name}
                </NavLink>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red" })[valuation.project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {valuation.project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Project</OutletLabel>
              <OutletData>
                  {isAdvanced() ? (
                      <NavLink to={getTeamUrl(`projects/${valuation.project.id}/view`)}>
                          {valuation.project.number}
                          {valuation.project.title && ` » ${valuation.project.title}`}
                      </NavLink>
                  ) : (
                      <a role='presentation' onClick={() => onClickProject(valuation.project)}>
                          {valuation.project.number}
                          {valuation.project.title && ` » ${valuation.project.title}`}
                      </a>
                  )}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  valuation.project.client_site.name,
                  valuation.project.client_site.address_line_1,
                  valuation.project.client_site.address_line_2,
                  valuation.project.client_site.address_line_3,
                  valuation.project.client_site.town_or_city,
                  valuation.project.client_site.county,
                  valuation.project.client_site.postcode
                ].filter(line => line).join(', ')}
                {' '}
                <EditFilled onClick={onClickEditClientSite} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Contact</OutletLabel>
              <OutletData>
                <Popover
                  trigger="hover"
                  content={(
                    <div>
                      <div style={{minWidth: 150}}>
                        <strong>Tel: </strong>
                        {valuation.client_contact.telephone ? valuation.client_contact.telephone : ''}
                      </div>
                      <div style={{minWidth: 150}}>
                        <strong>Email: </strong>
                        {valuation.client_contact.email ? valuation.client_contact.email : ''}
                      </div>
                    </div>
                  )}
                >
                  {`${valuation.client_contact.first_name || ''} ${valuation.client_contact.last_name || ''} `}
                </Popover>
                {' '}
                <EditFilled onClick={onClickEditClientContact} className={styles.editIcon} />
              </OutletData>
            </Outlet>

          </Section>


          <Section>

            <Outlet>
              <OutletLabel>Owner</OutletLabel>
              <OutletData>
                {`${valuation.user.first_name || ''} ${valuation.user.last_name || ''}`}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Date</OutletLabel>
              <OutletData>
                {valuation.date ? moment(valuation.date).format('DD MMM YYYY') : '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Valuation up to</OutletLabel>
              <OutletData>
                {valuation.valuation_to_date ? moment(valuation.valuation_to_date).format('DD MMM YYYY') : '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Billing Type</OutletLabel>
              <OutletData>
                {valuation.project.billing_type}
              </OutletData>
            </Outlet>

          </Section>

          {!editable && (
          <Section>
            <OutletLabel>Payment Certificate</OutletLabel>
            <Dropzone
              className={classnames(styles.fileDropzone, {[styles.isUploaded]: valuation.payment_certificate_storage_path !== null } )}
              activeClassName={styles.dragActive}
              disablePreview
              disableClick={valuation.payment_certificate_storage_path !== null}
              multiple={false}
              onDrop={acceptedFiles => { onDropOrderFile(acceptedFiles) }}

            >

              <div
                role='presentation'

              >


                <div className={classnames(styles.state, styles.fileMissing)}>
                  <ExclamationCircleFilled style={{ marginRight: 5 }} />
                  Not Uploaded
                </div>

                <div className={classnames(styles.state, styles.fileUploaded)}>
                  <CheckCircleFilled style={{ marginRight: 5 }} />
                  File Uploaded
                </div>

                  <div className={classnames(styles.state, styles.fileDownload)} onClick={() => onClickDownloadFile()} role="presentation">
                  <CloudDownloadOutlined style={{ marginRight: 5 }} />
                  Download File
                </div>

                <div className={classnames(styles.state, styles.fileDrag)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload File
                </div>

                <div className={classnames(styles.state, styles.fileSelect)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload File
                </div>

              </div>

            </Dropzone>

          </Section>
          )}

        </div>

      </div>
    );
  }
}

import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'

const mapStateToProps = (state) => ({
  subValuations: state.screens.SubProject.SubValuations.subValuations,
  fetching: state.screens.SubProject.SubValuations.fetching,
})

const actionCreators = {
  onChangeTable,
}

export default connect(mapStateToProps, actionCreators)(Table)

import React from 'react'
import { Table, List, Avatar, Button, Tooltip, Tag } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { orderBy, every } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import styles from './Table.css'

import TimesheetAdjustmentsPopover from './TimesheetAdjustmentsPopover'

type Props = {
  timesheets: [],
  fetching: boolean,
  pagination: {},
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickStartDateTime: () => void,
  onClickEndDateTime: () => void,
  onClickCreateTimesheetAdjustment: () => void,
  onClickTimesheetAmendment: () => void,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      timesheets,
      fetching,
      pagination,
      onChangeTable,
      onClickRow,
      onClickStartDateTime,
      onClickEndDateTime,
      onClickCreateTimesheetAdjustment,
      onClickTimesheetAmendment,
    } = this.props

    const todayFormatted = moment().format('YYYY-MM-DD')

    const columns = [
      { 
        title: 'Subcontractor', 
        dataIndex: 'subcontractor.name',
        key: 'subcontractor.name',
        sorter: true,
        render: (text, record) => {
          const allPriceWork = record.timesheet_postings.length ? every(record.timesheet_postings, row => !!row.price_work) : false
          return (
            <div className={styles.subcontractor}>
              <div>
                <h3>{record.subcontractor.name}</h3>
                {!!record.subcontractor.is_supervisor && <Tag color='volcano' style={{ marginBottom: 10 }}>Supervisor</Tag>}
                {!allPriceWork && !!record.hourly_rate && <div>{`£${numeral(record.hourly_rate).format('0,0.00')} per hour`}</div>}
                {!allPriceWork && !record.hourly_rate && <div>{`£${numeral(record.total_cost).format('0,0.00')} per day`}</div>}
                {allPriceWork && <div>Price work</div>}
              </div>
              {!!record.notes && (
                <Tooltip title={record.notes}>
                  <InfoCircleOutlined style={{ color: '#1990FF', fontSize: 18 }} />
                </Tooltip>
              )}
            </div>
          )
        },
      },
      {
        title: 'Date', 
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        render: (text, record) => {
          const date = moment(record.date)
          const dateFormatted = moment(record.date).format('YYYY-MM-DD')
          const dateTime = moment(`${record.date} ${moment().format('HH:mm:ss')}`)
          return (
            <div>
              <div style={{ fontWeight: 500 }}>
                {moment(date).format('ddd D MMM YYYY')}
              </div>
              <Tag style={{ marginTop: 5 }}>
                {dateFormatted === todayFormatted ? 'today' : dateTime.fromNow()}
              </Tag>
            </div>
          )
        },
      },
      {
        title: 'Postings',
        dataIndex: 'timesheet_postings',
        width: 600,
        render: (text, record) => (

          <div>

            {orderBy(record.timesheet_postings, 'start_date_time').map((timesheetPosting, index) => {

              return (

                <div key={timesheetPosting.id}>

                  <List.Item
                    className={styles.listItem}
                  >

                    <List.Item.Meta
                      avatar={(
                        <Avatar
                          style={{ backgroundColor: timesheetPosting.end_date_time ? '#87d068' : 'orange' }}
                        >
                          {timesheetPosting.hours_formatted}
                        </Avatar>
                      )}
                      title={(
                        <React.Fragment>
                          <span style={{ fontSize: 15, fontWeight: 600 }}>{timesheetPosting.project.number}</span>
                          <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${timesheetPosting.project.client_site.client.name}`}</span>
                          {!!timesheetPosting.price_work && <Tag color='gold' style={{ fontWeight: 400, marginLeft: 5 }}>Price Work</Tag>}
                        </React.Fragment>
                      )}
                      description={timesheetPosting.project.client_site.name}
                    />

                    <div>
                      <div>
                        <ArrowRightOutlined />
                        {` `}
                        <span
                          role='presentation'
                          className={styles.startDateTime}
                          onClick={e => {
                            e.stopPropagation()
                            onClickStartDateTime(timesheetPosting.start_date_time, record)
                          }}
                        >
                          {moment(timesheetPosting.start_date_time).format('HH:mm')}
                        </span>
                      </div>
                      {!!timesheetPosting.end_date_time && (
                        <div>
                          <ArrowLeftOutlined />
                          {` `}
                          <span
                            role='presentation'
                            className={styles.endDateTime}
                            onClick={e => {
                              e.stopPropagation()
                              onClickEndDateTime(timesheetPosting.end_date_time, record)
                            }}
                          >
                            {moment(timesheetPosting.end_date_time).format('HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>

                  </List.Item>

                  {(index + 1) < record.timesheet_postings.length && <br />}

                </div>

              )
              
            })}

          </div>

        ),
      },
      {
        title: 'Hours',
        dataIndex: 'hours_worked',
        key: 'hours_worked',
        sorter: true,
        className: styles.numericColumn,
        render: (text, record) => {
          const allPriceWork = record.timesheet_postings.length ? every(record.timesheet_postings, row => !!row.price_work) : false
          return (
            <div className={styles.hoursColumn}>
              {!allPriceWork && (
                <React.Fragment>
                  <div>
                    {`Hours: `}
                    <span>{record.hours_formatted}</span>
                  </div>
                  {!!record.hourly_rate && (
                    <div style={{ color: 'orange' }}>
                      {`Deduction: `}
                      <span>{record.deduction_formatted}</span>
                    </div>
                  )}
                  <TimesheetAdjustmentsPopover
                    timesheetAdjustments={record.timesheet_adjustments}
                    timesheet={record}
                    onClickRow={onClickTimesheetAmendment}
                  >
                    <div style={{ color: '#9e9e9e', fontStyle: 'italic' }}>
                      {`Adjustments: `}
                      <span>{record.adjustments_formatted}</span>
                    </div>
                  </TimesheetAdjustmentsPopover>
                </React.Fragment>
              )}
              <div>
                {`Total: `}
                <span style={{ fontSize: 16, fontWeight: 600 }}>{record.hours_worked_formatted}</span>
              </div>
              {!allPriceWork && (
                <div className={styles.cellButtons}>
                  <Tooltip title='Create Adjustment'>
                    <Button
                      icon={<PlusOutlined />}
                      shape='circle'
                      onClick={e => {
                        e.stopPropagation()
                        onClickCreateTimesheetAdjustment(record)
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          )
        },
      },
      {
        title: 'Cost',
        dataIndex: 'total_cost',
        key: 'total_cost',
        sorter: true,
        className: styles.numericColumn,
        render: (text, record) => {
          const allPriceWork = record.timesheet_postings.length ? every(record.timesheet_postings, row => !!row.price_work) : false
          return !allPriceWork ? (
            <div>
              {`Cost: `}
              <span style={{ fontSize: 16, fontWeight: 600 }}>{`£${numeral(record.total_cost).format('0,0.00')}`}</span>
            </div>
          ) : false
        },
      },
    ]

    return (

      <Table
        columns={columns}
        dataSource={timesheets}
        rowKey='id'
        onChange={onChangeTable}
        bordered
        loading={fetching}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id),
        })}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          current: pagination.pageNumber,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
      />

    )

  }

}

import React from 'react'
import PropTypes from 'prop-types'
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Divider, Dropdown, Menu, Button, Input } from 'antd';
import { isAdmin } from 'Common';
import styles from './Component.css'

export default class _Header_ extends React.PureComponent {

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onSubmitSearch: PropTypes.func.isRequired,
    onClickExport: PropTypes.func.isRequired,
  }

  render () {

    const {
      searchTerm,
      onChangeSearch,
      onSubmitSearch,
      onClickExport,
    } = this.props

    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key="excel">Export to Excel</Menu.Item>
        <Menu.Item key="csv">Export to CSV</Menu.Item>
      </Menu>
    )

    return (
      <div className={styles.wrapper}>

        <div className={styles.breadcrumbs}>

          <Breadcrumb separator="»">

            <Breadcrumb.Item>
              Home
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              Retentions
            </Breadcrumb.Item>

          </Breadcrumb>

        </div>
            
        <Divider />

        <div className={styles.header}>

          <div className={styles.title}>
            Retentions
          </div>

          {isAdmin() && (
              
            <Dropdown overlay={exportMenu}>
              <Button>
                <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
              </Button>
            </Dropdown>

          )}

          <div style={{ width: 8 }} />

          <Input.Search
            value={searchTerm}
            onChange={onChangeSearch}
            onSearch={onSubmitSearch}
            placeholder="Search"
            enterButton
            style={{ width: 250 }}
          />

        </div>

      </div>
    );
  }
}
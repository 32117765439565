import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/SETTINGS/SET_FILTERS')

const defaultState = {
  type: 'All',
  status: 'All',
}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => ({ ...state, ...action.payload }),
  },
  defaultState,
)

export { setFilters, reducer }

/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import { prepareProjectInformationEmail } from '../../../HttpRequests/projects'
import { FORM_NAME } from '../Form';
import { getProps } from '../props';
import { setInitializing } from '../State/initializing'
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {

  try {

    dispatch(setInitializing(true))

    const { project } = getProps();

    const response = await prepareProjectInformationEmail({ id: project.id })

  // TODO - cc should have settings, eg 'projects@vividfinishes.co.uk'
    const values = {
      id: project.id,
      to: [],
      cc: [],
      bcc: [],
      subject: response.data.data.subject,
      body: response.data.data.body,
      type: 'internal',
      projectFiles: response.data.data.projectFiles,
    };

    dispatch(initialize(FORM_NAME, values));

    dispatch(setInitializing(false))

  } catch (error) {

    dispatch(setInitializing(false))

    handleException(error);

  }



}

import { goBack } from 'connected-react-router';
import handleException from '../../../Core/Helpers/handleException';
import { deleteEstimate } from '../../../HttpRequests/estimates';

export default () => async (dispatch, getState) => {

  const { estimate } = getState().screens.EstimateView;
  
  try {

    await deleteEstimate({ id: estimate.id });
    
    dispatch(goBack());
    
  } catch(error) {

    handleException(error);

  }
  
}
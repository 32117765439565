// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Filters from './Components/Filters';
import Table from './Components/Table';

import ClientModal from '../../Modals/Client'

type Props = {
  clients: [],
  pagination: {},
  fetching: boolean,
  showClientModal: boolean,
  searchTerm: string,
  filters: any,

  componentDidMount: () => void,
  onChangeTable: () => void,
  onChangeFilter: () => void,
  onClickNewClient: () => void,
  onCloseClientModal: () => void,
  didCreateClient: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onClickExport: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();

  render() {
    
    const { 
      clients,
      pagination,
      fetching,
      showClientModal,
      searchTerm,
      filters,    

      onChangeTable,
      onClickNewClient,
      onCloseClientModal,
      didCreateClient,
      onChangeSearch,
      onSubmitSearch,
      onClickExport,
      onChangeFilter
    } = this.props;
    
    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
          onClickNewClient={onClickNewClient}
        />

        <Filters
          filters={filters}
          onChangeFilter={onChangeFilter}
        />

        <Table
          clients={clients}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
        />

        {showClientModal && (
          <ClientModal
            onClose={onCloseClientModal}
            didCreate={didCreateClient}
          />
        )}
      </Screen> 

    )
  }
}
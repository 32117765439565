import fetchSettings from '../Services/fetchSettings'
import { setSorting } from '../State/sorting'
import { setPagination } from '../State/pagination'

export default (pagination, filters, sorting) => (dispatch) => {

  window.scrollTo(0, 0)

  dispatch(setSorting({
    columnKey: sorting.columnKey,
    descending: sorting.order === 'descend',
  }))

  dispatch(setPagination({
    ...pagination,
    pageNumber: pagination.current,
  }))
  
  dispatch(fetchSettings())

}

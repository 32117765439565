import { Modal } from 'antd'
import { find } from 'lodash'
import getConflictingJobs from '../Utils/getConflictingJobs'
import save from '../Services/save'

export default () => (dispatch, getState) => {

  const { subcontractors, jobs } = getState().screens.Planner.ProjectCellModal
  
  let unavailable = false
  let absent = false
  let conflicts = false

  jobs.forEach(job => {

    const subcontractor = find(subcontractors, row => row.id === job.subcontractor_id)

    const conflictingJobs = getConflictingJobs(subcontractor.jobs, job)

    if (!subcontractor.available) unavailable = true
    if (subcontractor.absence) absent = true
    if (conflictingJobs.length) conflicts = true

  })

  if (unavailable) {
    Modal.confirm({
      title: 'Unavailable Subcontractor',
      content: `One or more of the subcontractors you've selected isn't available. Are you sure you want to continue?`,
      okText: 'Continue',
      onOk: () => dispatch(save()),
    })
    return
  }

  if (absent) {
    Modal.confirm({
      title: 'Absent Subcontractor',
      content: `One or more of the subcontractors you've selected is absent. Are you sure you want to continue?`,
      okText: 'Continue',
      onOk: () => dispatch(save()),
    })
    return
  }

  if (conflicts) {
    Modal.confirm({
      title: 'Duplicate Subcontractor',
      content: `One or more of the subcontractors you've selected is scheduled for another project. Are you sure you want to continue?`,
      okText: 'Continue',
      onOk: () => dispatch(save()),
    })
    return
  }

  dispatch(save())
  
}

// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import YearOverviewReport from './Components/YearOverviewReport';
import EstimatesByClientReport from './Components/EstimatesByClientReport';
import LastThreeMonthsReport from './Components/LastThreeMonthsReport';
import TopClientsReport from './Components/TopClientsReport';
import ServicesBreakdownReport from './Components/ServicesBreakdownReport';
import NewProjectsWithOrder from './Components/NewProjectsWithOrder';

import ExportStatisticsModal from '../../Modals/ExportStatistics';
import ExportHistory from '../../Modals/ExportHistory';

type Props = {
  configForYearOverviewReport: Object,
  configForEstimatesByClientReport: Object,
  configForLastThreeMonthsReport: Object,
  configForTopClientsReport: Object,
  configForServicesBreakdownReport: Object,
  newProjectsWithOrderReport: Object,
  estimatesByClientReportType: String,
  estimatesByClientReportDateRange: String,
  clients: [],
  mode: string,
  clientId: any,
  dateRange: Object,
  days: string,
  showExportStatisticsModal: Boolean,
  showExportHistoryModal: Boolean,
  componentWillMount: () => void,
  onChangeEstimatesByClientReportFilter: Function,
  onChangeMode: Function,
  onChangeClientId: Function,
  onChangeDateRange: Function,
  onChangeDays: Function,
  onCloseExportStatisticsModal: Function,
  onCloseExportHistoryModal: Function,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentWillMount();


  render() {

    const {
      configForYearOverviewReport,
      configForEstimatesByClientReport,
      configForLastThreeMonthsReport,
      configForTopClientsReport,
      configForServicesBreakdownReport,
      newProjectsWithOrderReport,
      estimatesByClientReportType,
      estimatesByClientReportDateRange,
      clients,
      mode,
      clientId,
      dateRange,
      days,
      showExportStatisticsModal,
      showExportHistoryModal,
      onChangeEstimatesByClientReportFilter,
      onChangeMode,
      onChangeClientId,
      onChangeDateRange,
      onChangeDays,
      onCloseExportStatisticsModal,
      onCloseExportHistoryModal,
    } = this.props;

    return (

      <Screen maxWidth={1401}>

        <Breadcrumbs />

        <Header />

        <YearOverviewReport
          config={configForYearOverviewReport}
          mode={mode}
          onChangeMode={onChangeMode}
        />

        <EstimatesByClientReport
          config={configForEstimatesByClientReport}
          type={estimatesByClientReportType}
          dateRange={estimatesByClientReportDateRange}
          onChangeFilter={onChangeEstimatesByClientReportFilter}
        />

        <LastThreeMonthsReport
          config={configForLastThreeMonthsReport}
          clients={clients}
          clientId={clientId}
          onChangeClientId={onChangeClientId}
        />

        <TopClientsReport
          config={configForTopClientsReport}
        />

        <ServicesBreakdownReport
          config={configForServicesBreakdownReport}
          dateRange={dateRange}
          onChangeDateRange={onChangeDateRange}
        />

        <NewProjectsWithOrder
          newProjectsWithOrderReport={newProjectsWithOrderReport}
          days={days}
          onChangeDays={onChangeDays}
        />

        { showExportStatisticsModal && (
          <ExportStatisticsModal onClose={onCloseExportStatisticsModal} />
        )}

        { showExportHistoryModal && (
          <ExportHistory onClose={onCloseExportHistoryModal} />
        )}


      </Screen>

    )
  }
}

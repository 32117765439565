import { initialize } from 'redux-form'
import modalProps from '../modalProps'
import { FORM_NAME } from '../Form'

export default () => (dispatch) => {
  
  const { initialValues } = modalProps()

  if (initialValues) {

    dispatch(initialize(FORM_NAME, initialValues))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      type: 'reduction',
    }))

  }

}

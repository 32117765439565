import fetchProjects from '../../../Core/Services/fetchProjects'
import fetch from '../Services/fetch'

export default () => async (dispatch) => {

  dispatch(fetchProjects())

  dispatch(fetch())

}

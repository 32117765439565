import React from 'react';
import { Field } from 'redux-form';
import { TextAreaField } from 'FormFields';

type Props = {
  submitting: boolean
}

export default class extends React.PureComponent<Props> {

  render () {

    const { submitting } = this.props;

    return (

      <div>

        <Field
          required
          component={TextAreaField}
          name="jobsheet_notes"
          disabled={submitting}
          autoComplete="off"
          autoSize={{ minRows: 6, maxRows: 7 }}
          wrapperCol={{ span: 24 }}
          fieldCol={{ span: 24 }}
        />

      </div>

    )
  }
}

import React from 'react'
import { Select, DatePicker, Switch, Radio, Tooltip } from 'antd'

const defaultSelectProps = {
  style: { width: 200 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: false,
  optionFilterProp: 'children',
}

type Props = {
  projectStatuses: [],
  projectPhases: [],
  clients: [],
  users: [],
  subcontractors: [],
  projects: [],
  setup: Object,
  filters: Object,
  onChangeSetup: Function,
  onChangeFilter: Function,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      projectStatuses,
      projectPhases,
      clients,
      users,
      subcontractors,
      projects,
      setup,
      filters,
      onChangeSetup,
      onChangeFilter,
    } = this.props

    return (

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>

        <div style={{ display: 'flex' }}>

          <Select
            value={setup.model}
            onChange={(value) => onChangeSetup('model', value)}
            allowClear={false}
            style={{ width: 200 }}
          >
            {setup.models.map(model => (
              <Select.Option key={model.value}>
                {model.label}
              </Select.Option>
            ))}
          </Select>

          <div style={{ width: 10 }} />

          <Select
            placeholder='Group by'
            value={setup.field ? `By ${setup.fields[setup.model][setup.field].toLowerCase()}` : undefined}
            onChange={(value) => onChangeSetup('field', value)}
            style={{ width: 200 }}
          >
            {Object.keys(setup.fields[setup.model]).map(key => (
              <Select.Option key={key}>
                {setup.fields[setup.model][key]}
              </Select.Option>
            ))}
          </Select>

          {setup.model === 'Project' && setup.field && (

            <React.Fragment>

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Status'
                value={filters.statusKey}
                onChange={(value) => onChangeFilter('statusKey', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
              >
                {projectStatuses.map(projectStatus => (
                  <Select.Option key={projectStatus.key}>
                    {`${projectStatus.title}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Phase'
                value={filters.phaseKey}
                onChange={(value) => onChangeFilter('phaseKey', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
              >
                {projectPhases.map(projectPhase => (
                  <Select.Option key={projectPhase.key}>
                    {`${projectPhase.title}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <DatePicker.RangePicker
                value={filters.startBetween}
                onChange={(value) => onChangeFilter('startBetween', value)}
                format="DD/MM/YYYY"
                placeholder={['Start from', 'Start to']}
              />

              <div style={{ width: 10 }} />

              <DatePicker.RangePicker
                value={filters.archivedBetween}
                onChange={(value) => onChangeFilter('archivedBetween', value)}
                format="DD/MM/YYYY"
                placeholder={['Archived from', 'Archived to']}
              />

            </React.Fragment>

          )}

          {setup.model === 'Estimate' && setup.field && (

            <React.Fragment>

              <div style={{ width: 10 }} />

              <DatePicker.RangePicker
                value={filters.dateBetween}
                onChange={(value) => onChangeFilter('dateBetween', value)}
                format='DD/MM/YYYY'
              />

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Client'
                value={filters.clientId}
                onChange={(value) => onChangeFilter('clientId', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
              >
                {clients.map(client => (
                  <Select.Option key={client.id}>
                    {`${client.name}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Owner'
                value={filters.userId}
                onChange={(value) => onChangeFilter('userId', value)}
                mode='multiple'
              >
                {users.map(user => (
                  <Select.Option key={user.id}>
                    {`${user.full_name}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.newOrExtra}
                onChange={(event) => onChangeFilter('newOrExtra', event.target.value)}
              >
                <Radio.Button key='all' value='all' style={{ minWidth: 60, textAlign: 'center' }}>All</Radio.Button>
                <Radio.Button key='new' value='new' style={{ minWidth: 60, textAlign: 'center' }}>New</Radio.Button>
                <Radio.Button key='extra' value='extra' style={{ minWidth: 60, textAlign: 'center' }}>Extra</Radio.Button>
              </Radio.Group>

              <div style={{ width: 10 }} />

              <div style={{ display: 'flex', marginTop: 5 }}>
                <Switch
                  checked={filters.withRevisions}
                  onChange={(value) => onChangeFilter('withRevisions', value)}
                />
                <div style={{ width: 5 }} />
                <span>With revisions</span>
              </div>

            </React.Fragment>

          )}

          {setup.model === 'Timesheet' && setup.field && (

            <React.Fragment>

              <div style={{ width: 10 }} />

              <DatePicker.RangePicker
                value={filters.dateRange}
                onChange={(value) => onChangeFilter('dateRange', value)}
                format='DD/MM/YYYY'
              />

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Subcontractor'
                value={filters.subcontractorId}
                onChange={(value) => onChangeFilter('subcontractorId', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
              >
                {subcontractors.map(subcontractor => (
                  <Select.Option key={subcontractor.id}>
                    {`${subcontractor.name}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <Select
                {...defaultSelectProps}
                placeholder='Project'
                value={filters.projectId}
                onChange={(value) => onChangeFilter('projectId', value)}
                mode='multiple'
              >
                {projects.map(project => (
                  <Select.Option key={project.id}>
                    {`[${project.number}] ${project.client_site.client.name} - ${project.client_site.name}`}
                  </Select.Option>
                ))}
              </Select>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.hoursType}
                onChange={(event) => onChangeFilter('hoursType', event.target.value)}
              >
                <Radio.Button key='all' value='all'>All</Radio.Button>
                <Tooltip title='Hourly Rate'>
                  <Radio.Button key='hourly-rate' value='hourly-rate'>HR</Radio.Button>
                </Tooltip>
                <Tooltip title='Day Rate'>
                  <Radio.Button key='day-rate' value='day-rate'>DR</Radio.Button>
                </Tooltip>
                <Tooltip title='Price Work'>
                  <Radio.Button key='price-work' value='price-work'>PW</Radio.Button>
                </Tooltip>
              </Radio.Group>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.hoursFormat}
                onChange={(event) => onChangeFilter('hoursFormat', event.target.value)}
              >
                <Radio.Button key='time' value='time'>Time</Radio.Button>
                <Radio.Button key='decimal' value='decimal'>Decimal</Radio.Button>
              </Radio.Group>

            </React.Fragment>

          )}

        </div>

      </div>

    )

  }

}

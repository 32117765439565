import { getTeamUrl } from 'Common'
import getParams from '../Utils/getParams'

export default (options) => async (dispatch, getState) => {

  const type = options.key

  const params = getParams(getState)

  let url = `insights/export?type=${type}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}

// @flow
import * as React from 'react';
import classnames from 'classnames';
import { find } from 'lodash';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  AlignLeftOutlined,
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Dropdown, Tooltip, Popover } from 'antd';
import styles from './GroupItem.css';
import PopoverNotes from './PopoverNotes';

import type {
  EstimateItem,
  AddEstimateItemParams,
  UpdateEstimateItemFieldParams,
  RemoveEstimateItemParams,
  DuplicateEstimateItemParams
} from '../../../types';

type Props = {
  editable: boolean,
  selectedEstimateItemIds: [],
  estimateGroupIndex: number,
  estimateItemIndex: number,
  estimateItem: EstimateItem,
  serviceTypes: [],
  onBlurEstimateItemField: (params: any) => void,
  onChangeEstimateItemField: (params: UpdateEstimateItemFieldParams) => void,
  onClickAddEstimateItem: (params: AddEstimateItemParams) => void,
  onClickRemoveEstimateItem: (params: RemoveEstimateItemParams) => void,
  onClickDuplicateEstimateItem: (params: DuplicateEstimateItemParams) => void,
  onClickInsertDescription: () => void,
  onToggleEstimateItem: () => void
}

type State = {
  hoveredDragArea: boolean,
  hoveredNotesButton: boolean,
  hoveredActionButton: boolean,
  focusedField: null|string
}

class Component extends React.PureComponent<Props,State> {

  state = {
    hoveredDragArea: false,
    hoveredNotesButton: false,
    hoveredActionButton: false
  }

  onChangeEstimateItemField = async (field: string, value: string) => {

    const {
      estimateGroupIndex,
      estimateItemIndex,
      onChangeEstimateItemField
    } = this.props;
    
    await onChangeEstimateItemField({
      estimateGroupIndex,
      estimateItemIndex,
      field,
      value
    })
  }

  onBlurEstimateItemField = async (field: string) => {

    const {
      estimateGroupIndex,
      estimateItemIndex,
      onBlurEstimateItemField
    } = this.props;
    
    await onBlurEstimateItemField({
      estimateGroupIndex,
      estimateItemIndex,
      field
    })
  }

  onClickInsertDescription = () => {

    const {
      estimateGroupIndex,
      estimateItemIndex,
      onClickInsertDescription
    } = this.props;
    
    onClickInsertDescription({
      estimateGroupIndex,
      estimateItemIndex
    })

  }  

  onClickMenu = ({ key }: any) => {

    const {
      estimateGroupIndex,
      estimateItemIndex,
      onClickRemoveEstimateItem,
      onClickAddEstimateItem,
      onClickDuplicateEstimateItem
    } = this.props;

    if (key === 'remove') onClickRemoveEstimateItem({ estimateGroupIndex, estimateItemIndex });
    
    if (key === 'add') onClickAddEstimateItem({ estimateGroupIndex, estimateItemIndex });

    if (key === 'duplicate') onClickDuplicateEstimateItem({ estimateGroupIndex, estimateItemIndex });

  }

  render () {

    const {
      editable,
      estimateItem,
      serviceTypes,
      selectedEstimateItemIds,
      estimateGroupIndex,
      estimateItemIndex,
      onChangeEstimateItemField,
      onToggleEstimateItem
    } = this.props;

    const DragArea = SortableHandle(props => (
      <span {...props}>
        {props.children}
      </span>
    ));

    const ActionMenu = (

      <Menu onClick={this.onClickMenu}>

        <Menu.Item key="add">
          <PlusOutlined />
          {` Add`}
        </Menu.Item>

        <Menu.Item key="duplicate">
          <CopyOutlined />
          {` Duplicate`}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="remove">
          <DeleteOutlined />
          {` Remove`}
        </Menu.Item>

      </Menu>

    )

    const serviceType = find(serviceTypes, type => type.key === estimateItem.type);

    const textOnly = !estimateItem.type

    const showLineNumber = !textOnly && (!editable || !this.state.hoveredDragArea);

    const showDragIcon = editable && this.state.hoveredDragArea;

    return (
      <div className={styles.wrapper}>

        <DragArea
          className={styles.dragArea}
          style={{ cursor: editable ? 'grab' : 'default' }}
          onMouseEnter={() => editable && this.setState({ hoveredDragArea: true })}
          onMouseLeave={() => this.setState({ hoveredDragArea: false })}
        >

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {estimateItem.line_number}
            </div>
          )}

          {showDragIcon && (
            <DragOutlined className={styles.dragIcon} />
          )}

        </DragArea>

        <div className={styles.table}>

          { editable && (
            <div className={classnames(styles.cell)} style={{ width: 30 }}>
              <input
                type="checkbox"
                checked={!!find(selectedEstimateItemIds, { estimateGroupIndex, estimateItemIndex })}
                onChange={() => onToggleEstimateItem({ estimateGroupIndex, estimateItemIndex })}
              />
            </div>
          )}

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'type' })} style={{ width: 45 }}>
              
            <Tooltip title={serviceType && serviceType.title} placement="right">
            
              <div>

                {serviceType && (
                  <div
                    className={styles.serviceTypeIcon}
                    style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                  >
                    {serviceType.key}
                  </div>
                )}

                {textOnly && (
                  <AlignLeftOutlined className={styles.textOnlyIcon} />
                )}

                {editable && (
                  <select
                    className={styles.select}
                    value={estimateItem.type || undefined}
                    onChange={event => this.onChangeEstimateItemField('type', event.target.value || null)}
                    onFocus={() => this.setState({ focusedField: 'type' })}
                    onBlur={() => this.setState({ focusedField: null })}
                    disabled={!editable}
                  >
                    <optgroup label="Service Types">
                      {serviceTypes.map(option => (
                        <option value={option.key} key={option.key}>
                          {option.title}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other">
                      <option value="" key="text-only">
                        {"Text Only"}
                      </option>
                      <option value="none" key="none">
                        {"None"}
                      </option>
                    </optgroup>
                  </select>
                )}

              </div>

            </Tooltip>

          </div>

          <div className={classnames(styles.cell, styles.flexCell, { [styles.cellFocused] : this.state.focusedField === 'description' })}>
            <textarea
              className={styles.textarea}
              value={estimateItem.description || ''}
              onChange={event => this.onChangeEstimateItemField('description', event.target.value)}
              onFocus={() => this.setState({ focusedField: 'description' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable}
              style={{ flex: 1 }}
            />
            {editable && (
              <div className={styles.insertIconWrapper}>
                <PlusOutlined onClick={this.onClickInsertDescription} />
              </div>
            )}
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'quantity' })}>
            <input
              className={styles.input}
              value={!textOnly ? (estimateItem.quantity || '') : '-'}
              onChange={event => this.onChangeEstimateItemField('quantity', event.target.value)}
              onFocus={event => {
                event.target.select();
                this.setState({ focusedField: 'quantity' });
              }}
              onBlur={() => {
                this.onBlurEstimateItemField('quantity');
                this.setState({ focusedField: null })
              }}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'unit' })}>
            <div className={styles.outlet}>
              {!textOnly ? estimateItem.unit : '-'}
            </div>
            <select
              className={styles.select}
              value={estimateItem.unit}
              onChange={event => this.onChangeEstimateItemField('unit', event.target.value)}
              onFocus={() => this.setState({ focusedField: 'unit' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable || textOnly}
            >
              {['m','m2','Item','Hours','Days','No.','PSUM'].map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'rate' })}>
            <input
              className={styles.input}
              value={!textOnly ? (estimateItem.rate || '') : '-'}
              onChange={event => this.onChangeEstimateItemField('rate', event.target.value)}
              onFocus={event => {
                event.target.select();
                this.setState({ focusedField: 'rate' });
              }}
              onBlur={() => {
                this.onBlurEstimateItemField('rate');
                this.setState({ focusedField: null })
              }}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateItem.subtotal : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateItem.discount : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateItem.total : '-'}
            </div>
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'flag' })}>
            <div className={styles.outlet}>
              {!textOnly ? estimateItem.flag : '-'}
            </div>
            <select
              className={styles.select}
              value={estimateItem.flag || undefined}
              onChange={event => this.onChangeEstimateItemField('flag', event.target.value || null)}
              onFocus={() => this.setState({ focusedField: 'flag' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable || textOnly}
            >
              {[undefined, 'By Others', 'Omit', 'Included'].map((option) => (
                <option value={option} key={option || 'null'}>
                  {option}
                </option>
              ))}
            </select>
          </div>

        </div>
      
        <div className={styles.actionArea}>

          <span
            onMouseEnter={() => this.setState({ hoveredNotesButton: true })}
            onMouseLeave={() => this.setState({ hoveredNotesButton: false })}
          >
            <Popover
              title="Notes"
              trigger="click"
              content={(
                <PopoverNotes
                  onChangeEstimateItemField={onChangeEstimateItemField}
                  estimateGroupIndex={estimateGroupIndex}
                  estimateItemIndex={estimateItemIndex}
                  editable={editable}
                  note={estimateItem.notes}
                />
              )}            
              style={{ minWidth: 320, maxWidth: 320 }}
            >

              {(estimateItem.notes || editable) && (
                <LegacyIcon
                  type="message"
                  theme={estimateItem.notes ? 'filled' : 'outlined'}
                  style={{ color: estimateItem.notes ? '#40a9ff' : ''}}
                  className={classnames(styles.notesButton, { [styles.notesButtonHovered]: this.state.hoveredNotesButton })}
                />
              )}

            </Popover>
          </span>

          {editable && (
            <span
              onMouseEnter={() => this.setState({ hoveredActionButton: true })}
              onMouseLeave={() => this.setState({ hoveredActionButton: false })}
            >
              <Dropdown overlay={ActionMenu} trigger={['click']}>
                <CaretDownOutlined
                  className={classnames(styles.actionButton, { [styles.actionButtonHovered]: this.state.hoveredActionButton })} />
              </Dropdown>
            </span>
          )}
          
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);
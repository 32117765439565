import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchTimesheets from '../Services/fetchTimesheets'

export default () => (dispatch) => {

  dispatch(fetchSubcontractors())

  dispatch(fetchProjects())

  dispatch(fetchTimesheets())
  
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Statistic, Progress } from 'antd'
import numeral from 'numeral'

import Header from './Components/Header'

const Spacer = () => <div style={{ height: 36 }} />

const Overview = props => {

  const {
    filters,
    overview,
    fetching,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [filters])

  const gbp = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0.00')}`

  return (

    <React.Fragment>

      <Header />

      <Card loading={fetching}>

        <Row gutter={72}>
          <Col span={6}>
            <Statistic title='Projects' value={numeral(overview.quantity).format('0,0')} />
          </Col>
          <Col span={6}>
            <Statistic title='Client Orders' value={gbp(overview.pr_total)} />
          </Col>
          <Col span={6}>
            <Statistic title='Client Complete' value={gbp(overview.pr_complete)} />
          </Col>
          <Col span={6}>
            <Statistic title='Client Remaining' value={gbp(overview.pr_remaining)} />
          </Col>
        </Row>

        <Row gutter={72}>
          <Col span={6} offset={12}>
            <Progress percent={numeral(overview.pr_complete_perc).format('0,0')} />
          </Col>
        </Row>

        <Spacer />

        <Row gutter={72}>
          <Col span={6} offset={6}>
            <Statistic title='Subcontractor Orders' value={gbp(overview.sub_total)} />
          </Col>
          <Col span={6}>
            <Statistic title='Subcontractor Complete' value={gbp(overview.sub_complete)} />
          </Col>
          <Col span={6}>
            <Statistic title='Subcontractor Remaining' value={gbp(overview.sub_remaining)} valueStyle={{ color: overview.sub_remaining < 0 && 'red' }} />
          </Col>
        </Row>

        <Row gutter={72}>
          <Col span={6} offset={12}>
            <Progress percent={numeral(overview.sub_complete_perc).format('0,0')} />
          </Col>
        </Row>

      </Card>

    </React.Fragment>

  )

}

Overview.propTypes = {
  filters: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

export default Overview

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover, Tag } from 'antd';
import { NavLink } from 'react-router-dom'
import { getTeamUrl, getUser } from 'Common'


export default class _Table_ extends React.PureComponent {

  static propTypes = {
    invoices: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired,
  }

  render () {

    const {
      invoices,
      pagination,
      fetching,
      onChangeTable,
    } = this.props

    const columns = [

      {
        title: 'Invoice',
        key: 'id',
        fixed: 'left',
        sorter: true,
        width: 155,
        render: (text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`invoices/${record.id}/view`)}>
            {record.title}
          </NavLink>
        ),
      },

      {
        title: 'Client',
        key: 'client.name',
        fixed: 'left',
        sorter: true,
        width: 252,
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.client.name}
              </strong>
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.project.client_site.name}
            </div>
          </div>
        ),
      },

      {
        title: ' ',
        key: 'notes',
        fixed: 'left',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },

      {
        title: 'Total',
        key: 'total',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            <span style={{ marginRight: 10, color: record.subtotal < 0 ? 'orange' : 'inherit' }}>
              {`£${numeral(record.subtotal).format('0,0.00')}`}
            </span>
            {record.subtotal < 0 && (
              <Tag color="orange">Credit</Tag>
            )}
          </div>
        ),
      },

      {
        title: 'Date',
        key: 'date',
        width: 150,
        sorter: true,
        render: (text, record) => record.date ? moment(record.date).format('DD MMM YYYY') : '',
      },

      {
        title: 'Type',
        key: 'type',
        width: 180,
        sorter: false,
        render: (text, record) => ({
          "APPLICATION": "Application",
          "INVOICE_SINGLE": "Invoice",
          "INVOICE_SPLIT": `Split Invoice (${record.sequence.split(',')[0]} of ${record.sequence.split(',')[1]})`
        })[record.type]
      },

      {
        title: 'Relates to',
        key: 'relates_to',
        width: 200,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>

            {record.valuation && (
              <div>
                <div>
                  <NavLink to={getTeamUrl(`valuations/${record.valuation.id}/view`)}>
                    { `Valuation ${record.valuation.number} ${record.valuation.reference_suffix ? `/ ${record.valuation.reference_suffix}` : ''}` }
                  </NavLink>
                </div>
                <div>
                  {record.valuation.reference}
                </div>
              </div>
            )}

            {record.retention_payment && (
               `Retention ${record.retention_payment.id}`
            )}

          </React.Fragment>
        ),
      },
      {
        title: 'Project',
        key: 'project_id',
        width: 150,
        sorter: true,
          render: (text, record) => getUser().type === 'Premium' ? (

              <NavLink key={record.project_id} to={getTeamUrl(`projects/${record.project_id}/view`)}>
                  {`Project ${record.project.number}`}
              </NavLink>

          ) : (
              `Project ${record.project.number}`
          ),
      },
      {
        title: 'Quickbooks',
        dataIndex: '',
        width: 200,
        render: (text, record) => !!record.added_to_quickbooks && (
          <React.Fragment>
            <CheckCircleFilled style={{ color: '#14be71', fontSize: 15, paddingRight: 5}} />
            {'Added to Quickbooks'}
          </React.Fragment>
        )
      },

      {
        title: ' ',
        key: 'spacer',
        width: 0,
        sorter: false,
        render: () => ''
      },

    ]

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) }

    return (

      <Table
        dataSource={invoices}
        columns={columns}
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        scroll={scroll}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination,
          style: { float: 'none', textAlign: 'center' },
        }}
      />

    )
  }
}

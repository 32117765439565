import { createAction, handleActions } from 'redux-actions'

const setLogo = createAction('MODALS/SETTING/SET_LOGO')
const resetLogo = createAction('MODALS/SETTING/RESET_LOGO')

const defaultState = null

const reducer = handleActions(
  {
    [setLogo]: (state, { payload }) => payload,
    [resetLogo]: () => defaultState,
  },
  defaultState
);

export { setLogo, resetLogo, reducer }

import axios from './axios'

export const createUser = ({ data }) => axios.post('users', data)
export const deleteUser = ({ id }) => axios.delete(`users/${id}`)
export const findUser = ({ params, id } = {}) => axios.get(`users/${id}`, { params })
export const getUsers = ({ params } = {}) => axios.get('users', { params })
export const updateUser = ({ id, data }) => axios.put(`users/${id}`, data)
export const requireUserPasswordReset = ({ id, data }) => axios.put(`users/${id}/require_password_reset`, data)
export const resetMyPassword = ({ data }) => axios.put(`users/reset_my_password`, data)
export const resetUserPassword = ({ id, data }) => axios.put(`users/${id}/reset_password`, data)
export const deactivateUser = ({ id, data }) => axios.put(`users/${id}/deactivate`, data)
export const reactivateUser = ({ id, data }) => axios.put(`users/${id}/reactivate`, data)

import screenProps from '../screenProps'
import { findSubOrder } from '../../../HttpRequests/subOrders'
import { setSubOrder } from '../State/subOrder'
import { setOriginalItems } from '../State/originalItems'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'project.clientSite.client',
      'subcontractor',
      'user',
      'subOrderGroups.subOrderItems',
    ].join(','),
  }

  try {

    const response = await findSubOrder({ id, params })

    dispatch(setSubOrder(response.data))

    dispatch(setOriginalItems(response.data.sub_order_groups))

  } catch (error) {

    handleException(error)

  }

}

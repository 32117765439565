// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover, Tooltip } from 'antd';
import { get, padStart } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import classnames from 'classnames';
import { getTeamUrl } from 'Common';
import styles from './Table.css';


import TableCellSelect from '../../../Components/TableCellSelect';

type Props = {
  projects: [],
  projectPhases: [],
  clientContacts: [],
  pagination: {},
  fetching: boolean,
  
  onChangeProjectField: Function,
  onChangeTable: Function
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      projects,
      projectPhases,
      clientContacts,
      pagination,
      fetching,

      onChangeProjectField,
      onChangeTable
    } = this.props;

    const columns = [

      { 
        title: 'Reference',
        key: 'id',
        width: 155,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <NavLink to={getTeamUrl(`projects/${record.id}/view`)}>
            { record.number }
          </NavLink>
        ),
      },
      
      {
        title: 'Client / Site',
        key: 'client.name',
        width: 252,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div className="ellipsis">
              <strong>
                { record.client_name }
              </strong>
            </div>
            <div className="ellipsis">
              { record.client_site_name }
            </div>
          </div>
        ),
      },
      
      { 
        title: ' ', 
        key: 'notes',
        width: 25,
        fixed: 'left',
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },
      
      {
        title: 'Contact',
        key: 'client_contact_id',
        width: 220,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: null, label: 'TBC' },
            ...clientContacts.filter(item => item.client_id === record.client_site.client_id).map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];
          
          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.client_contact_id}
              onChange={(value) => onChangeProjectField(record.id, 'client_contact_id', value)}
            />
          )
        }
      },

      {
        title: 'Start Date',
        key: 'start_date',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.start_date ? moment(record.start_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'End Date', 
        key: 'handover_date',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.handover_date ? moment(record.handover_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Contracts Managers',
        key: 'contracts_managers',
        width: 220,
        sorter: false,
        render: (text, record) => (
          <div>
            {record.contracts_manager ? <div>{`${record.contracts_manager.first_name} ${record.contracts_manager.last_name}`}</div> : ''}
            {record.other_contracts_manager ? <div>{`${record.other_contracts_manager.first_name} ${record.other_contracts_manager.last_name}`}</div> : ''}
          </div>
        ),
      },

      {
        title: 'Supervisor',
        key: 'supervisor_id',
        width: 220,
        sorter: false,
        render: (text, record) => get(record.supervisor, 'name'),
      },
      
      {
        title: 'Value',
        key: 'value_total',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_total).format('0,0.00')}`}
          </div>
        )
      },
      
      {
        title: 'Remaining',
        dataIndex: '',
        key: 'value_remaining',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_remaining	).format('0,0.00')}`}
          </div>
        )
      },

      {
        title: 'Client Valuation Date',
        dataIndex: '',
        key: 'valuation_day',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.valuation_day && `${moment(`2020-01-${padStart(String(record.valuation_day), 2, '0')}`).format('Do')} of Month`}
          </div>
        )
      },

      {
        title: 'Last Valuation Date',
        dataIndex: '',
        key: 'last_valuation_date',
        width: 200,
        sorter: true,
        render: (text, record) => {
          const now = moment()
          const last = record.last_valuation_date ? moment(record.last_valuation_date) : null
          return (
            <div>
              {last && (now.diff(last, 'days') < 90 ? `${now.diff(last, 'days')} days ago` : moment(record.last_valuation_date).fromNow())}
            </div>
          )
        }
      },

      {
        title: 'Last Valuation Status',
        dataIndex: '',
        key: 'valuation_status',
        width: 200,
        render: (text, record) => record.latest_valuations.length !== 0 ? record.latest_valuations[0].status.title : null
      },
      
      {
        title: 'Status',
        key: 'status_key',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <span>
            { record.status.title }
          </span>
        )
      },
      
      {
        title: 'Phase',
        key: 'phase_key',
        sorter: true,
        width: 180,
        onCell: () => ({
          className: styles.hasSelect,
        }),
        render: (text, record) => {

          const options = [];
          projectPhases.map(item => options.push({ value: item.key, label: item.title }))
          
          return (
            <TableCellSelect
              type="outlined"
              options={options}
              value={record.phase_key}
              onChange={(value) => onChangeProjectField(record.id, 'phase_key', value)}
            />
          )
        }
      },
      
      {
        title: 'Last Upload',
        key: 'last_upload_at',
        width: 200,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.last_upload_at ? moment(record.last_upload_at).fromNow() : '--'}
          </div>
        ),
      },

      {
        title: 'Files',
        dataIndex: '',
        width: 300,
        render: (text, record) => (
          <React.Fragment>
            
            <Tooltip placement="top" title="Method Statement">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_method_statement === 1, [styles.incomplete]: record.file_method_statement === 0} )}>
                MS
              </div>
            </Tooltip>

            <Tooltip placement="top" title="Risk Assessment">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_risk_assessment === 1, [styles.incomplete]: record.file_risk_assessment === 0} )}>
                RA
              </div>
            </Tooltip>

            <Tooltip placement="top" title="O & M">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_o_and_m === 1, [styles.incomplete]: record.file_o_and_m === 0} )}>
                OM
              </div>
            </Tooltip>

            <Tooltip placement="top" title="Finishing Schedule">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_finishing_schedule === 1, [styles.incomplete]: record.file_finishing_schedule === 0} )}>
                FS
              </div>
            </Tooltip>

            <Tooltip placement="top" title="Programme">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_programme === 1, [styles.incomplete]: record.file_programme === 0} )}>
                Pr
              </div>
            </Tooltip>

            <Tooltip placement="top" title="Drawings">
              <div className={classnames(styles.fileStatus, {[styles.complete]: record.file_drawings === 1, [styles.incomplete]: record.file_drawings === 0} )}>
                Dr
              </div>
            </Tooltip>

          </React.Fragment>
        )        
      },
      
      {
        title: 'Decorating (£)',
        key: 'd_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.D ? `£ ${numeral(record.item_type_summary.D).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Spraying (£)',
        key: 's_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.S ? `£ ${numeral(record.item_type_summary.S).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Wallpapering (£)',
        key: 'w_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.W ? `£ ${numeral(record.item_type_summary.W).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Tape & Joining (£)',
        key: 't_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.T ? `£ ${numeral(record.item_type_summary.T).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Flooring (£)',
        key: 'f_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.F ? `£ ${numeral(record.item_type_summary.F).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Intumescent (£)',
        key: 'i_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.I ? `£ ${numeral(record.item_type_summary.I).format('0,0.00')}` : ''}
          </div>
        )
      },
      
      {
        title: 'Other (£)',
        key: 'o_total',
        width: 180,
        render: (text, record) => (
          <div>
            {record.item_type_summary.O ? `£ ${numeral(record.item_type_summary.O).format('0,0.00')}` : ''}
          </div>
        )
      },

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) };

    const phaseColor =  {
      '1': 'tender',
      '5': 'preStart',
      '2': 'onSite',
      '3': 'statusComplete',
      '4': 'awaitingNextPhase'
    }

    return (
      <Table
        dataSource={projects}
        columns={columns} 
        rowKey={record => record.id}
        onRow={(record) =>  ({
            className: `disable-hover ${styles[phaseColor[record.phase.key]]}`
          })
        }
        scroll={scroll}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  };
}

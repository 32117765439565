import axios from './axios'

export const createSetting = ({ data }) => axios.post('settings', data)
export const deleteSetting = ({ id }) => axios.delete(`settings/${id}`)
export const findSetting = ({ params, id } = {}) => axios.get(`settings/${id}`, { params })
export const getSettings = ({ params } = {}) => axios.get('settings', { params })
export const updateSetting = ({ id, data }) => axios.put(`settings/${id}`, data)
export const resetSettingPassword = ({ id, data }) => axios.put(`settings/${id}/reset_password`, data)
export const deactivateSetting = ({ id, data }) => axios.put(`settings/${id}/deactivate`, data)
export const reactivateSetting = ({ id, data }) => axios.put(`settings/${id}/reactivate`, data)
export const uploadSettingsFile = ({ id, data, config } = {}) => axios.post(`settings/${id}/files/upload_file`, data, config);
//export const getSettingsFile = ({ filename } = {}) => axios.get(`/settings/files/${filename}`, { params })


import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/SUBBIES/SUB_VALUATIONS/SET_FILTERS')
const setFilter = createAction('SCREENS/SUBBIES/SUB_VALUATIONS/SET_FILTER')
const resetFilters = createAction('SCREENS/SUBBIES/SUB_VALUATIONS/RESET_FILTERS')

const defaultState = {
  subcontractor_id: [],
  project_id: [],
  date_range: [],
  to_date_range: [],
  user_id: [],
}

const reducer = handleActions(
  {
    [setFilters]: (state, { payload }) => payload,
    [setFilter]: (state, { payload }) => ({ ...state, [payload.field]: payload.value }),
    [resetFilters]: () => defaultState,
  },
  defaultState,
)

export { setFilters, setFilter, resetFilters, reducer }

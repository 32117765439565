import { message } from 'antd'
import { importProfitAndLoss } from '../../../HttpRequests/files'
import fetchProjects from './fetchProjects'
import handleException from "../../../Core/Helpers/handleException";

export default (fileProps) => async (dispatch) => {

  const { file } = fileProps

  try {

    const data = new FormData()

    data.append('file', file)

    await importProfitAndLoss({ data })

    dispatch(fetchProjects())

    message.success('Data imported successfully')

  } catch(error) {

      handleException(error);
      //throw error

  }

}

// @flow

import * as React from 'react';
import classnames from 'classnames';
import { find } from 'lodash';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  AlignLeftOutlined,
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Menu, Dropdown, Tooltip } from 'antd';
import styles from './Item.css';

import type {
  EstimateOptional,
  UpdateEstimateOptionalFieldParams,
  AddEstimateOptionalParams,
  RemoveEstimateOptionalParams,
  DuplicateEstimateOptionalParams
} from '../../../types';

type Props = {
  editable: boolean,
  estimateOptionalIndex: number,
  estimateOptional: EstimateOptional,
  serviceTypes: [],
  onChangeEstimateOptionalField: (params: UpdateEstimateOptionalFieldParams) => void,
  onClickAddEstimateOptional: (params: AddEstimateOptionalParams) => void,
  onClickRemoveEstimateOptional: (params: RemoveEstimateOptionalParams) => void,
  onClickDuplicateEstimateOptional: (params: DuplicateEstimateOptionalParams) => void,
}

type State = {
  hoveredDragArea: boolean,
  hoveredActionArea: boolean
}

class Component extends React.PureComponent<Props,State> {

  state = {
    hoveredDragArea: false,
    hoveredActionArea: false
  }

  onChangeEstimateOptionalField = async (field: string, value: string) => {

    const {
      estimateOptionalIndex,
      onChangeEstimateOptionalField
    } = this.props;
    
    await onChangeEstimateOptionalField({
      estimateOptionalIndex,
      field,
      value
    })
  }

  onClickMenu = ({ key }: any) => {

    const {
      estimateOptionalIndex,
      onClickRemoveEstimateOptional,
      onClickAddEstimateOptional,
      onClickDuplicateEstimateOptional
    } = this.props;

    if (key === 'remove') onClickRemoveEstimateOptional({ estimateOptionalIndex });
    
    if (key === 'add') onClickAddEstimateOptional({ estimateOptionalIndex });

    if (key === 'duplicate') onClickDuplicateEstimateOptional({ estimateOptionalIndex });

  }

  render () {

    const {
      editable,
      estimateOptional,
      serviceTypes
    } = this.props;

    const DragArea = SortableHandle(props => (
      <span {...props}>
        {props.children}
      </span>
    ));

    const ActionMenu = (

      <Menu onClick={this.onClickMenu}>

        <Menu.Item key="add">
          <PlusOutlined />
          {` Add`}
        </Menu.Item>

        <Menu.Item key="duplicate">
          <CopyOutlined />
          {` Duplicate`}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="remove">
          <DeleteOutlined />
          {` Remove`}
        </Menu.Item>

      </Menu>

    )

    const serviceType = find(serviceTypes, type => type.key === estimateOptional.type);

    const textOnly = !estimateOptional.type

    const showLineNumber = !textOnly && (!editable || !this.state.hoveredDragArea);

    const showDragIcon = editable && this.state.hoveredDragArea;

    return (
      <div className={styles.wrapper}>

        <DragArea
          className={styles.dragArea}
          style={{ cursor: editable ? 'grab' : 'default' }}
          onMouseEnter={() => editable && this.setState({ hoveredDragArea: true })}
          onMouseLeave={() => this.setState({ hoveredDragArea: false })}
        >

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {estimateOptional.line_number}
            </div>
          )}

          {showDragIcon && (
            <DragOutlined className={styles.dragIcon} />
          )}

        </DragArea>

        <div className={styles.table}>

          <div className={styles.cell} style={{ width: 45 }}>
              
            <Tooltip title={serviceType && serviceType.title} placement="right">
            
              <div>

                {serviceType && (
                  <div
                    className={styles.serviceTypeIcon}
                    style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                  >
                    {serviceType.key}
                  </div>
                )}

                {textOnly && (
                  <AlignLeftOutlined className={styles.textOnlyIcon} />
                )}

                {editable && (
                  <select
                    className={styles.select}
                    value={estimateOptional.type || undefined}
                    onChange={event => this.onChangeEstimateOptionalField('type', event.target.value || null)}
                    disabled={!editable}
                  >
                    <optgroup label="Service Types">
                      {serviceTypes.map(option => (
                        <option value={option.key} key={option.key}>
                          {option.title}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other">
                      <option value="" key="text-only">
                        {"Text Only"}
                      </option>
                    </optgroup>
                  </select>
                )}

              </div>

            </Tooltip>

          </div>

          <div className={classnames(styles.cell, styles.flexCell)}>
            <textarea
              className={styles.textarea}
              value={estimateOptional.description || ''}
              onChange={event => this.onChangeEstimateOptionalField('description', event.target.value)}
              disabled={!editable}
            />
          </div>

          <div className={styles.cell}>
            <input
              className={styles.input}
              value={!textOnly ? (estimateOptional.quantity || '') : '-'}
              onChange={event => this.onChangeEstimateOptionalField('quantity', event.target.value)}
              onFocus={event => event.target.select()}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={styles.cell}>
            <div className={styles.outlet}>
              {!textOnly ? estimateOptional.unit : '-'}
            </div>
            <select
              className={styles.select}
              value={estimateOptional.unit}
              onChange={event => this.onChangeEstimateOptionalField('unit', event.target.value)}
              disabled={!editable || textOnly}
            >
              {['m','m2','Item','Hours','Days','No.','PSUM'].map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.cell}>
            <input
              className={styles.input}
              value={!textOnly ? (estimateOptional.rate || '') : '-'}
              onChange={event => this.onChangeEstimateOptionalField('rate', event.target.value)}
              onFocus={event => event.target.select()}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateOptional.subtotal : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateOptional.discount : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? estimateOptional.total : '-'}
            </div>
          </div>

        </div>
      
        <div
          className={classnames(styles.actionArea, { [styles.actionAreaHovered]: this.state.hoveredActionArea })}
          onMouseEnter={() => this.setState({ hoveredActionArea: true })}
          onMouseLeave={() => this.setState({ hoveredActionArea: false })}
        >

          {editable && (
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <CaretDownOutlined className={styles.actionButton} />
            </Dropdown>
          )}
          
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);
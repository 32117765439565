import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Tag, Tooltip } from 'antd'
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PoundCircleFilled,
  PoundCircleOutlined,
  ScheduleOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import numeral from 'numeral'
import moment from 'moment'
import classNames from 'classnames'
import projectStatuses from '../../Utils/projectStatuses'
import projectPhases from '../../Utils/projectPhases'
import styles from './styles.css'

import ClientSiteDetails from '../ClientSiteDetails'
import CellInfo from '../CellInfo'

const Line = ({ content }) => <div style={{ height: '18px', lineHeight: '18px', fontSize: 11, fontWeight: 500 }}>{content}</div>

const ProjectCellShrunk = ({ record }) => (
  <div className={styles.cell}>
    <div className={styles.cellBody}>

      <div style={{ flex: 1 }}>
        <div style={{ fontWeight: 600 }}>Project {record.number}</div>
        <div>{record.client_site.client.name}</div>
        <Popover
          title={record.client_site.name}
          placement='bottom'
          content={<ClientSiteDetails clientSite={record.client_site} />}
        >
          <div style={{ color: '#9e9e9e' }}>{record.client_site.name}</div>
        </Popover>
        <div style={{ height: 25, display: 'flex', alignItems: 'center' }}>
          <Tag color={projectStatuses[record.status.key]} style={{ fontSize: 10 }}>{record.status.title}</Tag>
          <Tag color={projectPhases[record.phase.key]} style={{ fontSize: 10 }}>{record.phase.title}</Tag>
        </div>
        <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
          <div className={classNames(styles.file, { [styles.uploaded]: record.file_finishing_schedule })}>FS</div>
          <div className={classNames(styles.file, { [styles.uploaded]: record.file_programme })}>Pr</div>
        </div>
      </div>
      <div style={{ width: 140, paddingLeft: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserOutlined style={{ marginRight: 5 }} />
          <Line content={record.contracts_manager ? record.contracts_manager.full_name : 'N/A'} />
        </div>
        {!!record.other_contracts_manager && (
          <div style={{ display: 'flex', alignItems: 'center', color: '#9e9e9e' }}>
            <UserSwitchOutlined style={{ marginRight: 5 }} />
            <Line content={record.other_contracts_manager.full_name} />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarOutlined style={{ marginRight: 5 }} />
          <Line content={record.start_date ? moment(record.start_date).format('DD MMM YY') : 'TBC'} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PoundCircleOutlined style={{ marginRight: 5 }} />
          <Line content={`£${numeral(record.value_total).format('0,0.00')}`} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: record.value_remaining > 0 ? 'orange' : '#9e9e9e' }}>
          <PoundCircleFilled style={{ marginRight: 5 }} />
          <Line content={`£${numeral(record.value_remaining).format('0,0.00')}`} />
        </div>
        {Object.keys(record.rams_summary).map(type => (
          <React.Fragment key={type}>
            {record.rams_summary[type].status === 'Missing' && (
              <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                <CloseCircleOutlined style={{ marginRight: 5 }} />
                <Line content={`${type}: Missing`} />
              </div>
            )}
            {record.rams_summary[type].status === 'Current' && (
              <Tooltip title={`Expires: ${moment(record.rams_summary[type].date).format('DD/MM/YYYY')}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                  <CheckCircleOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Current`} />
                </div>
              </Tooltip>
            )}
            {record.rams_summary[type].status === 'Future' && (
              <Tooltip title={`Starts: ${moment(record.rams_summary[type].date).format('DD/MM/YYYY')}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#9e9e9e' }}>
                  <MinusCircleOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Future`} />
                </div>
              </Tooltip>
            )}
            {record.rams_summary[type].status === 'Expired' && !record.rams_muted && (
              <Tooltip title={`Expired: ${moment(record.rams_summary[type].date).format('DD/MM/YYYY')}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                  <WarningOutlined style={{ marginRight: 5 }} />
                  <Line content={`${type}: Expired`} />
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
    {!!record.planner_notes && <CellInfo content={record.planner_notes} fontSize={11} />}
  </div>
)

Line.propTypes = {
  content: PropTypes.any.isRequired,
}

ProjectCellShrunk.propTypes = {
  record: PropTypes.object.isRequired,
}

export default ProjectCellShrunk

import moment from 'moment'
import { getTeamUrl } from 'Common'

export default (options) => async (dispatch, getState) => {

  const type = options.key

  const { searchTerm, sorting, filters } = getState().screens.Invoices

  const params = {}

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey
  }
  
  if (searchTerm) {
    params.search = searchTerm
  }
    
  if (filters.type === 'INVOICES') {
    params['filter[type]'] = 'INVOICE_SINGLE,INVOICE_SPLIT'
  } else if (filters.type === 'APPLICATIONS') {
    params['filter[type]'] = 'APPLICATION'
  }
  
  if (filters.client_id) {
    params['filter[client_id]'] = filters.client_id
  }
  
  if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
    params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join()
  }

  let url = `invoices/export?type=${type}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}
import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { orderBy } from 'lodash'
import moment from 'moment'

const Schedule = props => {

  const { project, onClickValuationDate } = props

  return (

    <Table
      dataSource={orderBy(project.valuation_dates, 'date')}
      rowKey='id'
      onRow={record => ({
        onClick: () => onClickValuationDate(record),
        style: { cursor: 'pointer' },
      })}
    >

      <Table.Column
        title='Date'
        key='date'
        render={(text, record) => moment(record.date).format('ddd Do MMM YYYY')}
      />

      <Table.Column
        title='To Date'
        key='to_date'
        render={(text, record) => record.to_date && moment(record.to_date).format('ddd Do MMM YYYY')}
      />

    </Table>

  )

}

Schedule.propTypes = {
  project: PropTypes.object.isRequired,
  onClickValuationDate: PropTypes.func.isRequired,
}

export default Schedule

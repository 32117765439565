// @flow

import * as React from 'react';

import {
  CaretDownOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  CopyOutlined,
  DatabaseFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MailOutlined,
  PauseCircleFilled,
  PlusOutlined,
  RedoOutlined,
  StarFilled,
  UploadOutlined,
} from '@ant-design/icons';

import { Button, Dropdown, Menu, Upload } from 'antd';
import { find } from 'lodash';
import NotesPopover from './Components/NotesPopover';
import styles from './Component.css';

const ButtonGroup = Button.Group;

type Props = {
  estimateStatuses: [],
  estimateTenderStatuses: [],
  estimateLatestActions: [],

  estimate: any,
  onClickActionMenuItem: (key: string) => void,
  onUpload: Function,
  onChangeNotes: Function,
  onChangeEstimateField: (field: string, value: any) => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimate,
      onClickActionMenuItem,
      onUpload,
      onChangeNotes,
      estimateStatuses,
      estimateTenderStatuses,
      estimateLatestActions,
      onChangeEstimateField
    } = this.props;

    const { Item, Divider } = Menu;

    const tenderStatusIcons = {
      '1': (
        <EditFilled style={{fontSize: 15 }} />
      ),
      '5': (
        <ClockCircleFilled style={{fontSize: 15 }} />
      ),
      '2': (
        <CheckCircleFilled style={{fontSize: 15, color: '#ffa452' }} />
      ),
      '3': (
        <CheckCircleFilled style={{fontSize: 15, color: '#6DBF14' }} />
      ),
      '4': (
        <CheckCircleFilled style={{fontSize: 15, color: '#179ded' }} />
      ),
      '6': (
        <CloseCircleFilled style={{fontSize: 15, color: '#FF0000' }} />
      )
    };

    const statusesIcons = (color) => ({
      '11': (
        <StarFilled style={{fontSize: 15, color }} />
      ),
      '3': (
        <StarFilled style={{fontSize: 15, color }} />
      ),
      '12': (
        <StarFilled style={{fontSize: 15, color }} />
      ),
      '4': (
        <StarFilled style={{fontSize: 15, color }} />
      ),
      '5': (
        <StarFilled style={{fontSize: 15, color }} />
      ),
      '6': (
        <CheckCircleFilled style={{fontSize: 15, color }} />
      ),
      '7': (
        <CloseCircleFilled style={{fontSize: 15, color }} />
      ),
      '8': (
        <CloseCircleFilled style={{fontSize: 15, color }} />
      ),
      '9': (
        <PauseCircleFilled style={{fontSize: 15, color }} />
      ),
      '10': (
        <DatabaseFilled style={{fontSize: 15, color }} />
      )
    });

    const estimateTenderTitle = estimateTenderStatuses.length > 0 && find(estimateTenderStatuses, { key: estimate.tender_status_key});
    const estimateStatusesTitle = estimateStatuses.length > 0 && find(estimateStatuses, { key: estimate.status_key});
    const estimateLatestTitle = estimateLatestActions.length > 0 && find(estimateLatestActions,{ key: estimate.latest_action_key});

    const actionsMenu = (

      <Menu onClick={({ key }) => onClickActionMenuItem(key)}>

        <Item key="SEND">
          <MailOutlined />
          {' Send via Email'}
        </Item>

        <Divider />

        <Menu.SubMenu
          title={(
            <span>
              <FilePdfOutlined />
              {' Estimate PDF'}
            </span>
          )}
        >

          <Item key="CLIENT_PDF">
            {' Client Version'}
          </Item>

          <Item key="INTERNAL_PDF">
            {' Internal Version (with notes)'}
          </Item>

        </Menu.SubMenu>

        <Item key="EXCEL">
          <FileExcelOutlined />
          {' Estimate Excel'}
        </Item>

        <Divider />

        <Item key="EDIT">
          <EditOutlined />
          {' Edit Details'}
        </Item>

        <Item key="DUPLICATE">
          <CopyOutlined />
          {' Duplicate Estimate'}
        </Item>

        <Item key="REVISE" disabled={estimate.action_statuses.revise !== true}>
          <RedoOutlined />
          {' Revise Estimate'}
        </Item>

        <Item key="CREATE_ORDER" disabled={estimate.action_statuses.create_order !== true}>
          <PlusOutlined />
          {' Create Order'}
        </Item>

        {estimate.action_statuses.handle_items === true && (

          <Item key="IMPORT_ITEMS">
            <Upload
              name="file"
              customRequest={onUpload}
              showUploadList={false}
            >
              <UploadOutlined />
              {' Import Items'}
            </Upload>
          </Item>

        )}

        {estimate.action_statuses.handle_items !== true && (

          <Item key="IMPORT_ITEMS" disabled>
            <UploadOutlined />
            {' Import Items'}
          </Item>

        )}

        <Divider />

        <Item key="DELETE" disabled={estimate.action_statuses.delete !== true}>
          <DeleteOutlined />
          {' Delete Estimate'}
        </Item>

      </Menu>

    );

    const tenderStatusMenu = (
      <Menu
        selectable
        selectedKeys={[estimate.tender_status_key.toString()]}
        onClick={({ key }) => onChangeEstimateField('tender_status_key', key)}
      >
        {estimateTenderStatuses.map(option => (
          <Item key={option.key} value={option.key}>
            {tenderStatusIcons[option.key]}
            {` ${option.title}`}
          </Item>
        ))}
      </Menu>
    );

    const statusMenu = (
      <Menu
        selectable
        selectedKeys={[estimate.status_key && estimate.status_key.toString()]}
        onClick={({ key }) => onChangeEstimateField('status_key', key)}
      >
        {estimateStatuses.map(option => (
          <Item key={option.key} value={option.key}>
            {statusesIcons(option.color)[option.key]}
            {` ${option.title}`}
          </Item>
        ))}
      </Menu>
    );

    const latestActionMenu = (
      <Menu
        selectable
        selectedKeys={[estimate.latest_action_key.toString()]}
        onClick={({ key }) => onChangeEstimateField('latest_action_key', key)}
      >
        {estimateLatestActions.map(option => (
          <Item key={option.key} value={option.key}>
            {` ${option.title}`}
          </Item>
        ))}
      </Menu>
    )
      //<div className={styles.stack}>
    return (

        <div className='_header_bar'>

        <h1 className={styles.heading}>
          {estimate.full_reference}
        </h1>

        <NotesPopover
          notes={estimate.notes}
          onChangeNotes={onChangeNotes}
        />

        <div style={{ width: 16 }} />

        <ButtonGroup>
          {/* Tender Status */}
          <Dropdown
            overlay={tenderStatusMenu}
            trigger={['click']}
            disabled={!estimate.updatable_fields.includes('tender_status_key')}
          >

            <Button style={{ color: '#666'}}>
              { tenderStatusIcons[estimate.tender_status_key.toString()] }
              { estimateTenderTitle ? estimateTenderTitle.title : 'Draft' }
              <CaretDownOutlined />
            </Button>

          </Dropdown>

          {/* Status */}
          <Dropdown
            overlay={statusMenu}
            trigger={['click']}
            disabled={!estimate.updatable_fields.includes('status_key')}
          >

            <Button style={{ color: '#666'}}>
              { estimateStatusesTitle && statusesIcons(estimateStatusesTitle.color)[estimateStatusesTitle.key] }
              { estimateStatusesTitle ? estimateStatusesTitle.title : 'No Status'}
              <CaretDownOutlined />
            </Button>

          </Dropdown>

          {/* Latest Action */}
          <Dropdown
            overlay={latestActionMenu}
            trigger={['click']}
            disabled={!estimate.updatable_fields.includes('latest_action_key')}
          >

            <Button style={{ color: '#666'}}>
              {estimateLatestTitle ? estimateLatestTitle.title : 'Latest Action'}
              <CaretDownOutlined />
            </Button>

          </Dropdown>

        </ButtonGroup>

        {/* Actions */}
        <Dropdown overlay={actionsMenu} trigger={['click']}>
          <Button type="primary" style={{ marginLeft: 16}}>
            {'Actions '}
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>
    );
  }
}

import { connect } from 'react-redux'
import Insights from './Insights'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSetup from './Handlers/onChangeSetup'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeSort from './Handlers/onChangeSort'
import onClickExport from './Handlers/onClickExport'

const mapStateToProps = (state) => ({
  projectStatuses: state.core.projectStatuses,
  projectPhases: state.core.projectPhases,
  clients: state.core.clients,
  users: state.core.users,
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  setup: state.screens.Insights.setup,
  filters: state.screens.Insights.filters,
  searchTerm: state.screens.Insights.searchTerm,
  sortBy: state.screens.Insights.sortBy,
  data: state.screens.Insights.data,
  fetching: state.screens.Insights.fetching,
})

const actionCreators = {
  componentDidMount,
  onChangeSetup,
  onChangeFilter,
  onChangeSearch,
  onSubmitSearch,
  onChangeSort,
  onClickExport,
}

export default connect(mapStateToProps, actionCreators)(Insights)

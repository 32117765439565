// @flow

import * as React from 'react';

import {
  CaretDownOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MailOutlined,
  PlusOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Button, Dropdown, Menu } from 'antd';
import numeral from 'numeral'
import NotesPopover from './NotesPopover';
import styles from './Header.css';

type Props = {
  project: any,
  onClickActionMenuItem: (key: string) => void,
  onChangeNotes: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      project,
      onClickActionMenuItem,
      onChangeNotes
    } = this.props;

    const menu = (

      <Menu onClick={({ key }) => onClickActionMenuItem(key)}>

        <Menu.Item key="EDIT">
          <EditOutlined />
          {' Edit Details'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="COMPLETE" disabled={project.action_statuses.complete !== true}>
          <CheckCircleOutlined />
          {' Complete Project'}
        </Menu.Item>

        <Menu.Item key="UNCOMPLETE" disabled={project.action_statuses.uncomplete !== true}>
          <UndoOutlined />
          {' Uncomplete Project'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="JOB_SHEET_PDF">
          <FilePdfOutlined />
          {' Job Sheet'}
        </Menu.Item>

        <Menu.Item key="ORDER_SUMMARY_PDF">
          <FilePdfOutlined />
          {' Order Summary (PDF)'}
        </Menu.Item>

        <Menu.Item key="ORDER_SUMMARY_EXCEL">
          <FileExcelOutlined />
          {' Order Summary (Excel)'}
        </Menu.Item>

        <Menu.Item key="TARGET_COSTS_PDF">
          <FilePdfOutlined />
          {' Target Costs (PDF)'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="PROJECT_INFORMATION_EMAIL">
          <MailOutlined />
          {' Email Project Info'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="CREATE_ESTIMATE">
          <PlusOutlined />
          {' Create Estimate'}
        </Menu.Item>

        <Menu.Item key="CREATE_ORDER">
          <PlusOutlined />
          {' Create Order'}
        </Menu.Item>

        <Menu.Item key="CREATE_VALUATION">
          <PlusOutlined />
          {' Create Valuation'}
        </Menu.Item>

        <Menu.Item key="CREATE_RAMS">
          <PlusOutlined />
          {' Create RAMS'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="DELETE" disabled={project.action_statuses.delete !== true}>
          <DeleteOutlined />
          {' Delete Project'}
        </Menu.Item>

      </Menu>
    );

    return (
      <div
        className={styles.stack}
      >

        <div className={styles.heading}>
          <h1 className={styles.header}>{`Project ${project.number}`}</h1>
          {project.uplift_percentage && <div className={styles.uplift}>{`Uplift: ${numeral(project.uplift_percentage).format('0,0.[00]')}%`}</div>}
        </div>

        <NotesPopover
          notes={project.notes}
          onChangeNotes={onChangeNotes}
        />


        <div style={{ width: 16 }} />

        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="primary">
            {'Actions '}
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>
    );
  }
}
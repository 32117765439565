import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const type = options.key;

  const { searchTerm, filters, sorting } = getState().screens.Projects;

  const params = {};

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  }
  
  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.status_key !== 'all') {
    params['filter[status_key]'] = filters.status_key;
  }

  if (filters.phase_key.length > 0) {
    params['filter[phase_key]'] = filters.phase_key.join(',');
  }

  if (filters.client_id.length > 0) {
    params['filter[client_id]'] = filters.client_id.join(',');
  }

  if (filters.contracts_manager.length > 0) {
    params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
  }

  if (filters.supervisor_id.length > 0) {
    params['filter[supervisor_id]'] = filters.supervisor_id.join(',');
  }

  let url = `projects/export?type=${type}`;

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports');

}
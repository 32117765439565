import { combineReducers } from 'redux'

import { reducer as subValuation } from './State/subValuation'
import { reducer as originalItems } from './State/originalItems'
import { reducer as subOrders } from './State/subOrders'
import { reducer as activeTab } from './State/activeTab'
import { reducer as editing } from './State/editing'
import { reducer as saving } from './State/saving'
import { reducer as subValuationModal } from './State/subValuationModal'

export default combineReducers({
  subValuation,
  originalItems,
  subOrders,
  activeTab,
  editing,
  saving,
  subValuationModal,
})

import React from 'react'
import { Table, Dropdown, Menu } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './EstimatesTable.css'

const fieldSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.Item key='field.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
    <Menu.Item key='field.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
  </Menu>
)

const quantitySortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='All'>
      <Menu.Item key='quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Won'>
      <Menu.SubMenu title='Quantity'>
        <Menu.Item key='won_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='won_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='won_quantity_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='won_quantity_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Lost'>
      <Menu.SubMenu title='Quantity'>
        <Menu.Item key='lost_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='lost_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='lost_quantity_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='lost_quantity_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Other'>
      <Menu.SubMenu title='Quantity'>
        <Menu.Item key='other_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='other_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='other_quantity_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='other_quantity_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const subtotalSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='All'>
      <Menu.Item key='subtotal.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='subtotal.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Won'>
      <Menu.SubMenu title='Subtotal (£)'>
        <Menu.Item key='won_subtotal.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='won_subtotal.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='won_subtotal_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='won_subtotal_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Lost'>
      <Menu.SubMenu title='Subtotal (£)'>
        <Menu.Item key='lost_subtotal.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='lost_subtotal.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='lost_subtotal_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='lost_subtotal_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Other'>
      <Menu.SubMenu title='Subtotal (£)'>
        <Menu.Item key='other_subtotal.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='other_subtotal.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='other_subtotal_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='other_subtotal_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const negotiatingSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 180, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Client tendering'>
      <Menu.Item key='client_tendering_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='client_tendering_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Client shortlisted'>
      <Menu.Item key='client_shortlisted_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='client_shortlisted_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Client negotiating'>
      <Menu.Item key='client_negotiating_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='client_negotiating_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Client secured'>
      <Menu.Item key='client_secured_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='client_secured_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Company negotiating'>
      <Menu.Item key='company_negotiating_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='company_negotiating_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const tenderingSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 180, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Draft'>
      <Menu.Item key='draft_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='draft_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='In progress'>
      <Menu.Item key='in_progress_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='in_progress_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Awaiting Company approval'>
      <Menu.Item key='awaiting_company_approval_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='awaiting_company_approval_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Company approved'>
      <Menu.Item key='company_approved_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='company_approved_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Sent to client'>
      <Menu.Item key='sent_to_client_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='sent_to_client_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Declined to tender'>
      <Menu.Item key='declined_to_tender_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='declined_to_tender_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const decisionSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 180, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Won'>
      <Menu.Item key='won_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='won_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Client lost'>
      <Menu.Item key='client_lost_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='client_lost_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Company lost'>
      <Menu.Item key='company_lost_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='company_lost_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='On hold'>
      <Menu.Item key='on_hold_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='on_hold_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Archived'>
      <Menu.Item key='archived_quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='archived_quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const getSortByIcon = direction => <LegacyIcon type={`arrow-${direction}`} style={{ marginRight: 5, color: 'rgba(0, 0, 0, 0.65)' }} />

type Props = {
  setup: Object,
  sortBy: String,
  data: [],
  fetching: Boolean,
  onChangeSort: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      setup,
      sortBy,
      data,
      fetching,
      onChangeSort,
    } = this.props

    const columns = [
      {
        title: (
          <Dropdown overlay={fieldSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <div>{setup.fields[setup.model][setup.field]}</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'field',
        width: 150,
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {sortBy === 'field.asc' && getSortByIcon('up')}
              {sortBy === 'field.desc' && getSortByIcon('down')}
              {record.field}
            </div>
            {record.h2 && <div style={{ fontSize: 16, fontWeight: 500 }}>{record.h2}</div>}
            {record.h3 && <div>{record.h3}</div>}
          </div>
        ),
      },
      {
        title: (
          <Dropdown overlay={quantitySortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Quantity</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'quantity',
        width: 150,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'quantity.asc' && getSortByIcon('down')}
              {sortBy === 'quantity.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: '16px' }}>
                {numeral(record.quantity).format('0,0')}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div
              className={classNames({
                [styles.positive]: Number(record.won_quantity) > 0,
              })}
            >
              {(sortBy === 'won_quantity.asc' || sortBy === 'won_quantity_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'won_quantity.desc' || sortBy === 'won_quantity_ratio.desc') && getSortByIcon('up')}
              Won:
              <strong>
                {` ${numeral(record.won_quantity).format('0,0')}`}
              </strong>
              {` (${numeral(record.won_quantity_ratio).format('0')}%)`}
            </div>
            <div
              className={classNames({
                [styles.negative]: Number(record.lost_quantity) > 0,
              })}
            >
              {(sortBy === 'lost_quantity.asc' || sortBy === 'lost_quantity_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'lost_quantity.desc' || sortBy === 'lost_quantity_ratio.desc') && getSortByIcon('up')}
              Lost:
              <strong>
                {` ${numeral(record.lost_quantity).format('0,0')}`}
              </strong>
              {` (${numeral(record.lost_quantity_ratio).format('0')}%)`}
            </div>
            <div>
              {(sortBy === 'other_quantity.asc' || sortBy === 'other_quantity_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'other_quantity.desc' || sortBy === 'other_quantity_ratio.desc') && getSortByIcon('up')}
              Other:
              <strong>
                {` ${numeral(record.other_quantity).format('0,0')}`}
              </strong>
              {` (${numeral(record.other_quantity_ratio).format('0')}%)`}
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={subtotalSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Subtotal</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'subtotal',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'subtotal.asc' && getSortByIcon('down')}
              {sortBy === 'subtotal.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: '16px' }}>
                {`£${numeral(record.subtotal).format('0,0.00')}`}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div
              className={classNames({
                [styles.positive]: Number(record.won_quantity) > 0,
              })}
            >
              {(sortBy === 'won_subtotal.asc' || sortBy === 'won_subtotal_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'won_subtotal.desc' || sortBy === 'won_subtotal_ratio.desc') && getSortByIcon('up')}
              Won:
              <strong>
                {` £${numeral(record.won_subtotal).format('0,0.00')}`}
              </strong>
              {` (${numeral(record.won_subtotal_ratio).format('0')}%)`}
            </div>
            <div
              className={classNames({
                [styles.negative]: Number(record.lost_quantity) > 0,
              })}
            >
              {(sortBy === 'lost_subtotal.asc' || sortBy === 'lost_subtotal_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'lost_subtotal.desc' || sortBy === 'lost_subtotal_ratio.desc') && getSortByIcon('up')}
              Lost:
              <strong>
                {` £${numeral(record.lost_subtotal).format('0,0.00')}`}
              </strong>
              {` (${numeral(record.lost_subtotal_ratio).format('0')}%)`}
            </div>
            <div>
              {(sortBy === 'other_subtotal.asc' || sortBy === 'other_subtotal_ratio.asc') && getSortByIcon('down')}
              {(sortBy === 'other_subtotal.desc' || sortBy === 'other_subtotal_ratio.desc') && getSortByIcon('up')}
              Other:
              <strong>
                {` £${numeral(record.other_subtotal).format('0,0.00')}`}
              </strong>
              {` (${numeral(record.other_subtotal_ratio).format('0')}%)`}
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={negotiatingSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Negotiating</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'negotiating',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'client_tendering_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'client_tendering_quantity.desc' && getSortByIcon('up')}
              Client tendering:
              <strong>
                {` ${numeral(record.client_tendering_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'client_shortlisted_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'client_shortlisted_quantity.desc' && getSortByIcon('up')}
              Client shortlisted:
              <strong>
                {` ${numeral(record.client_shortlisted_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'client_negotiating_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'client_negotiating_quantity.desc' && getSortByIcon('up')}
              Client negotiating:
              <strong>
                {` ${numeral(record.client_negotiating_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'client_secured_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'client_secured_quantity.desc' && getSortByIcon('up')}
              Client secured:
              <strong>
                {` ${numeral(record.client_secured_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'company_negotiating_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'company_negotiating_quantity.desc' && getSortByIcon('up')}
              Company negotiating:
              <strong>
                {` ${numeral(record.company_negotiating_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>&nbsp;</div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={tenderingSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Tendering</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'tendering',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'draft_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'draft_quantity.desc' && getSortByIcon('up')}
              Draft:
              <strong>
                {` ${numeral(record.draft_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'in_progress_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'in_progress_quantity.desc' && getSortByIcon('up')}
              In progress:
              <strong>
                {` ${numeral(record.in_progress_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'awaiting_company_approval_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'awaiting_company_approval_quantity.desc' && getSortByIcon('up')}
              Awaiting Company approval:
              <strong>
                {` ${numeral(record.awaiting_company_approval_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'company_approved_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'company_approved_quantity.desc' && getSortByIcon('up')}
              Company approved:
              <strong>
                {` ${numeral(record.company_approved_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'sent_to_client_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'sent_to_client_quantity.desc' && getSortByIcon('up')}
              Sent to client:
              <strong>
                {` ${numeral(record.sent_to_client_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'declined_to_tender_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'declined_to_tender_quantity.desc' && getSortByIcon('up')}
              Declined to tender:
              <strong>
                {` ${numeral(record.declined_to_tender_quantity).format('0,0')}`}
              </strong>
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={decisionSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Decision</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'decision',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'won_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'won_quantity.desc' && getSortByIcon('up')}
              Won:
              <strong>
                {` ${numeral(record.won_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'client_lost_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'client_lost_quantity.desc' && getSortByIcon('up')}
              Client lost:
              <strong>
                {` ${numeral(record.client_lost_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'company_lost_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'company_lost_quantity.desc' && getSortByIcon('up')}
              Company lost:
              <strong>
                {` ${numeral(record.company_lost_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'on_hold_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'on_hold_quantity.desc' && getSortByIcon('up')}
              On hold:
              <strong>
                {` ${numeral(record.on_hold_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>
              {sortBy === 'archived_quantity.asc' && getSortByIcon('down')}
              {sortBy === 'archived_quantity.desc' && getSortByIcon('up')}
              Archived:
              <strong>
                {` ${numeral(record.archived_quantity).format('0,0')}`}
              </strong>
            </div>
            <div>&nbsp;</div>
          </div>
        )
      },
    ]

    return (

      <Table
        columns={columns}
        dataSource={data}
        rowKey='key'
        loading={fetching}
      />

    )

  }

}

import fetchEstimates from '../Services/fetchEstimates';
import fetchClientContactsWithEstimates from '../Services/fetchClientContacts';
import fetchEstimateStatuses from '../../../Core/Services/fetchEstimateStatuses';
import fetchEstimateLatestActions from '../../../Core/Services/fetchEstimateLatestActions';
import fetchEstimateTenderStatuses from '../../../Core/Services/fetchEstimateTenderStatuses';
import fetchSiteVisitStatuses from '../../../Core/Services/fetchSiteVisitStatuses';
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchClients from '../../../Core/Services/fetchClients';
import fetchClientContacts from '../../../Core/Services/fetchClientContacts';

export default () => async (dispatch) => {

  dispatch(fetchEstimates());
  dispatch(fetchClientContactsWithEstimates());

  dispatch(fetchEstimateStatuses());
  dispatch(fetchEstimateLatestActions());
  dispatch(fetchEstimateTenderStatuses());
  dispatch(fetchSiteVisitStatuses());
  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());
  dispatch(fetchClients());
  dispatch(fetchClientContacts());

}
// @flow

import * as React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { getUser, getTeamUrl, isAdvanced } from 'Common';

import {
  CheckCircleFilled,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EditFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover, Tag, Row, Col } from 'antd';
import classnames from 'classnames'
import styles from './Component.css';

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

type Props = {
  order: any,
  onClickDownloadFile: Function,
  onDropOrderFile: Function,
  onClickEditClientSite: Function,
  onClickEditClientContact: Function,
  onClickProject: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      order,
      onClickDownloadFile,
      onDropOrderFile,
      onClickEditClientSite,
      onClickEditClientContact,
      onClickProject,
    } = this.props;

    const userIsBasic = getUser().type === 'Basic'
    //      <div className={styles.wrapper}>
    return (
      <div className="_side_bar">

          <div className={styles.innerWrapper}>


          <Section>

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${order.project.client_site.client.id}/view`)}>
                  {order.project.client_site.client.name}
                </NavLink>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red" })[order.project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {order.project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Project</OutletLabel>
              <OutletData>

                  {isAdvanced() ? (
                      <NavLink to={getTeamUrl(`projects/${order.project.id}/view`)}>
                          {order.project.number}
                          {order.project.title && ` » ${order.project.title}`}
                      </NavLink>
                  ) : (
                      <a role='presentation' onClick={() => onClickProject(order.project)}>
                          {order.project.number}
                          {order.project.title && ` » ${order.project.title}`}
                      </a>
                  )}

              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  order.project.client_site.name,
                  order.project.client_site.address_line_1,
                  order.project.client_site.address_line_2,
                  order.project.client_site.address_line_3,
                  order.project.client_site.town_or_city,
                  order.project.client_site.county,
                  order.project.client_site.postcode
                ].filter(line => line).join(', ')}
                {' '}
                <EditFilled onClick={onClickEditClientSite} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Contact</OutletLabel>
              <OutletData>
                <Popover
                  trigger="hover"
                  content={(
                    <div>
                      <div style={{minWidth: 150}}>
                        <strong>Tel: </strong>
                        {order.client_contact.telephone ? order.client_contact.telephone : ''}
                      </div>
                      <div style={{minWidth: 150}}>
                        <strong>Email: </strong>
                        {order.client_contact.email ? order.client_contact.email : ''}
                      </div>
                    </div>
                  )}
                >
                  {`${order.client_contact.first_name || ''} ${order.client_contact.last_name || ''} `}
                </Popover>
                {' '}
                <EditFilled onClick={onClickEditClientContact} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Client Project No</OutletLabel>
              <OutletData>
                {order.client_project_no || '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>New / Extra</OutletLabel>
              <OutletData>
                <LegacyIcon type={order.extra_works ? 'plus' : 'star'} style={{ color: order.extra_works ? 'limegreen' : '#1990FF' }} />
                {order.extra_works ? ' Extra Works' : ' New Works'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Estimate</OutletLabel>
              <OutletData>
                {!!order.estimate && (
                  <NavLink to={getTeamUrl(`estimates/${order.estimate.id}/view`)}>
                    {order.estimate.full_reference}
                  </NavLink>
                )}
                {!order.estimate && '--'}
              </OutletData>
            </Outlet>

          </Section>


          <Section>

            <Outlet>
              <OutletLabel>Owner</OutletLabel>
              <OutletData>
                {`${order.user.first_name || ''} ${order.user.last_name || ''}`}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Date</OutletLabel>
              <OutletData>
                {order.date ? moment(order.date).format('DD MMM YYYY') : '--'}
              </OutletData>
            </Outlet>

          </Section>

          <Section>

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Discount</OutletLabel>
                  <OutletData>
                    {order.discount_percentage > 0 ? `${order.discount_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Uplift</OutletLabel>
                  <OutletData style={order.uplift_percentage > 0 ? { color: 'red' } : {}}>
                    {order.uplift_percentage > 0 ? `${order.uplift_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

          <Section>

            <Dropzone
              className={classnames(styles.fileDropzone, {[styles.isUploaded]: order.order_file_storage_path !== null } )}
              activeClassName={styles.dragActive}
              disablePreview
              disableClick={order.order_file_storage_path !== null}
              multiple={false}
              onDrop={acceptedFiles => { onDropOrderFile(acceptedFiles) }}

            >

              <div
                role='presentation'

              >

                <div className={classnames(styles.state, styles.fileMissing)}>
                  <ExclamationCircleFilled style={{ marginRight: 5 }} />
                  File Missing
                </div>

                <div className={classnames(styles.state, styles.fileUploaded)}>
                  <CheckCircleFilled style={{ marginRight: 5 }} />
                  File Uploaded
                </div>

              <div className={classnames(styles.state, styles.fileDownload)} onClick={() => onClickDownloadFile()} role="presentation">
                  <CloudDownloadOutlined style={{ marginRight: 5 }} />
                  Download File
                </div>

                <div className={classnames(styles.state, styles.fileDrag)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload File
                </div>

                <div className={classnames(styles.state, styles.fileSelect)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload File
                </div>

              </div>

            </Dropzone>
          </Section>


        </div>

      </div>
    );
  }
}

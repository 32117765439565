import { connect } from 'react-redux'

import Settings from './Settings'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickNewSetting from './Handlers/onClickNewSetting'
import onCloseSettingModal from './Handlers/onCloseSettingModal'
import didCreateSetting from './Handlers/didCreateSetting'
import didUpdateSetting from './Handlers/didUpdateSetting'
import didDeleteSetting from './Handlers/didDeleteSetting'
import didDeactivateSetting from './Handlers/didDeactivateSetting'

import didReactivateSetting from './Handlers/didReactivateSetting'

const mapStateToProps = (state) => ({
  settings: state.screens.Settings.settings,
  fetching: state.screens.Settings.fetching,
  searchTerm: state.screens.Settings.searchTerm,
  filters: state.screens.Settings.filters,
  pagination: state.screens.Settings.pagination,
  settingModal: state.screens.Settings.settingModal,
})

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onCloseSettingModal,
  didCreateSetting,
  onClickNewSetting,
  onChangeSearch,
  onSubmitSearch,
  onClickRow,
  onChangeFilter,
}

export default connect(mapStateToProps, actionCreators)(Settings)


import fetchEstimates from '../Services/fetchEstimates';
import { setFilters } from '../State/filters';
import { setPagination } from '../State/pagination';

export default (filter, value) => async (dispatch) => {
  
  dispatch(setFilters({ [filter]: value }));

  if (filter === 'client_id') {
    dispatch(setFilters({ decision_maker_id: undefined }));
  }

  dispatch(setPagination({ current: 1 }));
  
  dispatch(fetchEstimates());
  
}
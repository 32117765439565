import React from 'react'
import PropTypes from 'prop-types'

import JobSlots from './JobSlots'

const ProjectDetails = ({ project }) => (
  <div style={{ fontSize: 13 }}>
    <div style={{ fontWeight: 600 }}>
      Project {project.number}
      {` `}
      <JobSlots jobs={project.jobs} />
    </div>
    <div>{project.client_site.client.name}</div>
    <div style={{ color: '#9e9e9e' }}>{project.client_site.name}</div>
  </div>
)

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired,
}

export default ProjectDetails

import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Button } from 'antd'
import { CaretDownOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import styles from './styles.css'

const Header = props => {

  const {
    subOrder,
    onClickEdit,
    onClickDelete,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <h1 className={styles.header}>{subOrder.reference}</h1>

      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item key='edit' icon={<EditOutlined />} onClick={onClickEdit}>Edit Details</Menu.Item>
            <Menu.Item key='delete' icon={<DeleteOutlined />} onClick={onClickDelete}>Delete Order</Menu.Item>
          </Menu>
        )}
      >
        <Button type='primary'>
          Actions
          <CaretDownOutlined />
        </Button>
      </Dropdown>

    </div>
    
  )

}

Header.propTypes = {
  subOrder: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

export default Header

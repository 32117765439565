import React from 'react';
import { Field } from 'redux-form';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Button, Table } from 'antd';
import { SelectField, InputField, TextAreaField, RadioField } from 'FormFields';
import styles from './Form.css'

const labelColSpan = 3;
const wrapperColSpan = 21;

type Props = {
  submitting: boolean,
  emailOptions: [],
  selectedEmailToOption: [],
  selectedEmailCcOption: [],
  selectedEmailBccOption: [],
  projectFiles: [],
  fileList: [],
  onSearchEmailOptions: Function,
  onSelectEmailOption: Function,
  onAddFile: Function,
  onRemoveFile: Function,
  onRemoveProjectFile: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      emailOptions,
      selectedEmailToOption,
      selectedEmailCcOption,
      selectedEmailBccOption,
      projectFiles,
      fileList,
      onSearchEmailOptions,
      onSelectEmailOption,
      onAddFile,
      onRemoveFile,
      onRemoveProjectFile,
    } = this.props;

    const emailTo = [...emailOptions, ...selectedEmailToOption];
    const emailCc = [...emailOptions, ...selectedEmailCcOption];
    const emailBcc = [...emailOptions, ...selectedEmailBccOption];

    return (
      <div>

        <Field
          button
          buttonStyle="solid"
          component={RadioField}
          name="type"
          label="Type"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          options={[
            { value: 'internal', label: 'Internal (with Order Summary)'},
            { value: 'external', label: 'External (with Job Sheet)'},
          ]}
          className={styles.typeTab}
        />

        <Field
          required
          component={SelectField}
          name="to"
          label="To"
          mode="tags"
          autoComplete="off"
          options={emailTo}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          component={SelectField}
          name="cc"
          label="Cc"
          mode="tags"
          autoComplete="off"
          options={emailCc}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          component={SelectField}
          name="bcc"
          label="Bcc"
          mode="tags"
          autoComplete="off"
          options={emailBcc}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          required
          component={InputField}
          name="subject"
          label="Subject"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          required
          component={TextAreaField}
          name="body"
          label="Body"
          rows={10}
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          autoSize={{
            minRows: 10,
            maxRows: 12
          }}
        />

        <div style={{ marginLeft: 75, marginTop: 10, marginRight: 45 }}>

          <Table
            rowKey={file => file.path}
            dataSource={projectFiles}
            pagination={false}
            scroll={{ y: 160 }}
            size="small"
            columns={[
              {
                title: 'Project Files',
                key: 'folder',
                render: (text, record) => <span className={styles.downloadFileButton}>{`${record.folder} » ${record.filename}`}</span>
              },
              {
                title: ' ',
                key: 'remove',
                render: (text, record) => <CloseOutlined
                  className={styles.removeFileButton}
                  onClick={() => onRemoveProjectFile(record.path)} />
              },
            ]}
          />

          <div style={{ clear: 'both'}} />

        </div>

        <div style={{ marginLeft: 75, marginTop: 10, marginRight: 45 }}>

          <Upload
            fileList={fileList}
            onRemove={onRemoveFile}
            beforeUpload={onAddFile}
            disabled={submitting}
          >
            <Button disabled={submitting}>
              <UploadOutlined />
              {' Upload Additional Files'}
            </Button>
          </Upload>

        </div>

      </div>
    );
  }
}

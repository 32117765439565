import { getProps } from '../props';
import { updateProject } from '../../../HttpRequests/projects';

export default (values) => async () => {
  const { projectId } = getProps();

  const data = {
    ...values
  }

  await updateProject({ id: projectId, data });

}
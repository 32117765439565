import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/INSIGHTS/SET_FILTERS')
const resetFilters = createAction('SCREENS/INSIGHTS/RESET_FILTERS')

const defaultState = {
  newOrExtra: 'all',
  withRevisions: true,
  hoursType: 'all',
  hoursFormat: 'time',
}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => ({ ...state, ...action.payload }),
    [resetFilters]: () => defaultState,
  },
  defaultState,
)

export { setFilters, resetFilters, reducer }

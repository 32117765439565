import { get } from 'lodash'

const isPaymentUser = () => {

    const user = get(window, 'state.user')

    return !!user.payment_user

}

export default isPaymentUser

import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses'
import fetchProjectPhases from '../../../Core/Services/fetchProjectPhases'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchUsers())
  dispatch(fetchProjectStatuses())
  dispatch(fetchProjectPhases())

  dispatch(fetchProjects())

  dispatch(fetch())

}

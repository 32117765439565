import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/PROJECTS/SET_FILTERS");

const defaultState = {
  status_key: 2,
  phase_key: [],
  client_id: [],
  contracts_manager: [],
  supervisor_id: [],
  no_valuation_this_month: false,
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setFilters, reducer };
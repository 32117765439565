import { get } from 'lodash'

const isAdmin = () => {

  const type = get(window, 'state.user.type')

  return type === 'Admin' || type === 'Advanced' || type === 'Premium'

}

export default isAdmin

// @flow

import * as React from 'react';
import { sortBy } from 'lodash';
import { Field } from 'redux-form';
import { Divider } from 'antd';
import { SelectField, DatePickerField, InputNumberField, InputField } from 'FormFields';
import IconButton from '../../../Components/IconButton';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  estimate: ?any,
  projects: [],
  users: [],
  clientContacts: [],
  showNewClientContactButton: boolean,
  submitting: boolean,
  onChangeProject: () => void,
  onClickNewProject: () => void,
  onClickNewClientContact: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimate,
      projects,
      users,
      clientContacts,
      showNewClientContactButton,
      submitting,
      onChangeProject,
      onClickNewProject,
      onClickNewClientContact
    } = this.props;

    const projectOptions = projects.map(item => ({
      value: item.id,
      label: `[${item.number}] ${item.client_site.client.name} - ${item.client_site.name}`
    }));

    const clientContactOptions = sortBy(clientContacts.map(item => ({
      value: item.id,
      label: `${item.first_name || ''} ${item.last_name || ''}`
    })), ['label']);

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    const termsOptions = [
      { value: 1, label: 'Subcontractor T&Cs' },
      { value: 2, label: 'Main Contractor T&Cs' }
    ];

    const vatOptions = [
      { value: 0, label: '0%' },
      { value: 20, label: '20%' }
    ];

    const NewProjectButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Project"
        onClick={onClickNewProject}
        style={{ marginBottom: -4 }}
      />
    );

    const NewClientContactButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Contact"
        onClick={onClickNewClientContact}
        style={{ marginBottom: -4 }}
      />
    );

    return (

      <div>

        {!estimate && (

          <React.Fragment>

            <Field
              required
              component={SelectField}
              name="project_id"
              label="Project"
              disabled={submitting}
              options={projectOptions}
              onChange={onChangeProject}
              renderAfter={() => <NewProjectButton />}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Divider />

          </React.Fragment>

        )}
            
        <Field
          component={InputField}
          name="title"
          label="Title"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />
            
        <Field
          required
          component={SelectField}
          name="client_contact_id"
          label="Requested by"
          placeholder="Select contact"
          disabled={submitting}
          options={clientContactOptions}
          renderAfter={() => showNewClientContactButton && <NewClientContactButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />
            
        <Field
          component={SelectField}
          name="user_id"
          label="Owner"
          placeholder="Select user"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          component={SelectField}
          name="assigned_to_user_id"
          label="Sales Contact"
          placeholder="Select user"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          required
          component={DatePickerField}
          name="date"
          label="Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          component={DatePickerField}
          name="due_date"
          label="Due"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          component={DatePickerField}
          name="follow_up_date"
          label="Follow Up"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={SelectField}
          name="decision_maker_id"
          label="Decision Maker"
          placeholder="Select contact"
          disabled={submitting}
          options={clientContactOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />
            
        <Field
          required
          component={SelectField}
          name="terms_and_conditions_type"
          label="Terms"
          disabled={submitting}
          options={termsOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          required
          component={InputNumberField}
          name="discount_percentage"
          label="Discount %"
          disabled={submitting}
          min={0}
          max={100}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />
            
        <Field
          required
          component={InputNumberField}
          name="uplift_percentage"
          label="Uplift %"
          disabled={submitting}
          min={0}
          max={100}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />
            
        <Field
          required
          component={SelectField}
          name="vat_percentage"
          label="VAT %"
          disabled={submitting}
          options={vatOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />

        {!!estimate && (
          <Field
            required
            component={SelectField}
            name="extra_works"
            label="New / Extra"
            disabled={submitting}
            options={[
              { label: 'New Works', value: 0 },
              { label: 'Extra Works', value: 1 },
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 8 }}
          />
        )}
        
      </div>

    )
  }
}
import { get } from 'lodash'

const isAdvanced = () => {

    const type = get(window, 'state.user.type')

    return type === 'Admin' || type === 'Basic+' || type === 'Advanced' || type === 'Premium'

}

export default isAdvanced
